import { RoutingBomRow } from "@/custom/features/item/components/form/itemStepperForm/RoutingBomRow";
import styled from "@emotion/styled";
import { Flex, Table, Text, Title } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";

interface RoutingBomListProps {
  formData: ItemsGet200ResponseRowsInnerRoutingsInner[] | undefined;
}

export const RoutingBomList = ({ formData }: RoutingBomListProps) => {
  const [bomNumerator, setBomNumerator] = useState<number>(0);

  return (
    <Flex w="100%" p="md" direction="column" gap="xs">
      <Flex direction={"row"} justify={"space-between"}>
        <Flex direction={"column"}>
          <Title order={2}>라우팅</Title>
          <Text fz="xs">품목을 생산하기 위한 라우팅과 라우팅에 필요한 BOM들을 보여줍니다.</Text>
        </Flex>
        <Text fz="lg" mr="lg">
          총 비율 : {bomNumerator} %
        </Text>
      </Flex>
      <Table withBorder withColumnBorders>
        <thead>
          <tr>
            <Th width={5}>&nbsp;</Th>
            <Th width={5}>시퀀스</Th>
            <Th width={20}>공정명</Th>
            <Th width={10}>소모 시점</Th>
            <Th width={10}>BOM 수정</Th>
          </tr>
        </thead>
        <tbody>
          {formData &&
            formData.map((data, i) => {
              return (
                <RoutingBomRow
                  bomRow={data}
                  key={data.code ?? "" + i}
                  rowIndex={i}
                  bomNumerator={bomNumerator}
                  setBomNumerator={setBomNumerator}
                />
              );
            })}
        </tbody>
      </Table>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
