import { items } from "@/api/items/useItemQuery";
import { itemTypeColor } from "@/constants/items";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { Badge, Flex } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ItemsDetailForm } from "../ItemsDetailForm";

interface RoutingBomItemRowProps {
  bom: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
}

export const RoutingBomItemRow = ({ bom }: RoutingBomItemRowProps) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const { data } = useQuery(
    items.getView({
      query: {
        code: bom.itemCode,
      },
    }),
  );

  const itemData = data?.data?.rows?.[0];

  return (
    <tr key={bom.itemCode}>
      <td>
        <Badge color={itemTypeColor[itemData?.itemType!]}>{t(itemData?.itemType!)}</Badge>
      </td>
      <td>
        <Flex justify="flex-start">
          <DetailLink
            onClick={() =>
              openModal(<ItemsDetailForm itemCode={bom.itemCode as string} />, null, "품목 상세")
            }
          >
            {itemData?.name}
          </DetailLink>
        </Flex>
      </td>
      <td>{itemData?.gradeName}</td>
      <td>{itemData?.itemCategoryName}</td>
      <td>{itemData?.abstractItemCode}</td>
      <td>{itemData?.unitText}</td>
      <td>{bom.quantityNumerator}</td>
    </tr>
  );
};
