import { Table, TextInput } from "@mantine/core";

interface FilterTableProps {
  searchInputRefs: React.MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
  filterQueries: { [key: string]: string };
  handleFilterChange: (e: any) => void;
}

export const FilterTable = (params: FilterTableProps) => {
  const { searchInputRefs, filterQueries, handleFilterChange } = params;
  return (
    <Table>
      <thead>
        <tr>
          <th>설비명</th>
          <th>품목명</th>
          <th>품목코드</th>
          <th>규격</th>
          <th>작업지시번호</th>
          <th>작업자</th>
          <th>로트이름</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.keys(filterQueries).map((key) => (
            <td key={key}>
              <TextInput
                ref={(el) => {
                  searchInputRefs.current[key] = el;
                }}
                placeholder={key}
                name={key}
                value={filterQueries[key]}
                onChange={handleFilterChange}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};
