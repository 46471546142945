import { customFunctions } from "@/config/customFunction";
import { CloseForm } from "@/features/inventory/components/Form/CloseForm";
import { DeferredLotInfoForm } from "@/features/inventory/components/Form/DeferredLotInfoForm";
import { DiligenceAllForm } from "@/features/inventory/components/Form/DiligenceAllForm";
import { DiligenceForm } from "@/features/inventory/components/Form/DiligenceForm";
import { IncomingAllForm } from "@/features/inventory/components/Form/IncomingAllForm";
import { IncomingBulkForm } from "@/features/inventory/components/Form/IncomingBulkForm";
import { IncomingForm } from "@/features/inventory/components/Form/IncomingForm";
import { OutgoingAllForm } from "@/features/inventory/components/Form/OutgoingAllForm";
import { OutgoingForm } from "@/features/inventory/components/Form/OutgoingForm";
import { TransferAllForm } from "@/features/inventory/components/Form/TransferAllForm";
import { TransferForm } from "@/features/inventory/components/Form/TransferForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import customAlert from "@/features/ui/alert/alert";
import { useLoader } from "@/hooks/useLoader";
import DefaultInstance from "@/instance/axios";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkData";
import { dataStyle, excelDownLoad, headerStyle } from "@/utils/excelDownLoad";
import { Button, Flex } from "@mantine/core";
import { InventoriesBetweenGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconArrowBarToRight,
  IconCalendarOff,
  IconEdit,
  IconFileSpreadsheet,
  IconMinus,
  IconPlus,
  IconPrinter,
  IconRecycle,
  IconWritingSign,
} from "@tabler/icons-react";
import axios from "axios";
import dayjs from "dayjs";

const printBarcode = async (rows: InventoriesBetweenGet200ResponseRowsInner[]) => {
  const hasLotName = rows.map((row) => row.lot?.name).every((name) => name !== null);

  if (window.confirm("바코드를 출력하시겠습니까?")) {
    if (!hasLotName) {
      alert("바코드를 출력할 수 없습니다. 로트명이 없는 재고가 포함되어 있습니다.");
    }

    try {
      await axios.post(
        `${customFunctions.ADD_COMPANY_BARCODE_ADDRESS_INVENTORY}`,
        {
          company_code: customFunctions.ADD_COMPANY_CODE,
          lot_name: rows.map((row) => row.lot?.name),
          printer_code: await getPrinterCodeByUserWithWhoami(),
          quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
          location_code: rows.map((row) => row.locationCode),
          item_code: rows.map((row) => row.itemCode),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      alert("바코드 출력이 완료되었습니다.");
    } catch (error) {
      alert("바코드 출력에 실패하였습니다.");
      console.error("Error:", error);
    }
  }
};

// 제네릭을 포함한 ES6 문법의 ActionHeader 컴포넌트
export const ActionHeader = () => {
  const { openModal } = useModal();
  const {
    query,
    search,
    searchFields,
    searchFieldsHeader,
    sort,
    populate,
    startDate,
    endDate,
    selectedRows,
    setSelectedRows,
    resetQueryStrings,
    refetch,
  } = useStandardLayout();
  const { LoadingOverlay, setLoading } = useLoader();

  const excelDownLoadAction = async () => {
    try {
      setLoading(true);

      const response = await DefaultInstance.inventoriesBetweenFindPost(
        {
          query: JSON.stringify({
            $and: query,
            createdAt: {
              $lte: dayjs(endDate).endOf("day").toISOString(),
            },
          }),
          search,
          searchFields: searchFields.length
            ? searchFields
            : searchFieldsHeader
                .filter((item) => item.category === "text" && !item.isEnum)
                .map((item) => item.value),
          ...(sort.length ? { sort: sort.join(",") } : {}),
          populate: populate && populate.length ? populate : [],
        },
        {
          params: {
            targetDateString: dayjs(startDate).startOf("day").toISOString(),
            targetDateEndString: dayjs(endDate).endOf("day").toISOString(),
          },
        },
      );

      const data = response.data;

      const headerRow = [
        { v: "품목 명", t: "s", s: headerStyle },
        { v: "규격", t: "s", s: headerStyle },
        { v: "품목 유형", t: "s", s: headerStyle },
        { v: "로트명", t: "s", s: headerStyle },
        { v: "로트 유효기한", t: "s", s: headerStyle },
        { v: "위치", t: "s", s: headerStyle },
        { v: "입고일", t: "s", s: headerStyle },
        { v: "마감 재고", t: "s", s: headerStyle },
        { v: "마감일시", t: "s", s: headerStyle },
        { v: "추가된 재고", t: "s", s: headerStyle },
        { v: "감소된 재고", t: "s", s: headerStyle },
        { v: "재고 변화량", t: "s", s: headerStyle },
        { v: "이월 재고", t: "s", s: headerStyle },
        { v: "최종 재고", t: "s", s: headerStyle },
      ];

      const dataRows =
        data.map((item: InventoriesBetweenGet200ResponseRowsInner) => {
          return [
            { v: item.itemName ?? "", t: "s", s: dataStyle },
            { v: item.spec ?? "", t: "s", s: dataStyle },
            { v: item.itemType ?? "", t: "s", s: dataStyle },
            { v: item.lot?.name ?? "", t: "s", s: dataStyle },
            {
              v: item.lot?.expiration
                ? dayjs(item.lot.expiration).format("YYYY-MM-DD").toString()
                : "",
              t: "s",
              s: dataStyle,
            },
            { v: item.locationName ?? "", t: "s", s: dataStyle },
            {
              v: item.createdAt ? dayjs(item.createdAt).format("YYYY-MM-DD").toString() : "",
              t: "s",
              s: dataStyle,
            },
            { v: item.closedQuantity ?? "", t: "s", s: dataStyle },
            {
              v: item.closedAt ? dayjs(item.closedAt).format("YYYY-MM-DD HH:mm:ss").toString() : "",
              t: "s",
              s: dataStyle,
            },
            { v: item.increaseQuantity ?? "", t: "s", s: dataStyle },
            { v: item.decreaseQuantity ?? "", t: "s", s: dataStyle },
            { v: item.deltaQuantity ?? "", t: "s", s: dataStyle },
            { v: item.quantityAtStart ?? "", t: "s", s: dataStyle },
            { v: item.quantityAtEnd ?? "", t: "s", s: dataStyle },
          ];
        }) ?? [];

      await excelDownLoad({
        headerRow,
        dataRows,
        colWidths: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120],
        fileName: "재고현황",
      });
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  // 선택된 행 중에 최종재고가 0인 행이 있는지 확인
  const hasInvalidQuantity = [...selectedRows].some((row) => {
    const parsedRow = JSON.parse(row);
    return Number(parsedRow.quantity) <= 0;
  });

  // 출고 바코드출력 재고정보재입력 마감 조정 일괄조정 이동
  // setSelectedRows((): Set<any> => new Set());
  return (
    <Flex w="100%" justify="space-between" wrap="wrap">
      <LoadingOverlay />
      <Flex gap="sm" justify="flex-start" p="sm">
        <Button
          leftIcon={<IconPlus />}
          onClick={() => openModal(<IncomingForm />, null, "재고 입고", true)}
        >
          {/* 만료일 선택 시 날짜 감춰짐 수정 필요 */}
          입고
        </Button>
        <Button
          leftIcon={<IconPlus />}
          color="indigo"
          onClick={() => openModal(<IncomingAllForm />, null, "재고 일괄 입고", true)}
        >
          일괄 입고
        </Button>
        {customFunctions.ADD_BULK_INCOMING_BUTTON && (
          <Button
            leftIcon={<IconPlus />}
            color="grape"
            onClick={() => openModal(<IncomingBulkForm />, null, "재고 입고", true)}
          >
            벌크 입고
          </Button>
        )}
        <Button
          leftIcon={<IconMinus />}
          color="red"
          onClick={() => {
            if ([...selectedRows].length === 1) {
              openModal(
                <OutgoingForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
                null,
                "재고 출고",
                true,
              ).then(() => {
                // 로직이 정상적으로 처리되면 선택된 행을 초기화
                setSelectedRows((): Set<any> => new Set());
              });
            }
          }}
          disabled={!([...selectedRows].length === 1) || hasInvalidQuantity}
        >
          출고
        </Button>
        <Button
          leftIcon={<IconMinus />}
          color="pink"
          onClick={() => {
            openModal(<OutgoingAllForm />, null, "재고 벌크 출고", true);
          }}
        >
          벌크 출고
        </Button>
        <Button
          leftIcon={<IconPrinter />}
          color="orange"
          onClick={() =>
            printBarcode([...selectedRows].map((row) => JSON.parse(row))).then((result) => {
              // 로직이 정상적으로 처리되면 선택된 행을 초기화
              setSelectedRows((): Set<any> => new Set());
            })
          }
          disabled={[...selectedRows].length < 1}
        >
          바코드 출력
        </Button>
      </Flex>
      <Flex gap="sm" justify="flex-end" p="sm">
        <Button
          rightIcon={<IconFileSpreadsheet />}
          color="teal"
          onClick={() => excelDownLoadAction()}
        >
          엑셀 다운로드
        </Button>
        <Button
          rightIcon={<IconWritingSign />}
          color="green"
          onClick={() => {
            // data?.isUnknown === true
            const row = JSON.parse([...selectedRows].at(0));

            if (row?.isUnknown) {
              openModal(
                <DeferredLotInfoForm formatterProps={row} />,
                null,
                "재고 정보 재입력",
                true,
              ).then(() => {
                // 로직이 정상적으로 처리되면 선택된 행을 초기화
                setSelectedRows((): Set<any> => new Set());
              });
            } else {
              customAlert(
                "재고 정보 재입력이 불가능한 재고입니다.",
                "재고 정보 재입력 실패",
                "red",
              );
            }
          }}
          disabled={!([...selectedRows].length === 1)} // TODO: !data?.isUnknown === true
        >
          재고 정보 재입력
        </Button>
        <Button
          rightIcon={<IconCalendarOff />}
          color="indigo"
          onClick={() => {
            if ([...selectedRows].length === 1) {
              openModal(
                <CloseForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
                null,
                "재고 마감",
                true,
              ).then(() => {
                // 로직이 정상적으로 처리되면 선택된 행을 초기화
                setSelectedRows((): Set<any> => new Set());
              });
            }
          }}
          disabled={!([...selectedRows].length === 1)}
        >
          마감
        </Button>
        <Button
          rightIcon={<IconEdit />}
          color="yellow"
          onClick={() => {
            if ([...selectedRows].length === 1) {
              openModal(
                <DiligenceForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
                null,
                "재고 조정",
                true,
              ).then(() => {
                // 로직이 정상적으로 처리되면 선택된 행을 초기화
                setSelectedRows((): Set<any> => new Set());
              });
            }
          }}
          disabled={!([...selectedRows].length === 1)}
        >
          조정
        </Button>
        <Button
          rightIcon={<IconEdit />}
          color="lime"
          onClick={() => {
            if ([...selectedRows].length >= 2) {
              openModal(
                <DiligenceAllForm
                  formatterProps={[...selectedRows].map((row) => JSON.parse(row))}
                />,
                null,
                "재고 일괄 조정",
                true,
              ).then(() => {
                // 로직이 정상적으로 처리되면 선택된 행을 초기화
                setSelectedRows((): Set<any> => new Set());
              });
            }
          }}
          disabled={[...selectedRows].length < 2}
        >
          일괄 조정
        </Button>
        <Button
          color="red"
          rightIcon={<IconArrowBarToRight />}
          onClick={() =>
            openModal(
              <TransferForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
              null,
              "재고 이동",
              true,
            ).then(() => {
              // 로직이 정상적으로 처리되면 선택된 행을 초기화
              setSelectedRows((): Set<any> => new Set());
            })
          }
          disabled={!([...selectedRows].length === 1) || hasInvalidQuantity}
        >
          이동
        </Button>
        <Button
          color="pink"
          rightIcon={<IconArrowBarToRight />}
          onClick={() => {
            refetch();
            openModal(<TransferAllForm />, null, "재고 벌크 이동", true);
          }}
        >
          벌크 이동
        </Button>
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          검색값 초기화
        </Button>
      </Flex>
    </Flex>
  );
};
