import { BASE_URL } from "@/instance/axios";
import { jwtDecode } from "jwt-decode";

/**
 * 작성자명 : 원종석
 * 작성일자 : 2023.12.26 (화)
 * 작성내용 : 입력값이 다음과 같을 경우, 빈 값이라고 판단하여 true를 반환한다.
 *      -> undefined, null, 빈 문자열
 * @param input string, null, undefined 타입의 데이터
 * @returns boolean 형식 (true or false)
 */
const isEmpty = (input: string | null | undefined): boolean => {
  if (typeof input === "undefined" || input === null || input.length === 0) return true;

  return false;
};

export const isEmptyObject = (input: object): boolean => {
  return Object.keys(input).length === 0;
};

interface User {
  id: number;
  code: string;
  username: string;
  name: string;
  printerCode: string;
}

interface MyToken {
  scope: string[];
  user: User;
  iat: number;
}

const getPrinterCodeByUserWithWhoami = async () => {
  const token = localStorage.getItem("authToken") ?? "";

  try {
    const response = await fetch(`${BASE_URL}/auth/whoami`, {
      method: "GET", // HTTP 메소드 지정
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const data = await response.json(); // 응답을 JSON 형태로 파싱›

    return data.printerCode; // printerCode 반환
  } catch (error) {
    console.error("Error:", error); // 오류 처리
    return {};
  }
};

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.04.08 (월)
 * 작성내용 : localStorage에 담긴 토큰을 복호화하여 사용자의 프린터 코드를 반환합니다.
 * @returns 사용자의 프린터 코드를 반환합니다.
 */
const getPrinterCodeByUser = (): any => {
  const token = localStorage.getItem("authToken") ?? "";

  try {
    if (!token) return "";
    // 토큰을 해독하여 페이로드(payload)를 반환합니다.
    const decodedToken: MyToken = jwtDecode(token);

    return decodedToken.user.printerCode;
  } catch (error) {
    // 복호화 중 에러가 발생한 경우 빈 객체를 반환합니다.
    console.error("Error decoding JWT:", error);
    return {};
  }
};

const getUserCodeByUser = (): any => {
  const token = localStorage.getItem("authToken") ?? "";

  try {
    if (!token) return "";
    // 토큰을 해독하여 페이로드(payload)를 반환합니다.
    const decodedToken: MyToken = jwtDecode(token);

    return decodedToken.user.code;
  } catch (error) {
    // 복호화 중 에러가 발생한 경우 빈 객체를 반환합니다.
    console.error("Error decoding JWT:", error);
    return {};
  }
};

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.10.30 (수)
 * 작성내용 : 랜덤한 문자열 값을 생성합니다.
 * @param length 16 (default)
 * @returns
 */
const generateRandomValue = (length: number = 16): string => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-={}[]|:;<>,.?/~`";

  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars[randomIndex];
  }

  return result;
};

export {
  generateRandomValue,
  getPrinterCodeByUser,
  getPrinterCodeByUserWithWhoami,
  getUserCodeByUser,
  isEmpty,
};
