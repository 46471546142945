import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { LotTrackingLedgerTrackingContent } from "@/features/lotTracking/components/ledgerTracking";
import { LotTrackingWorkHistoryContent } from "@/features/lotTracking/components/workHistory";
import { LotTrackingForwardItemContent } from "@/features/lotTracking/forward/components/forwardItem";
import { LotTrackingForwardSummaryContent } from "@/features/lotTracking/forward/components/forwardSummary";
import { LotTrackingForwardActionHeader } from "@/features/lotTracking/forward/header/lotTrackingActionHeader";
import { useLotTrackingActions } from "@/features/lotTracking/hook/useLotTrackingActions";
import { useLotTrackingState } from "@/features/lotTracking/hook/useLotTrackingState";
import { Flex, Text, Title } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const LotTrackingForwardPage = () => {
  const state = useLotTrackingState();
  const actions = useLotTrackingActions();
  const location = useLocation();

  useEffect(() => {
    // 필요한 경우 조건 추가
    if (state.itemCodeValue && state.lotId && location.state?.value === "click") {
      actions.setProductionPlan(undefined);
    } else if (state.itemCodeValue || state.lotId || state.productionPlan) {
      actions.setProductionPlan(undefined);
      actions.setLotId(null);
      actions.setItemCodeValue(null);
    }
  }, []);

  const { data: workLogData } = useQuery({
    ...workLogs.find({
      query: {
        $and: [
          { lotId: { $eq: state.lotId } },
          { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CONSUME } },
        ],
      },
    }),
    enabled: !!state.lotId,
  });
  const productionPlanIds = Array.from(
    new Set(
      workLogData?.data?.map((row) => row.productionPlanId)?.filter((id) => id !== undefined),
    ),
  );

  const data = useQueries({
    queries: productionPlanIds.map((id) => {
      return {
        ...workLogs.find({
          query: {
            $and: [
              { productionPlanId: { $eq: id } },
              { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PRODUCTION } },
            ],
          },
          populate: ["itemData", "lotData", "workData"],
        }),
      };
    }),
  });

  return (
    <Flex w="100%" h="100%" direction="column">
      <Flex px="sm">
        <LotTrackingForwardActionHeader />
      </Flex>
      <Flex direction="column">
        <Title p={8} mb={8} order={1}>
          정방향 로트 추적
        </Title>
        <Text fz={12} p={8}>
          출고된 품목의 로트가 어떤 생산계획 / 작업지시에서 어떤 원부자재의 로트를 사용했는지
          확인하기 위해 사용합니다.
        </Text>
        <LotTrackingForwardItemContent />
        <LotTrackingForwardSummaryContent summaryData={data?.map((d) => d.data)} />
        {state.productionPlan && (
          <>
            <LotTrackingWorkHistoryContent />
            <LotTrackingLedgerTrackingContent />
          </>
        )}
        {/* <LotTrackingQualityHistoryContent /> */}
      </Flex>
    </Flex>
  );
};
