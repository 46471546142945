import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, NumberInput, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  DefaultApiWmsStockSerialPostRequest,
  WmsStockSerialPostRequest,
  WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRef } from "react";

const Create = Object.assign(
  {},
  {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    NumberInput: NumberInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
  },
);

export const IncomingAllForm = () => {
  const { closeModal } = useModal();

  const queryClient = useQueryClient();
  const { mutate: incomingAllMutate } = useMutation(
    (params: DefaultApiWmsStockSerialPostRequest) =>
      mutateInventories
        .incomingAll(params)
        .mutationFn(params as DefaultApiWmsStockSerialPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
      },
    },
  );
  const form = useForm<Partial<WmsStockSerialPostRequest>>({
    initialValues: {
      itemCode: undefined,
      locationCode: undefined,
      quantity: undefined,
      lotInfo: {
        name: undefined,
        expiration: undefined,
      },
      additional: {},
      num: undefined,
    },
  });
  const { data: itemData } = useQuery(
    items.codeSearch({
      query: form.values.itemCode ? { $and: [{ code: { $eq: form.values.itemCode } }] } : "",
    }),
  );

  const isSubmitting = useRef(false); // 추가

  const onSubmit = () => {
    if (isSubmitting.current) return; // 중복 요청 방지

    isSubmitting.current = true;
    try {
      const { itemCode, locationCode, quantity, lotInfo, num } = form.values;

      incomingAllMutate(
        {
          wmsStockSerialPostRequest: {
            itemCode: itemCode as string,
            locationCode: locationCode as string,
            quantity: quantity as string,
            lotInfo:
              lotInfo as WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo | null,
            num: Number(num),
            actionFrom: "WEB",
          },
        },
        {
          onSuccess: async (res: { data: any; status: number }) => {
            closeModal(form.values);
            customAlert("재고 입고에 성공하였습니다.", "재고 입고 성공", "green");
            const lotInfo = res.data.map((d: any) => d.lotData.name);
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_INCOMING_ALL}`,
                {
                  company_code: process.env.REACT_APP_COMPANY_CODE, // TODO: 환경변수로 관리?
                  lot_name: lotInfo,
                  printer_code: await getPrinterCodeByUserWithWhoami(),
                  quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
                  location_code: locationCode,
                  item_code: itemCode,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
              console.log(response.data);
            } catch (error) {
              console.error("Error:", error);
            }
          },
          onError: (error: any) => {
            customAlert(
              handleErrorResponse({ error, errorContext: "재고 입고" }),
              "재고 입고 실패",
              "red",
            );
            isSubmitting.current = false;
          },
        },
      );
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "재고 입고" }),
        "재고 입고 실패",
        "red",
      );
      isSubmitting.current = false;
    }
  };

  const item =
    itemData?.data?.rows && itemData?.data?.rows.find((row) => row.code === form.values.itemCode);

  return (
    <>
      <Create.Wrapper>
        <Create.ItemAutoComplete
          label="품목코드"
          withAsterisk
          {...form.getInputProps("itemCode")}
        />
        <Create.LocationAutoComplete
          label="로케이션코드"
          withAsterisk
          {...form.getInputProps("locationCode")}
        />
        <Create.Input label="수량" withAsterisk type="number" {...form.getInputProps("quantity")} />
        <Create.Input
          label="로트명"
          description={`${form.values.itemCode && item?.usesLotName ? "" : "LOT 관리 기준 : 이름"}`}
          styles={
            form.values.itemCode && item?.usesLotName
              ? {
                  description: { fontSize: 10, color: "red" },
                  input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                }
              : {
                  description: { fontSize: 10, color: "red" },
                }
          }
          disabled={form.values.itemCode && item?.usesLotName ? false : true}
          withAsterisk
          type="text"
          {...form.getInputProps("lotInfo.name")}
        />
        <Create.Date
          label="로트 유효기한"
          clearable
          description={`${
            form.values.itemCode && item?.usesLotExpiration ? "" : "LOT 관리 기준 : 만료일"
          }`}
          styles={
            form.values.itemCode && item?.usesLotExpiration
              ? {
                  description: { fontSize: 10, color: "red" },
                  input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                }
              : {
                  description: { fontSize: 10, color: "red" },
                }
          }
          disabled={form.values.itemCode && item?.usesLotExpiration ? false : true}
          withAsterisk={form.values.itemCode && item?.usesLotExpiration ? true : false}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("lotInfo.expiration")}
        />
        <Create.NumberInput
          label="입고할 로트 갯수"
          withAsterisk
          type="number"
          {...form.getInputProps("num")}
        />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button
            disabled={
              validateFormValues({
                pageType: "incomingAll",
                values: form.values,
              }) ||
              (item?.usesLotExpiration && !form.values.lotInfo?.expiration)
            }
            onClick={onSubmit}
          >
            재고 일괄 입고
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
