import { mutateDefect } from "@/api/defect/useDefectsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDefectsDefectCodePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// interface DefectUpdateFormValues {
//   code: string | undefined;
//   name: string | undefined;
// }

// interface DefectUpdateFormProps {
//   onSubmit?: (values: DefectUpdateFormValues) => void;
//   formatterProps: DefectsGet200ResponseRowsInner;
// }

export const DefectUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiDefectsDefectCodePutRequest) =>
      mutateDefect.update(params).mutationFn(params as DefaultApiDefectsDefectCodePutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defects"]);
        closeModal(formatterProps);
        customAlert("불량코드 수정에 성공하였습니다.", "수정 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "불량코드" }),
          "불량 수정 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      operationCode: formatterProps.operationCode,
    },
  });
  const onSubmit = async () => {
    try {
      updateMutate({
        defectCode: formatterProps.code,
        defectsGetRequest: {
          code: form.values.code,
          name: form.values.name,
          operationCode: form.values.operationCode,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "불량코드" }),
        "불량 수정 실패",
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label="불량코드"
            placeholder="불량코드"
            disabled
            {...form.getInputProps("code")}
            defaultValue={formatterProps.code}
          />
          <TextInput
            withAsterisk
            label="불량명"
            placeholder="불량명"
            {...form.getInputProps("name")}
            defaultValue={formatterProps.name}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <OperationAutoComplete
            label="공정코드"
            defaultValue={formatterProps?.operationCode}
            {...form.getInputProps("operationCode")}
            error={form.isDirty("operationCode") ? "저장 필요" : ""}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "defects",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              불량코드 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
