import { mutateItems } from "@/api/items/useItemQuery";
import { customFunctions } from "@/config/customFunction";
import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemsCreateForm } from "@/features/item/components/form/ItemsCreateForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemsUpdateForm } from "@/features/item/components/form/ItemsUpdateForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Badge, Checkbox, Flex, Text, Tooltip } from "@mantine/core";
import {
  ItemsGet200Response,
  ItemsGet200ResponseRowsInner,
  MasterApiItemsItemIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const Item = () => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiItemsItemIdDeleteRequest) =>
      mutateItems.delete(params).mutationFn(params as MasterApiItemsItemIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert("품목 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "품목 코드" }),
          "품목 삭제 실패",
          "red",
        );
      },
    },
  );

  const columns: readonly Column<ItemsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "itemType",
        name: "품목군",
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <Badge size="lg" color={itemTypeColor[row.itemType]}>
              {t(row.itemType)}
            </Badge>
          );
        },
      },
      {
        key: "name",
        name: "품목명",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(<ItemsDetailForm itemCode={formatterProps?.row.code} />, null, "품목 상세");
          };

          return <LinkAnchor onClick={detailItemView}>{formatterProps.row.name}</LinkAnchor>;
        },
      },
      ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
        ? [
            {
              key: "gradeName",
              name: "Grade명",
              sortable: true,
              resizable: true,
            },
          ]
        : []),
      // {
      //   key: "code",
      //   name: "품목코드",
      //   sortable: true,
      //   resizable: true,
      // },
      {
        key: "itemCategoryName",
        name: "Color",
        width: 80,
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() => {
                openModal(
                  <ItemCategoryDetailForm ItemCategoryName={formatterProps.row.itemCategoryName} />,
                  null,
                  "",
                );
              }}
            >
              {formatterProps.row.itemCategoryName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "abstractItemCode",
        name: "형태",
        width: 80,
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() => {
                openModal(
                  <AbstractItemDetailForm AbstractItemCode={formatterProps.row.abstractItemCode} />,
                  null,
                  "",
                  true,
                );
              }}
            >
              {formatterProps.row.abstractItemCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "unitText",
        name: "단위",
        sortable: true,
        resizable: true,
      },
      {
        key: "lot",
        name: "LOT 관리 기준",
        width: 200,
        formatter: (row) => {
          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <Checkbox value="usesLotName" label="이름" disabled checked={row?.row.usesLotName} />
              <Checkbox
                value="useLotExpiration"
                label="유효기한"
                disabled
                checked={row?.row.usesLotExpiration}
              />
            </Flex>
          );
        },
      },
      {
        key: "options",
        name: "액션",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(<ItemsDetailForm itemCode={formatterProps?.row.code} />, null, "품목 상세");
          };

          const updateItemActionView = () => {
            openModal(
              <ItemsUpdateForm formatterProps={formatterProps?.row} />,
              null,
              "품목 수정",
              true,
            );
          };

          const deleteItemAction = () => {
            openModal(
              <ConfirmForm
                message="선택한 행을 삭제하시겠습니까?"
                yesCallback={() => {
                  deleteMutate({ itemId: formatterProps?.row.id });
                }}
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              "품목 삭제",
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={detailItemView} color="blue">
                <IconSearch />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={updateItemActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteItemAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal, t],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 품목을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ itemId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "품목 삭제",
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() => openModal(<ItemsCreateForm />, null, "품목 추가", true)}
          createDataTitle="품목"
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          품목 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 원/부자재, 제품, 반제품 등에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<ItemsGet200Response, ItemsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<ItemsGet200Response> />
      </Flex>
    </Flex>
  );
};
