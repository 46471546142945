import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, NumberInput, Text } from "@mantine/core";

import { mutateMoldCavity } from "@/api/mold/useMoldCavityQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { DefaultApiMoldCavitiesMoldCavityIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
  Button: Button,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
});

export const MoldCavityUpdateForm = (formatterProps: any) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiMoldCavitiesMoldCavityIdPutRequest) =>
      mutateMoldCavity
        .update(params)
        .mutationFn(params as DefaultApiMoldCavitiesMoldCavityIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldCavity"]);
      },
    },
  );

  const form = useForm({
    initialValues: {
      moldCode: formatterProps.formatterProps.moldCode,
      num: formatterProps.formatterProps.num,
      itemCode: formatterProps.formatterProps.itemCode,
      abstractItemCode: formatterProps.formatterProps.abstractItemCode,
      seq: formatterProps.formatterProps.seq,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          moldCavityId: formatterProps.formatterProps.id,
          moldCavitiesGetRequest: {
            moldCode: form.values.moldCode!,
            num: form.values.num!,
            itemCode: form.values.itemCode!,
            abstractItemCode: form.values.abstractItemCode!,
            seq: form.values.seq!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps.formatterProps);
            if (res.status === 200) {
              customAlert("금형캐비티 수정에 성공하였습니다.", "수정 성공", "green");
            } else {
              customAlert("금형캐비티 수정에 실패하였습니다.", "수정 실패", "red");
            }
          },
        },
      );
    } catch (e) {
      customAlert("금형캐비티 수정에 실패하였습니다.", "수정 실패", "red");
    }
  };

  return (
    <>
      <Update.Wrapper>
        <MoldAutoComplete
          label="금형코드"
          width="100%"
          {...form.getInputProps("moldCode")}
          withAsterisk
          defaultValue={formatterProps.formatterProps.moldCode}
          error={form.isDirty("moldCode") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("moldCode") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("moldCode") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <Update.NumberInput
          label="갯수"
          {...form.getInputProps("num")}
          defaultValue={formatterProps.formatterProps.num}
          withAsterisk
          error={form.isDirty("num") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("num") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("num") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <ItemAutoComplete
          label="품목코드"
          width="100%"
          {...form.getInputProps("itemCode")}
          disabled={formatterProps.formatterProps.abstractItemCode ? true : false}
          defaultValue={formatterProps.formatterProps.itemCode}
          error={form.isDirty("itemCode") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("itemCode") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("itemCode") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <AbstractItemAutoComplete
          label="형태 코드"
          width="100%"
          {...form.getInputProps("abstractItemCode")}
          disabled={formatterProps.formatterProps.itemCode ? true : false}
          defaultValue={formatterProps.formatterProps.abstractItemCode}
          error={form.isDirty("abstractItemCode") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("abstractItemCode") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("abstractItemCode") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <Update.NumberInput
          label="캐비티 번호"
          {...form.getInputProps("seq")}
          defaultValue={formatterProps.formatterProps.seq}
          error={form.isDirty("seq") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("seq") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("seq") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal} className="cancel_btn">
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "moldCavity",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            금형캐비티 수정
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
