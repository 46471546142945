import { ColumnHeaderProps } from "@/constants/columnHeader";
import { sorterMap } from "@/constants/sorter";
import { useModal } from "@/features/modal/ModalStackManager";
import { Button, Flex, Select } from "@mantine/core";
import { useState } from "react";

interface SorterFormProps {
  searchFieldsHeader: ColumnHeaderProps[];
}

export const SorterForm = ({ searchFieldsHeader }: SorterFormProps) => {
  const { closeModal } = useModal();

  const [selectedElement, setSelectedElement] = useState<string>("");
  const [selectedCondition, setSelectedCondition] = useState<string>("");

  const onSubmit = () => {
    closeModal(`${selectedCondition}${selectedElement}`);
  };

  return (
    <Flex gap="md" direction="column">
      <Select
        label="정렬 요소"
        data={searchFieldsHeader}
        value={selectedElement}
        onChange={(e) => setSelectedElement(e ?? "")}
        withinPortal
      />
      <Select
        label="정렬 조건"
        data={sorterMap}
        value={selectedCondition}
        onChange={(e) => setSelectedCondition(e?.toString() ?? "")}
        withinPortal
      />
      <Flex justify="flex-end" gap="xs">
        <Button color="gray" onClick={() => closeModal(null)}>
          취소
        </Button>
        <Button onClick={() => onSubmit()} disabled={!selectedElement}>
          필터 추가
        </Button>
      </Flex>
    </Flex>
  );
};
