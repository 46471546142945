import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Flex, Table, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultApiWmsBulkDiligencePutRequest,
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  WmsBulkDiligencePutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface DiligenceAllFormProps {
  formatterProps: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[];
}

export const DiligenceAllForm = ({ formatterProps }: DiligenceAllFormProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: diligenceAllMutate } = useMutation(
    (params: DefaultApiWmsBulkDiligencePutRequest) =>
      mutateInventories
        .diligenceAll(params)
        .mutationFn(params as DefaultApiWmsBulkDiligencePutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert("재고 조정에 성공하였습니다.", "재고 조정 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "재고 조정" }),
          "재고 조정 실패",
          "red",
        );
      },
    },
  );

  const form = useForm<Partial<WmsBulkDiligencePutRequest>>({
    initialValues: {
      diligence: formatterProps.map((item) => ({
        itemCode: item.itemCode as string, // 기본값 제공
        locationCode: item.locationCode as string, // 기본값 제공
        quantity: item.quantity as string, // 기본값 제공
        lotId: item.lotId || 0, // 기본값 제공
        lotInfo: {
          name: item.lot?.name as string, // 기본값 제공
          expiration: item.lot?.expiration as string, // 기본값 제공
          actionFrom: "WEB",
        },
      })),
    },
  });

  const onSubmit = () => {
    try {
      diligenceAllMutate({
        wmsBulkDiligencePutRequest: {
          diligence: form.values.diligence ?? [],
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "재고 조정" }),
        "재고 조정 실패",
        "red",
      );
    }
  };

  return (
    <Flex w="60rem" direction="column" gap="xl">
      <Table>
        <thead>
          <tr>
            <Th width={3.75}>시퀀스</Th>
            <Th width={13.125}>품목코드</Th>
            <Th width={13.125}>로케이션코드</Th>
            <Th width={7.5}>수량</Th>
            <Th width={7.5}>로트 ID</Th>
            <Th width={7.5}>로트 명</Th>
            <Th width={7.5}>로트 유효 기간</Th>
          </tr>
        </thead>
        <tbody>
          {formatterProps.map((item, index) => (
            <tr key={item.lotId}>
              <td>{index + 1}</td>
              <td>
                <TextInput disabled {...form.getInputProps(`diligence.${index}.itemCode`)} />
              </td>
              <td>
                <TextInput disabled {...form.getInputProps(`diligence.${index}.locationCode`)} />
              </td>
              <td>
                <TextInput type="number" {...form.getInputProps(`diligence.${index}.quantity`)} />
              </td>
              <td>
                <TextInput
                  disabled
                  withAsterisk
                  {...form.getInputProps(`diligence.${index}.lotId`)}
                />
              </td>
              <td>
                <TextInput
                  disabled
                  withAsterisk
                  {...form.getInputProps(`diligence.${index}.lot.name`)}
                />
              </td>
              <td>
                <TextInput
                  disabled
                  withAsterisk
                  {...form.getInputProps(`diligence.${index}.lot.expiration`)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex justify="flex-end" gap="sm">
        <Button color="gray" onClick={() => closeModal({})}>
          취소
        </Button>
        <Button onClick={onSubmit}>재고 일괄 조정</Button>
      </Flex>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}rem` : "auto")};
`;
