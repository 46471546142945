import { WmsInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import {
  WmsApiStockPlansFindPostRequest,
  WmsApiStockPlansGetRequest,
  WmsApiStockPlansPostRequest,
  WmsApiStockPlansStockPlanIdDeleteRequest,
  WmsApiStockPlansStockPlanIdGetRequest,
  WmsApiStockPlansStockPlanIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const stockPlans = createQueryKeys("stockPlans", {
  all: null,
  get: (params: WmsApiStockPlansGetRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.
    // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => WmsInstance.stockPlansGet(query),
    };
  },
  detail: (params: WmsApiStockPlansStockPlanIdGetRequest) => ({
    queryKey: [params.stockPlanId],
    queryFn: () => WmsInstance.stockPlansStockPlanIdGet(params),
  }),
  find: (params: WmsApiStockPlansFindPostRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.
    // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => WmsInstance.stockPlansFindPost(query),
    };
  },
});

export const mutateStockPlans = createMutationKeys("stockPlans", {
  create: (params: WmsApiStockPlansPostRequest) => ({
    mutationKey: [params.stockPlansGetRequest],
    mutationFn: () => WmsInstance.stockPlansPost(params),
  }),
  update: (params: WmsApiStockPlansStockPlanIdPutRequest) => ({
    mutationKey: [params.stockPlanId, params.stockPlansGetRequest],
    mutationFn: () => WmsInstance.stockPlansStockPlanIdPut(params),
  }),
  delete: (params: WmsApiStockPlansStockPlanIdDeleteRequest) => ({
    mutationKey: [params.stockPlanId],
    mutationFn: () => WmsInstance.stockPlansStockPlanIdDelete(params),
  }),
});
