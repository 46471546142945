import { shipmentStatusHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { Calendar, CalendarDate, isCalendarDate } from "@/features/ui/Calendar";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { ActionIcon, Button, Flex, Pagination, Text, Title } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const ShipmentStatusWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const ShipmentStatusPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const [dateRange, setDateRange] = useState<CalendarDate>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);
  const { openModal } = useModal();
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = shipmentStatusHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const pageSize = 10;
  const totalRows = ShipmentStatusPageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = ShipmentStatusPageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "code",
      name: "CODE",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "serialNumber",
      name: "Serial 번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "partnerCode",
      name: "거래처",
      sortable: true,
      resizable: true,
    },
    {
      key: "date",
      name: "날짜",
      sortable: true,
      resizable: true,
    },
    {
      key: "quantity",
      name: "입고량",
      sortable: true,
      resizable: true,
    },
    {
      key: "price",
      name: "단가",
      sortable: true,
      resizable: true,
    },
    {
      key: "amount",
      name: "금액",
      sortable: true,
      resizable: true,
    },
    {
      key: "locationCode",
      name: "로케이션",
      sortable: true,
      resizable: true,
    },
    {
      key: "totalAmount",
      name: "총 금액",
      sortable: true,
      resizable: true,
    },
    {
      key: "status",
      name: "상태",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Text>{row.status === "Active" ? "입고 완료" : "입고 대기"}</Text>;
      },
    },
  ];

  return (
    <ShipmentStatusWrap.Wrapper>
      <Flex w="100%" p="xs" justify="flex-end" gap="xs">
        <Button color="yellow">바코드 미리보기</Button>
        <Button color="orange">취소하기</Button>
      </Flex>
      <Flex w="100%" p="xs" justify="center" gap="xs" align="center">
        <Title size="h1" weight={700}>
          {dayjs(dateRange[0]).format("YYYY-MM-DD")} ~ {dayjs(dateRange[1]).format("YYYY-MM-DD")}
        </Title>
        <ActionIcon
          onClick={() =>
            openModal(<Calendar dates={dateRange} type="range" />, null, "날짜 선택").then(
              (result) => {
                if (isCalendarDate(result)) {
                  setDateRange(result);
                }
              },
            )
          }
        >
          <IconCalendar size="2rem" />
        </ActionIcon>
      </Flex>
      <ShipmentStatusWrap.Header>
        <ShipmentStatusWrap.HeaderTitle>입고 현황</ShipmentStatusWrap.HeaderTitle>
        <ShipmentStatusWrap.HeaderSubTitle>
          {"우리 회사에 입고된 현황을 확인합니다."}
          {/* LotNo가 동일한 경우 취소하면 해당 LotNo의 모든 항목이 함께 취소됩니다. */}
        </ShipmentStatusWrap.HeaderSubTitle>
      </ShipmentStatusWrap.Header>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={shipmentStatusHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={shipmentStatusHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </ShipmentStatusWrap.Wrapper>
  );
};

const ShipmentStatusPageMockupData = [
  {
    code: "X2001",
    itemName: "Gadget Pro",
    spec: "500ml",
    serialNumber: "Z9876",
    partnerCode: "Supplier Alpha",
    date: "2024-10-30",
    quantity: 320,
    price: 28,
    amount: 8960,
    locationCode: "LOC3",
    totalAmount: 9600,
    status: "Inactive",
  },
  {
    code: "L5009",
    itemName: "Widget Basic",
    spec: "1L",
    serialNumber: "A1234",
    partnerCode: "Supplier Beta",
    date: "2024-10-29",
    quantity: 150,
    price: 45,
    amount: 6750,
    locationCode: "LOC2",
    totalAmount: 7200,
    status: "Active",
  },
  {
    code: "Z8934",
    itemName: "Component X",
    spec: "750ml",
    serialNumber: "G2345",
    partnerCode: "Supplier Gamma",
    date: "2024-10-28",
    quantity: 600,
    price: 15,
    amount: 9000,
    locationCode: "LOC4",
    totalAmount: 10000,
    status: "Inactive",
  },
  {
    code: "M4002",
    itemName: "Accessory Prime",
    spec: "250ml",
    serialNumber: "H6543",
    partnerCode: "Supplier Delta",
    date: "2024-10-27",
    quantity: 80,
    price: 85,
    amount: 6800,
    locationCode: "LOC1",
    totalAmount: 7400,
    status: "Active",
  },
  {
    code: "K9876",
    itemName: "Tool Master",
    spec: "100ml",
    serialNumber: "F2346",
    partnerCode: "Supplier Zeta",
    date: "2024-10-26",
    quantity: 900,
    price: 12,
    amount: 10800,
    locationCode: "LOC5",
    totalAmount: 11500,
    status: "Inactive",
  },
  {
    code: "W3005",
    itemName: "Utility Gear",
    spec: "300ml",
    serialNumber: "B1237",
    partnerCode: "Supplier Alpha",
    date: "2024-10-25",
    quantity: 200,
    price: 19,
    amount: 3800,
    locationCode: "LOC2",
    totalAmount: 4200,
    status: "Active",
  },
  {
    code: "P1123",
    itemName: "Device Ultra",
    spec: "450ml",
    serialNumber: "D7543",
    partnerCode: "Supplier Beta",
    date: "2024-10-24",
    quantity: 530,
    price: 25,
    amount: 13250,
    locationCode: "LOC1",
    totalAmount: 14000,
    status: "Inactive",
  },
  {
    code: "T3002",
    itemName: "Component Y",
    spec: "200ml",
    serialNumber: "Q8765",
    partnerCode: "Supplier Gamma",
    date: "2024-10-23",
    quantity: 110,
    price: 58,
    amount: 6380,
    locationCode: "LOC3",
    totalAmount: 6800,
    status: "Active",
  },
  {
    code: "B7001",
    itemName: "Gizmo Lite",
    spec: "600ml",
    serialNumber: "W2341",
    partnerCode: "Supplier Delta",
    date: "2024-10-22",
    quantity: 75,
    price: 95,
    amount: 7125,
    locationCode: "LOC5",
    totalAmount: 7800,
    status: "Inactive",
  },
  {
    code: "C1003",
    itemName: "Accessory Lite",
    spec: "50ml",
    serialNumber: "K1239",
    partnerCode: "Supplier Zeta",
    date: "2024-10-21",
    quantity: 850,
    price: 8,
    amount: 6800,
    locationCode: "LOC4",
    totalAmount: 7500,
    status: "Active",
  },
  {
    code: "N8004",
    itemName: "Gadget Deluxe",
    spec: "700ml",
    serialNumber: "U7632",
    partnerCode: "Supplier Alpha",
    date: "2024-10-20",
    quantity: 95,
    price: 92,
    amount: 8740,
    locationCode: "LOC1",
    totalAmount: 9500,
    status: "Inactive",
  },
  {
    code: "V6008",
    itemName: "Device Mini",
    spec: "900ml",
    serialNumber: "I9876",
    partnerCode: "Supplier Beta",
    date: "2024-10-19",
    quantity: 100,
    price: 10,
    amount: 1000,
    locationCode: "LOC2",
    totalAmount: 1100,
    status: "Active",
  },
  {
    code: "R5555",
    itemName: "Component Prime",
    spec: "800ml",
    serialNumber: "O2345",
    partnerCode: "Supplier Gamma",
    date: "2024-10-18",
    quantity: 75,
    price: 82,
    amount: 6150,
    locationCode: "LOC3",
    totalAmount: 7000,
    status: "Inactive",
  },
  {
    code: "S9888",
    itemName: "Tool Ultra",
    spec: "1L",
    serialNumber: "L6543",
    partnerCode: "Supplier Delta",
    date: "2024-10-17",
    quantity: 300,
    price: 33,
    amount: 9900,
    locationCode: "LOC4",
    totalAmount: 10500,
    status: "Active",
  },
  {
    code: "E1239",
    itemName: "Utility Master",
    spec: "350ml",
    serialNumber: "P2341",
    partnerCode: "Supplier Zeta",
    date: "2024-10-16",
    quantity: 420,
    price: 17,
    amount: 7140,
    locationCode: "LOC5",
    totalAmount: 7700,
    status: "Inactive",
  },
  {
    code: "Y1002",
    itemName: "Gizmo Prime",
    spec: "400ml",
    serialNumber: "C9872",
    partnerCode: "Supplier Alpha",
    date: "2024-10-15",
    quantity: 630,
    price: 21,
    amount: 13230,
    locationCode: "LOC2",
    totalAmount: 14000,
    status: "Active",
  },
  {
    code: "F4321",
    itemName: "Gadget Master",
    spec: "450ml",
    serialNumber: "V2346",
    partnerCode: "Supplier Beta",
    date: "2024-10-14",
    quantity: 200,
    price: 50,
    amount: 10000,
    locationCode: "LOC1",
    totalAmount: 11000,
    status: "Inactive",
  },
  {
    code: "G6789",
    itemName: "Accessory Deluxe",
    spec: "600ml",
    serialNumber: "X8765",
    partnerCode: "Supplier Gamma",
    date: "2024-10-13",
    quantity: 120,
    price: 60,
    amount: 7200,
    locationCode: "LOC3",
    totalAmount: 8000,
    status: "Active",
  },
  {
    code: "H2345",
    itemName: "Tool Basic",
    spec: "500ml",
    serialNumber: "B9876",
    partnerCode: "Supplier Delta",
    date: "2024-10-12",
    quantity: 900,
    price: 15,
    amount: 13500,
    locationCode: "LOC4",
    totalAmount: 15000,
    status: "Inactive",
  },
  {
    code: "I1298",
    itemName: "Device Pro",
    spec: "750ml",
    serialNumber: "R2345",
    partnerCode: "Supplier Zeta",
    date: "2024-10-11",
    quantity: 500,
    price: 55,
    amount: 27500,
    locationCode: "LOC5",
    totalAmount: 29000,
    status: "Active",
  },
  {
    code: "J4328",
    itemName: "Component Lite",
    spec: "800ml",
    serialNumber: "Z1234",
    partnerCode: "Supplier Alpha",
    date: "2024-10-10",
    quantity: 75,
    price: 80,
    amount: 6000,
    locationCode: "LOC1",
    totalAmount: 6500,
    status: "Inactive",
  },
  {
    code: "K7776",
    itemName: "Utility Basic",
    spec: "1L",
    serialNumber: "T9876",
    partnerCode: "Supplier Beta",
    date: "2024-10-09",
    quantity: 620,
    price: 28,
    amount: 17360,
    locationCode: "LOC2",
    totalAmount: 18000,
    status: "Active",
  },
  {
    code: "L8889",
    itemName: "Gizmo Ultra",
    spec: "300ml",
    serialNumber: "D9876",
    partnerCode: "Supplier Gamma",
    date: "2024-10-08",
    quantity: 450,
    price: 19,
    amount: 8550,
    locationCode: "LOC3",
    totalAmount: 9100,
    status: "Inactive",
  },
  {
    code: "M1234",
    itemName: "Tool Lite",
    spec: "150ml",
    serialNumber: "H1234",
    partnerCode: "Supplier Delta",
    date: "2024-10-07",
    quantity: 700,
    price: 14,
    amount: 9800,
    locationCode: "LOC4",
    totalAmount: 10500,
    status: "Active",
  },
  {
    code: "N4321",
    itemName: "Accessory Pro",
    spec: "250ml",
    serialNumber: "G6543",
    partnerCode: "Supplier Zeta",
    date: "2024-10-06",
    quantity: 340,
    price: 32,
    amount: 10880,
    locationCode: "LOC5",
    totalAmount: 11500,
    status: "Inactive",
  },
  {
    code: "O9876",
    itemName: "Component Pro",
    spec: "100ml",
    serialNumber: "V2345",
    partnerCode: "Supplier Alpha",
    date: "2024-10-05",
    quantity: 900,
    price: 22,
    amount: 19800,
    locationCode: "LOC1",
    totalAmount: 21000,
    status: "Active",
  },
  {
    code: "P6543",
    itemName: "Device Prime",
    spec: "1L",
    serialNumber: "W2341",
    partnerCode: "Supplier Beta",
    date: "2024-10-04",
    quantity: 1000,
    price: 30,
    amount: 30000,
    locationCode: "LOC2",
    totalAmount: 32000,
    status: "Inactive",
  },
  {
    code: "Q1239",
    itemName: "Gizmo Pro",
    spec: "500ml",
    serialNumber: "M9876",
    partnerCode: "Supplier Gamma",
    date: "2024-10-03",
    quantity: 500,
    price: 35,
    amount: 17500,
    locationCode: "LOC3",
    totalAmount: 19000,
    status: "Active",
  },
  {
    code: "R4567",
    itemName: "Utility Lite",
    spec: "750ml",
    serialNumber: "L1239",
    partnerCode: "Supplier Delta",
    date: "2024-10-02",
    quantity: 80,
    price: 65,
    amount: 5200,
    locationCode: "LOC4",
    totalAmount: 5800,
    status: "Inactive",
  },
  {
    code: "S3333",
    itemName: "Tool Pro",
    spec: "450ml",
    serialNumber: "Q8765",
    partnerCode: "Supplier Zeta",
    date: "2024-10-01",
    quantity: 720,
    price: 18,
    amount: 12960,
    locationCode: "LOC5",
    totalAmount: 13500,
    status: "Active",
  },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
