import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { Box, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultApiRoutingBomsPostRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  RoutingBomsGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const BomByOperationCreateWrap = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  ButtonBox: FormButtonBox,
});

type BomByOperationCreateFormProps = {
  routingCode: string | undefined;
};

export const BomByOperationCreateForm = ({ routingCode }: BomByOperationCreateFormProps) => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const { mutate: createMutate } = useMutation(
    (params: DefaultApiRoutingBomsPostRequest) =>
      mutateRoutingBoms.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customAlert("Bom 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "Bom 생성에 실패하였습니다.",
          "생성 실패",
          "red",
        );
      },
    },
  );

  const form = useForm<RoutingBomsGetRequest>({
    initialValues: {
      routingCode: routingCode as string, // 라우팅코드
      itemCode: "",
      quantityNumerator: undefined,
      description: "",
      seq: undefined,
    },
    validate: {
      itemCode: (value) => {
        if (!value) {
          return "아이템 코드를 입력해주세요";
        }
      },
      seq: (value) => {
        if (!value) {
          return "시퀀스를 입력해주세요";
        }
      },
      quantityNumerator: (value) => {
        if (!value) {
          return "수량을 입력해주세요";
        }
      },
    },
  });

  const onSubmit = (values: RoutingBomsGetRequest) => {
    createMutate(
      {
        routingBomsGetRequest: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["items"]);
          closeModal({});
        },
      },
    );
  };

  return (
    <Box>
      <form
        style={{
          width: "100%",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
        }}
        onSubmit={form.onSubmit((values) => onSubmit(values))}
      >
        <BomByOperationCreateWrap.Input label="시퀀스" {...form.getInputProps("seq")} />
        <ItemAutoComplete
          getItemType={[
            ItemsGet200ResponseRowsInnerItemTypeEnum.MATERIAL,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SUBSIDIARY,
          ]}
          label="아이템 이름"
          {...form.getInputProps("itemCode")}
        />
        <BomByOperationCreateWrap.Input
          label="비율 (%)"
          {...form.getInputProps("quantityNumerator")}
        />
        <Group position="right" mt="md">
          <BomByOperationCreateWrap.Button type="submit" color="green">
            {"추가"}
          </BomByOperationCreateWrap.Button>
        </Group>
      </form>
    </Box>
  );
};
