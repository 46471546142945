import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { customFunctions } from "@/config/customFunction";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderCreateForm } from "@/features/order/form/createForm";
import { OrderDetailForm } from "@/features/order/form/detailForm";
import { OrderUpdateForm } from "@/features/order/form/updateForm";
import { OrderActionHeader } from "@/features/order/header/orderActionHeader";
import { OrderTable } from "@/features/order/table";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { useLoader } from "@/hooks/useLoader";
import { getUserCodeByUser } from "@/utils/checkData";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Avatar, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  PurchaseOrdersGet200Response,
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";

const Order = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  OrderTable: OrderTable,
  OrderCreateForm: OrderCreateForm,
});

export const OrderPage = () => {
  const { openModal, closeModal } = useModal();
  const userCode = getUserCodeByUser();
  const { selectedRows, refetch } = useStandardLayout();

  const { loading, LoadingOverlay, setLoading } = useLoader();
  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
      mutatePurchaseOrders
        .delete(params)
        .mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${userCode === "FLOUR" ? "수주 삭제에 성공하였습니다." : "발주 삭제에 성공하였습니다."}`,
          "삭제 성공",
          "green",
        );
        closeModal(null);
      },
      onError: (error: any) => {
        customAlert(
          `${error.response?.data?.message}` ??
            `${userCode === "FLOUR" ? "수주 삭제에 실패하였습니다." : "발주 삭제에 실패하였습니다."}`,
          "삭제 실패",
          "red",
        );
      },
    },
  );

  const columns: readonly Column<PurchaseOrdersGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
    },
    {
      key: "id",
      name: `${userCode === "FLOUR" ? "수주번호" : "발주번호"}`,
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(<OrderDetailForm purchaseOrderId={formatterProps.row.id} />, null, "")
              }
            >
              {formatterProps.row.id}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "code",
      name: `${userCode === "FLOUR" ? "수주코드" : "발주코드"}`,
      sortable: true,
      resizable: true,
    },
    {
      key: "status",
      name: `${userCode === "FLOUR" ? "수주 상태" : "발주 상태"}`,
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            variant="filled"
            color={
              purchaseOrderStatusColor[
                formatterProps.row.status as keyof typeof purchaseOrderStatusColor
              ]
            }
          >
            {setPurchaseOrderStatus(formatterProps.row.status ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "partner.name",
      name: "협력사명",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                null,
                "협력사 상세",
              )
            }
          >
            {formatterProps.row.partner?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "manager",
      name: "담당자",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps.row?.userCode} />,
                null,
                "사용자 상세",
              )
            }
          >
            {formatterProps.row.manager}
          </LinkAnchor>
        );
      },
    },
    {
      key: "numberOfPurchaseOrderItems",
      name: `${userCode === "FLOUR" ? "수주항목 갯수" : "발주항목 갯수"}`,
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const betweenDecreaseQuantity = row?.numberOfPurchaseOrderItems ?? 0;
        return <Flex justify="flex-end">{betweenDecreaseQuantity}</Flex>;
      },
    },
    {
      key: "targetLocationName",
      name: "입고 로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps?.row?.targetLocationCode} />,
                null,
                "로케이션 상세",
              )
            }
          >
            {formatterProps.row.targetLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "orderDate",
      name: "발주일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.orderDate !== null
              ? timeUtil(formatterProps.row.orderDate as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      name: "접수일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "deliveryDeadline",
      name: "납기일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.deliveryDeadline !== null
              ? timeUtil(formatterProps.row.deliveryDeadline as string)
              : ""}
          </div>
        );
      },
    },
    ...(!customFunctions.ADD_ORDER_ACTION_BUTTON
      ? [
          {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps: {
              row: PurchaseOrdersGet200ResponseRowsInner | undefined;
            }) => {
              return (
                <Flex w="100%" h="100%" justify="center" align="center" gap="md">
                  <ActionIcon
                    variant="subtle"
                    onClick={() => {
                      openModal(
                        <OrderUpdateForm formatterProps={formatterProps.row} />,
                        null,
                        `${userCode === "FLOUR" ? "수주 수정" : "발주 수정"}`,
                      );
                    }}
                    color="yellow"
                  >
                    <IconEdit />
                  </ActionIcon>
                  <ActionIcon
                    variant="subtle"
                    onClick={() => {
                      openModal(
                        <ConfirmForm
                          message="정말로 삭제하시겠습니까?"
                          yesCallback={() => {
                            deleteMutate({ purchaseOrderId: formatterProps?.row?.id as number });
                          }}
                          noCallback={() => {
                            closeModal(null);
                          }}
                        />,
                        null,
                        `${userCode === "FLOUR" ? "수주 삭제" : "발주 삭제"}`,
                      );
                    }}
                    color="red"
                  >
                    <IconTrash />
                  </ActionIcon>
                </Flex>
              );
            },
          },
        ]
      : []),
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 발주를 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ purchaseOrderId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "발주 삭제",
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <LoadingOverlay />
      <OrderActionHeader setLoading={setLoading} />
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {userCode === "FLOUR" ? "수주" : "발주"}
        </Text>
        <Text fz="xs">공급업체에 주문 완료한 내역</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<PurchaseOrdersGet200Response, PurchaseOrdersGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<PurchaseOrdersGet200Response> />
      </Flex>
    </Flex>
  );
};
