import { mutateItems } from "@/api/items/useItemQuery";
import { customFunctions } from "@/config/customFunction";
import { itemType } from "@/constants/items";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { GradesAutoComplete } from "@/features/ui/autoComplete/grade/grades-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Checkbox, Flex, Select, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiItemsItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const ItemsUpdateForm = (params: { formatterProps: any }) => {
  const { t } = useTranslation();
  const lnItemType = itemType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiItemsItemIdPutRequest) =>
      mutateItems.update(params).mutationFn(params as MasterApiItemsItemIdPutRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["items"]);
        closeModal(formatterProps);
        customAlert("품목 수정에 성공하였습니다.", "수정 성공", "green");
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: "품목" }), "품목 수정 실패", "red");
      },
    },
  );

  const formData = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      itemType: formatterProps.itemType,
      itemCategoryName: formatterProps.itemCategoryName,
      abstractItemCode: formatterProps.abstractItemCode,
      spec: formatterProps.spec,
      width: Number(formatterProps.width) ?? undefined,
      height: Number(formatterProps.height) ?? undefined,
      depth: Number(formatterProps.depth) ?? undefined,
      texture: formatterProps.texture,
      standardUph: formatterProps.standardUph ?? undefined,
      unitText: formatterProps.unitText,
      gradeCode: formatterProps.gradeCode ?? undefined,
      usesLotName: formatterProps.usesLotName,
      usesLotExpiration: formatterProps.usesLotExpiration,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        itemId: params.formatterProps.id,
        itemsGetRequest: {
          name: formData.values.name as any,
          code: formData.values.code as any,
          itemType: formData.values.itemType as any,
          itemCategoryName: formData.values.itemCategoryName,
          abstractItemCode: formData.values.abstractItemCode,
          spec: formData.values.spec,
          width: formData.values.width.toString(),
          height: formData.values.height.toString(),
          depth: formData.values.depth.toString(),
          texture: formData.values.texture,
          standardUph: formData.values.standardUph,
          unitText: formData.values.unitText,
          gradeCode: formData.values.gradeCode,
          usesLotName: formData.values.usesLotName,
          usesLotExpiration: formData.values.usesLotExpiration,
        },
      });
    } catch (error) {
      customAlert(handleErrorResponse({ error, errorContext: "품목" }), "품목 수정 실패", "red");
    }
  };

  return (
    <Flex direction="column" gap="sm" p="md" w="100%">
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            품목군
          </Text>
        </Flex>
        <Select
          placeholder="품목군을 선택해 주세요."
          data={lnItemType}
          size="md"
          w="20rem"
          {...formData.getInputProps("itemType")}
          styles={{
            input: {
              color: formData.isDirty("itemType") ? "red !important" : "inherit",
              borderColor: formData.isDirty("itemType") ? "red !important" : "inherit",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            품목명
          </Text>
        </Flex>
        <TextInput
          placeholder="품목명"
          size="md"
          w="20rem"
          {...formData.getInputProps("name")}
          styles={{
            input: {
              color: formData.isDirty("name") ? "red !important" : "inherit",
              borderColor: formData.isDirty("name") ? "red !important" : "inherit",
            },
          }}
        />
      </Flex>
      {customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING && (
        <Flex gap="xl" align="center">
          <Flex gap="md" align="center">
            <Text fw={700} fz="lg" color="red" w="1rem">
              *
            </Text>
            <Text fw={700} fz="lg" color="red" w="10rem">
              등급
            </Text>
          </Flex>
          <GradesAutoComplete
            placeholder="등급을 선택해 주세요."
            size="sm"
            w="20rem"
            styles={{
              input: {
                color: formData.isDirty("gradeCode") ? "red !important" : "inherit",
                borderColor: formData.isDirty("gradeCode") ? "red !important" : "inherit",
              },
              error: { fontSize: 11, color: "red" },
            }}
            {...formData.getInputProps("gradeCode")}
          />
        </Flex>
      )}
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          Color
        </Text>
        <ItemCategoryAutoComplete
          placeholder="Color를 선택해 주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("itemCategoryName")}
          styles={{
            input: {
              color: formData.isDirty("itemCategoryName") ? "red !important" : "inherit",
              borderColor: formData.isDirty("itemCategoryName") ? "red !important" : "inherit",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          형태
        </Text>
        <AbstractItemAutoComplete
          placeholder="형태를 선택해 주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("abstractItemCode")}
          styles={{
            input: {
              color: formData.isDirty("abstractItemCode") ? "red !important" : "inherit",
              borderColor: formData.isDirty("abstractItemCode") ? "red !important" : "inherit",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          단위
        </Text>
        <Select
          data={["kg", "Bag", "EA"]}
          placeholder="단위를 선택해 주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("unitText")}
          styles={{
            input: {
              color: formData.isDirty("unitText") ? "red !important" : "inherit",
              borderColor: formData.isDirty("unitText") ? "red !important" : "inherit",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          재고 LOT 관리 기준
        </Text>
        <Flex justify="space-between" align="center" gap="xl">
          <Checkbox label="이름" {...formData.getInputProps("usesLotName")} />
          <Checkbox label="유효기한" {...formData.getInputProps("usesLotExpiration")} />
        </Flex>
      </Flex>
      <Flex justify="flex-end" gap="xl">
        <Button color="gray" onClick={closeModal}>
          취소
        </Button>
        <Button onClick={onSubmit}>품목 수정</Button>
      </Flex>
    </Flex>

    // <ModalConfirmBtnBox>
    //   <Button color="gray" onClick={closeModal}>
    //     취소
    //   </Button>
    //   <Button onClick={onSubmit}>품목 수정</Button>
    // </ModalConfirmBtnBox>
  );
};
