import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { customFunctions } from "@/config/customFunction";
import { BomByOperationCreateForm } from "@/features/bomSearch/table/form/BomByOperationCreateForm";
import { BomByOperationUpdateForm } from "@/features/bomSearch/table/form/BomByOperationUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Badge, Button, Table, Text, useMantineColorScheme } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  DefaultApiRoutingBomsRoutingBomIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ItemsDetailForm } from "../../ItemsDetailForm";
import { useTranslation } from "react-i18next";
import { GradeDetailForm } from "@/features/grade/components/form/GradeDetailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";

const TableContainer = styled(Table)``;

const Thead = styled.thead`
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Tr = styled.tr`
  display: flex;
  width: 100%;
  align-items: center;
  height: 45px;
`;

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  height: 100%;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const TextNumber = styled<any>(Text)`
  width: 100%;
`;

export const RoutingBomRow = (params: {
  routingCode?: string | undefined;
  routingBoms: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner[];
}) => {
  const { routingBoms, routingCode } = params;
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const queryClient = useQueryClient();

  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiRoutingBomsRoutingBomIdDeleteRequest) =>
      mutateRoutingBoms.delete(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["items"]);
        customAlert("삭제되었습니다.", "성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "Bom 삭제에 실패하였습니다.",
          "등록 실패",
          "red",
        );
      },
    },
  );

  const openEditModal = ({
    routingBom,
  }: {
    routingBom: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
  }) => {
    openModal(<BomByOperationUpdateForm data={routingBom} />, null, "BOM 수정");
  };

  const openCreateModal = ({ routingCode }: { routingCode: string | undefined }) => {
    openModal(<BomByOperationCreateForm routingCode={routingCode} />, null, "BOM 추가");
  };

  const handleDeleteBom = ({ routingBomId }: { routingBomId: number | undefined }) => {
    if (routingBomId === undefined) return;
    deleteMutate({
      routingBomId: routingBomId,
    });
  };

  return (
    <TableContainer>
      <Thead>
        <Tr>
          <Th width={10}>시퀀스</Th>
          <Th width={12}>품목군</Th>
          <Th width={12}>품목명</Th>
          <Th width={12}>Grade</Th>
          <Th width={12}>Color</Th>
          <Th width={12}>형태</Th>
          <Th width={5}>수량</Th>
          {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && <Th width={15}>비고</Th>}
          <Th width={15}>액션</Th>
        </Tr>
      </Thead>
      <Tbody>
        {routingBoms &&
          routingBoms.map(
            (
              routingBom: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
              i: number,
            ) =>
              routingBom.seq &&
              routingBom.itemCode && (
                <Tr key={i}>
                  <Td width={10}>
                    <TextNumber ta="right">{routingBom.seq}</TextNumber>
                  </Td>
                  <Td width={12}>
                    <Badge>{t(routingBom.itemWithGrade?.itemType as string)}</Badge>
                  </Td>
                  <Td width={12}>
                    <DetailLink
                      onClick={() =>
                        openModal(
                          <ItemsDetailForm itemCode={routingBom.itemCode as string} />,
                          null,
                          "품목 상세",
                        )
                      }
                    >
                      {routingBom?.item?.name ?? routingBom?.itemName}
                    </DetailLink>
                  </Td>
                  <Td width={12}>
                    <DetailLink
                      onClick={() =>
                        openModal(
                          <GradeDetailForm gradeCode={routingBom.itemWithGrade?.gradeCode} />,
                          null,
                          "Grade 상세",
                        )
                      }
                    >
                      {routingBom.itemWithGrade?.gradeName}
                    </DetailLink>
                  </Td>
                  <Td width={12}>
                    <DetailLink
                      onClick={() =>
                        openModal(
                          <ItemCategoryDetailForm
                            ItemCategoryName={routingBom.itemWithGrade?.itemCategoryName}
                          />,
                          null,
                          "Color 상세",
                        )
                      }
                    >
                      {routingBom.itemWithGrade?.itemCategoryName}
                    </DetailLink>
                  </Td>
                  <Td width={12}>
                    <DetailLink
                      onClick={() =>
                        openModal(
                          <ItemsDetailForm
                            itemCode={routingBom.itemWithGrade?.abstractItemCode as string}
                          />,
                          null,
                          "형태 상세",
                        )
                      }
                    >
                      {routingBom.itemWithGrade?.abstractItemCode}
                    </DetailLink>
                  </Td>
                  <Td width={5}>
                    <TextNumber ta="right">
                      {setToLocaleString(routingBom.quantityNumerator ?? "")}{" "}
                      {routingBom.item?.unit_text}
                    </TextNumber>
                  </Td>
                  {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && (
                    <Td width={15}>
                      <Text>{routingBom.description}</Text>
                    </Td>
                  )}
                  <Td width={15} style={{ padding: "2px 16px" }}>
                    <Button
                      w={32}
                      variant="subtle"
                      onClick={() => openEditModal({ routingBom })}
                      leftIcon={<IconEdit />}
                      color="yellow"
                    ></Button>
                    <Button
                      w={32}
                      variant="subtle"
                      onClick={() => handleDeleteBom({ routingBomId: routingBom.id })}
                      leftIcon={<IconTrash />}
                      color="red"
                    ></Button>
                  </Td>
                </Tr>
              ),
          )}
        <Tr>
          <Td style={{ width: "100%", justifyContent: "center" }}>
            <Button
              leftIcon={<IconPlus />}
              variant={"subtle"}
              color={isDarkMode ? "dark" : "white"}
              onClick={() => openCreateModal({ routingCode })}
            >
              새로운 BOM 추가
            </Button>
          </Td>
        </Tr>
      </Tbody>
    </TableContainer>
  );
};

const Link = styled.a`
  color: ${theme.colors?.blue?.[7]};
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
`;
