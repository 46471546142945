import useWorksMutation from "@/api/works/useWorksMutation";
import { useModal } from "@/features/modal/ModalStackManager";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Flex, Table, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentWorksInner,
  DefaultApiProductionPlansProductionPlanIdPutRequest,
  DefaultApiWorksWorkIdPutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksWorkIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import customAlert from "../ui/alert/alert";

type PageKeyType = "equipment" | "plan"; // pageKey의 가능한 값들을 정의합니다.

type ItemType<T extends PageKeyType> = T extends "equipment"
  ? AuthSignupPost201ResponseEquipmentWorksInner
  : ProductionPlansGet200ResponseRowsInnerWorksInner; // pageKey에 따른 item의 타입을 정의합니다.

interface WorkUpdateFormProps<T extends PageKeyType> {
  workRowData: ItemType<T>[];
}
export function WorkUpdateForm<T extends PageKeyType>(params: WorkUpdateFormProps<T>): JSX.Element {
  const workDataArray = params.workRowData;

  const { closeModal } = useModal();
  const { mutate: updateMutate } = useWorksMutation("update");
  const form = useForm<{
    works: Array<
      Partial<DefaultApiWorksWorkIdPutRequest & DefaultApiProductionPlansProductionPlanIdPutRequest>
    >;
  }>({
    initialValues: {
      works: workDataArray.map((workData) => ({
        workId: workData.id,
        worksWorkIdDeleteRequest: {
          routingCode: workData.routingCode,
          equipmentCode: (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)
            .equipmentCode,
          targetQuantity: workData.targetQuantity,
          scheduledAt: (workData as ProductionPlansGet200ResponseRowsInnerWorksInner).scheduledAt,
        },
        productionPlanId: workData.productionPlanId,
        productionPlansProductionPlanIdDeleteRequest: {},
      })),
    },
  });

  const onSave = () => {
    form.values.works.forEach((work, index) => {
      const { workId, worksWorkIdDeleteRequest, productionPlanId } = work;

      updateMutate(
        {
          workId: workId as number,
          worksWorkIdDeleteRequest: worksWorkIdDeleteRequest as WorksWorkIdDeleteRequest,
          productionPlanId: productionPlanId as number,
          productionPlansProductionPlanIdDeleteRequest: {
            scheduledAt: worksWorkIdDeleteRequest?.scheduledAt!,
            targetQuantity: worksWorkIdDeleteRequest?.targetQuantity!,
          },
        },
        {
          onSuccess: () => {
            if (index === form.values.works.length - 1) {
              customAlert("작업 지시 수정에 성공했습니다.", "작업 지시 수정 성공", "green");
              form.reset();
              closeModal(true);
            }
          },
          onError: (error) => {
            customAlert(
              handleErrorResponse({ error, errorContext: "작업 지시" }),
              "작업 지시 수정 실패",
              "red",
            );
            closeModal(false);
          },
        },
      );
    });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>일자</th>
            <th>라우팅</th>
            <th>설비</th>
            <th>목표수량</th>
          </tr>
        </thead>
        <tbody>
          {workDataArray.map((_, index) => {
            const inputProps = form.getInputProps(
              `works.${index}.worksWorkIdDeleteRequest.scheduledAt`,
            );
            inputProps.value = new Date(inputProps.value);

            return (
              <tr key={index}>
                <td>
                  <DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    valueFormat="YYYY-MM-DD"
                    {...inputProps}
                  />
                </td>
                <td>{form.values.works[index].worksWorkIdDeleteRequest?.routingCode}</td>
                <td>
                  <EquipmentAutoComplete
                    width="384px"
                    placeholder="설비 선택"
                    {...form.getInputProps(`works.${index}.worksWorkIdDeleteRequest.equipmentCode`)}
                  />
                </td>
                <td>
                  <TextInput
                    placeholder="목표수량"
                    type="number"
                    {...form.getInputProps(
                      `works.${index}.worksWorkIdDeleteRequest.targetQuantity`,
                    )}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Flex justify="flex-end">
        <Button onClick={onSave}>일괄 수정</Button>
      </Flex>
    </>
  );
}
