export const operatorMap: {
  key?: string;
  value: string;
  label: string;
  category: string;
}[] = [
  { key: "text_eq", value: "$eq", label: "같음", category: "text" },
  { value: "$ne", label: "다름", category: "text" },
  { value: "$iLike", label: "포함", category: "text" },
  { value: "$notILike", label: "미포함", category: "text" },
  { key: "number_eq", value: "$eq", label: "=", category: "number" },
  { key: "number_gte", value: "$gte", label: ">=", category: "number" },
  { value: "$gt", label: ">", category: "number" },
  { key: "number_ne", value: "$ne", label: "<>", category: "number" },
  { key: "number_lte", value: "$lte", label: "<=", category: "number" },
  { value: "$lt", label: "<", category: "number" },
  { key: "date_lt", value: "$lt", label: "이전", category: "date" },
  { key: "date_gt", value: "$gt", label: "이후", category: "date" },
  // { value: "$in", label: "", category: "text" }
  { value: "$in", label: "", category: "multi" },
];
