import { moldMaintenancesHeader } from "@/constants/columnHeader";
import { columnlist } from "@/constants/columnlist";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { GridTable } from "@/features/ui/PMS/GridTable";
import useQueryString from "@/hooks/useQueryString";
import styled from "@emotion/styled";
import { Flex, Pagination, Text } from "@mantine/core";
import { useState } from "react";
import { SelectColumn } from "react-data-grid";

const rows = [
  {
    code: "코일 금형 테스트",
    name: "코일 금형1",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 10000,
    inspectionBats: 10000,
    currentBats: 508,
    dutyCycle: 5.08,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "다리틀 금형2",
    name: "다리틀 금형2",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 10000,
    inspectionBats: 10000,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "다리틀",
    name: "다리틀",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 10000,
    inspectionBats: 10000,
    currentBats: 1580,
    dutyCycle: 15.8,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "DOUBLE",
    name: "더블",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 10000,
    inspectionBats: 1000,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "MOLD_P02_3_J",
    name: "YJ-P09(3)",
    cavity: 0,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 1300000,
    inspectionBats: 120000,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "MOLD_P02_2_J",
    name: "YJ-P09(2)",
    cavity: 0,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 1200000,
    inspectionBats: 120000,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "MOLD_P02_1_J",
    name: "YJ-P09(1)",
    cavity: 0,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 1100000,
    inspectionBats: 120000,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "excel_up",
    name: "금형명",
    cavity: 1,
    spm: 2,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "44552",
    name: "222",
    cavity: 222,
    spm: 2,
    slideLocation: 22.0,
    maxBats: 2,
    inspectionBats: 2,
    currentBats: 2,
    dutyCycle: 100.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "zerotest2",
    name: "-",
    cavity: 0,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "zerotest",
    name: "-",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "what?",
    name: "nono",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 0,
    dutyCycle: 0.0,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "타수입력",
    name: "금형-04",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 320,
    dutyCycle: 3.2,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "ccd",
    name: "-",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 2147483647,
    dutyCycle: 21474836.47,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "excel-code",
    name: "금형명",
    cavity: 2,
    spm: 2,
    slideLocation: 3.0,
    maxBats: 4,
    inspectionBats: 5,
    currentBats: 4575,
    dutyCycle: 91.5,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "mold-03",
    name: "금형-03",
    cavity: 3,
    spm: 4,
    slideLocation: 5.0,
    maxBats: 65,
    inspectionBats: 7,
    currentBats: 84,
    dutyCycle: 1.29,
    repairRegisterText: "금형 수리 등록",
    inspectionAlert: "알림 해제",
  },
  {
    code: "mold-02",
    name: "금형-02",
    cavity: 1,
    spm: 1,
    slideLocation: 2.0,
    maxBats: 100,
    inspectionBats: 2,
    currentBats: 102648,
    dutyCycle: 1026.48,
    inspectionAlert: "알림 해제",
  },
  {
    code: "mold-01",
    name: "금형-01",
    cavity: 1,
    spm: 0,
    slideLocation: 0.0,
    maxBats: 0,
    inspectionBats: 0,
    currentBats: 26690,
    dutyCycle: 266.9,
    inspectionAlert: "알림 해제",
  },
];

export const MoldMaintenancePage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  return (
    <Flex gap="md" direction="column" w="100%" h="100%" align="center">
      <Flex gap="md" justify="space-between" w="100%">
        <Text size="xl">금형 타수관리</Text>
        <SearchBox searchType={moldMaintenancesHeader} setSearch={setSearch} search={search} />
      </Flex>
      <GridTable
        headerList={[SelectColumn, ...columnlist.moldMaintenance]}
        row={rows ?? []}
        setRow={() => {}}
      />
      <Pagination onChange={() => {}} value={1} total={3} size="lg" radius="sm" />
    </Flex>
  );
};

const GridWrapper = styled(Flex)`
  & *[role="grid"] {
    height: 440px;
  }

  & *[role="columnheader"] {
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    box-shadow: none;
    display: flex;
    align-items: center;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
  }
`;
