import { documentFileHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const DocumentFileWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const DocumentFilePage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = documentFileHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const pageSize = 10;
  const totalRows = DocumentFilePageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = DocumentFilePageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "fileName",
      name: "파일명",
      sortable: true,
      resizable: true,
    },
    {
      key: "fileType",
      name: "파일형식",
      sortable: true,
      resizable: true,
    },
    {
      key: "fileSize",
      name: "파일크기",
      sortable: true,
      resizable: true,
    },
  ];

  const deleteSelectedItems = () => {
    window.confirm("정말로 삭제하시겠습니까?") &&
      customAlert("선택된 행 삭제에 실패하였습니다.", "삭제 실패", "red");
  };

  return (
    <DocumentFileWrap.Wrapper>
      <DocumentFileWrap.ActionHeader>
        <DocumentFileWrap.ActionButton leftIcon={<IconPlus />}>
          파일 추가
        </DocumentFileWrap.ActionButton>
        <DocumentFileWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => deleteSelectedItems()}
        >
          선택된 행 삭제
        </DocumentFileWrap.ActionButton>
      </DocumentFileWrap.ActionHeader>
      <DocumentFileWrap.Header>
        <DocumentFileWrap.HeaderTitle>문서 관리</DocumentFileWrap.HeaderTitle>
        <DocumentFileWrap.HeaderSubTitle>
          {"우리 회사에서 다루는 모든 문서를 관리합니다."}
        </DocumentFileWrap.HeaderSubTitle>
      </DocumentFileWrap.Header>

      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={documentFileHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={documentFileHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </DocumentFileWrap.Wrapper>
  );
};

const DocumentFilePageMockupData = [
  { fileName: "공장 프로세스 설명서", fileType: "pdf", fileSize: "3.86 MB" },
  { fileName: "데이터 세팅 방법", fileType: "pdf", fileSize: "1.93 MB" },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
