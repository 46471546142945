import { mutatePartners } from "@/api/partner/usePartnerQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerCreateForm } from "@/features/partner/form/createForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { MasterApiPartnersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import customAlert from "../../alert/alert";
import { PartnerOption, usePartnersAutoComplete, usePartnersCode } from "./auto-useGetPartnerQuery";

interface PartnerProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface PartnerAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

export const PartnerAutoComplete = (params: PartnerAutoCompleteProps) => {
  const { value: partnerCode, onChange, maxDropdownHeight, width, ...etcParams } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = usePartnersAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = usePartnersCode(!!partnerCode, partnerCode ?? null);

  let selectedRoute = initialOptions?.find((item) => {
    return item.value === partnerCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedRoute = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, PartnerProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{code}</Text>
            <Text fz="xs"> (name: {name === "null" ? "-" : name})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  return (
    <PartnerSelect
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedRoute?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedRoute?.value}
            </div>
          )}
        </div>
      )}
      value={partnerCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: PartnerProps[], option: PartnerProps) => {
          return unique.some((u) => u.value === option.value) ? unique : [...unique, option];
        },
        [],
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={PartnerInfo({
        route: selectedRoute as PartnerOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewPartner({
        partnerCode: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const PartnerInfo = (params: {
  route?: PartnerOption;
  onChange: (partnerCode: string | null) => void;
}) => {
  const { route, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return route?.value ? (
    <PartnerInfoLabel>
      {/* ({route.value})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </PartnerInfoLabel>
  ) : null;
};

const AddNewPartner = (params: { partnerCode: string; onChange: (itemCode: string) => void }) => {
  const { partnerCode, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiPartnersPostRequest) =>
      mutatePartners.create(params).mutationFn(params as MasterApiPartnersPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["partners"]);
        customAlert("협력사 생성에 성공하였습니다.", "생성 성공", "green");
        closeModal(res.data);
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "협력사" }),
          "협력사 생성 실패",
          "red",
        );
      },
    },
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate({
      partnersGetRequest: {
        code: values.code,
        name: values.name,
        type: values.type,
        contact: values.contact,
      },
    });
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <PartnerCreateForm code={partnerCode} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      "신규 협력사 등록",
      true,
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 협력사({partnerCode}) 등록
    </Button>
  );
};
const PartnerInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const PartnerSelect = styled(Select)<{ value: string | null | undefined }>`
  width: 100%;

  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? "0.8rem !important" : "")};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
