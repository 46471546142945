import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Space } from "@mantine/core";

const DetailGrade = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Content: DetailContent,
});

interface GradeDetailHeaderProps {
  data: any;
}

export const GradeDetailHeader = (params: GradeDetailHeaderProps) => {
  const { data } = params;
  const grade = data?.data?.rows[0];

  return (
    <DetailGrade>
      <DetailGrade.Title>{`[등급 상세]`}</DetailGrade.Title>
      <Space h={8} />
      <DetailGrade.Field>
        <DetailGrade.Label>{"등급코드"}</DetailGrade.Label>
        <DetailGrade.Content>{grade?.code}</DetailGrade.Content>
      </DetailGrade.Field>
      <DetailGrade.Field>
        <DetailGrade.Label>{"등급명"}</DetailGrade.Label>
        <DetailGrade.Content>{grade?.name}</DetailGrade.Content>
      </DetailGrade.Field>
      <DetailGrade.Field>
        <DetailGrade.Label>{"생성자 (생성일)"}</DetailGrade.Label>
        <DetailGrade.Content>
          {grade?.creatorUserCode} ({grade?.createdAt ? DatetimeUtil(grade?.createdAt) : ""})
        </DetailGrade.Content>
      </DetailGrade.Field>
    </DetailGrade>
  );
};
