import { useStandardLayout } from "@/features/standardLayout/Context";
import { Flex, Text } from "@mantine/core";
import { AxiosResponse } from "axios";
import DataGrid, { Column } from "react-data-grid";

// rows 내 각 항목의 타입에 맞는 컬럼 정의
interface ListTableProps<TRow> {
  columns: readonly Column<TRow>[];
  rowKeyGetter?: (row: TRow) => any;
  additionalDataFn?: (row: TRow[]) => TRow[];
}

// TResponse는 rows 속성을 포함해야 함을 명시
export const ListTable = <TResponse extends { rows?: TRow[] }, TRow>({
  columns,
  rowKeyGetter = (row) => (row as any).id ?? (row as any).code,
  additionalDataFn,
}: ListTableProps<TRow>) => {
  // provider에서 data를 받아와서 바인딩
  const { data, selectedRows, setSelectedRows } = useStandardLayout<AxiosResponse<TResponse>>();
  const response = data?.data || { rows: [] as TRow[] }; // rows가 기본적으로 빈 배열을 가지도록 설정
  let rows: TRow[] = response.rows ?? []; // rows가 undefined일 경우 빈 배열을 사용

  if (!!additionalDataFn) {
    rows = additionalDataFn?.(rows as TRow[]);
  }

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      rowHeight={40}
      rowKeyGetter={rowKeyGetter} // row의 id를 키로 사용, id가 없는 경우 code를 키로 사용
      selectedRows={selectedRows}
      onSelectedRowsChange={(e) => {
        setSelectedRows(e);
      }}
      components={{
        noRowsFallback: (
          <Flex justify="center" align="center" w="100%" bg="white" top={45} pos="absolute">
            <Text fz="lg">데이터가 없습니다.</Text>
          </Flex>
        ),
      }}
    />
  );
};
