import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { mutateRoutingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiRoutingOutsourcesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface RoutingOutsourceProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const RoutingOutsourceCreateForm = (params: RoutingOutsourceProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();

  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiRoutingOutsourcesPostRequest) =>
      mutateRoutingOutsources
        .create(params)
        .mutationFn(params as DefaultApiRoutingOutsourcesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["routingOutsources"]);
        closeModal(form.values);
        customAlert("외주처 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "외주처" }),
          "외주처 생성 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      name: name ?? undefined,
      routingCode: undefined,
      fromLocationCode: undefined,
      toLocationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        routingOutsourcesGetRequest: {
          name: name ?? form.values.name!,
          routingCode: form.values.routingCode!,
          fromLocationCode: form.values.fromLocationCode!,
          toLocationCode: form.values.toLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "외주처" }),
        "외주처 생성 실패",
        "red",
      );
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput label="외주처명" withAsterisk {...form.getInputProps("name")} />
          <RoutingAutoComplete
            label="라우팅코드"
            withAsterisk
            width={"100%"}
            {...form.getInputProps("routingCode")}
          />
          <LocationAutoComplete
            label="자재 출고 로케이션코드"
            withAsterisk
            width={"100%"}
            {...form.getInputProps("fromLocationCode")}
          />
          <LocationAutoComplete
            label="재공품 입고 로케이션코드"
            withAsterisk
            width={"100%"}
            {...form.getInputProps("toLocationCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routingOutsource",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              외주처 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
