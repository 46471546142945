import AnalysisReadyTimeTable from "@/features/PMS/analysis/AnalysisReadyTimeTable";
import {
  FrequentAlreadyHaveIsVisible,
  FrequentlyDateTypeAndDate,
} from "@/features/PMS/frequently/Frequently";
import FrequentlyAreaChart from "@/features/PMS/frequently/FrequentlyAreaChart";
import FrequentlyChartLegend from "@/features/PMS/frequently/FrequentlyChartLegned";
import FrequentlyFlexMiddle from "@/features/PMS/frequently/FrequentlyFlexMiddle";
import FrequentlyProgressBar from "@/features/PMS/frequently/FrequentlyProgressBar";
import FrequentlyRowItemList from "@/features/PMS/frequently/FrequentlyRowItemList";
import { PRESS_STATICS_READY_TIME, PRESS_V2_CHART_OPTIONS } from "@/features/PMS/pm_v2";
import styled from "@emotion/styled";
import { Flex, Text } from "@mantine/core";
import { ApexOptions } from "apexcharts";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";

declare module ApexCharts {
  export interface ApexOptions {
    annotations?: ApexAnnotations;
    chart?: ApexChart;
    colors?: any[];
    dataLabels?: ApexDataLabels;
    fill?: ApexFill;
    forecastDataPoints?: ApexForecastDataPoints;
    grid?: ApexGrid;
    labels?: string[];
    legend?: ApexLegend;
    markers?: ApexMarkers;
    noData?: ApexNoData;
    plotOptions?: ApexPlotOptions;
    responsive?: ApexResponsive[];
    series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
    states?: ApexStates;
    stroke?: ApexStroke;
    subtitle?: ApexTitleSubtitle;
    theme?: ApexTheme;
    title?: ApexTitleSubtitle;
    tooltip?: ApexTooltip;
    xaxis?: ApexXAxis;
    yaxis?: ApexYAxis | ApexYAxis[];
  }
}

interface StaticReadyTimeSelectedPressInformation {
  pressIndex: number | null;
  dataIndex: number | null;
}

export const AnalysisIdleTimePage = () => {
  const [pressList, setPressList] = useState<FrequentAlreadyHaveIsVisible[]>([]);

  const INITIAL_DATE = new Date();
  INITIAL_DATE.setDate(INITIAL_DATE.getDate() - 1);

  const [date, setDate] = useState<FrequentlyDateTypeAndDate>({
    type: "day",
    date: `${dayjs(INITIAL_DATE).format("YYYY-MM-DD")}`,
    unit: "day",
    tableDate: "",
  });
  const selectedPress = useMemo(
    () => pressList.filter((pressInformation) => pressInformation.isVisible),
    [pressList],
  );
  const [analysisDowntimeResponse, setAnalysisDowntimeResponse] = useState<
    PRESS_STATICS_READY_TIME[]
  >([]);
  const [selectedPressInformation, setSelectedPressInformation] =
    useState<StaticReadyTimeSelectedPressInformation>({
      pressIndex: null,
      dataIndex: null,
    });
  const [legendSeriesMemory, setLegendSeriesMemory] = useState<boolean>(false);

  const PRESS_SELECTED_COLOR = [
    "#F3E2A9",
    "#F9CDAC",
    "#F6CEE3",
    "#F3A8A1",
    "#ED8495",
    "#E8608A",
    "#CD4A89",
    "#A73B8E",
    "#822C94",
    "#63218F",
    "#521B75",
    "#40155B",
    "#330033",
    "#330066",
    "#000066",
    "#000033",
  ];
  const PRESS_RUNNING_TIME_COLOR = [
    "#DCECC9",
    "#99FF99",
    "#AFDCCC",
    "#FFFF99",
    "#CCCCFF",
    "#9999FF",
    "#819FF7",
    "#56B9D2",
    "#642EFE",
    "#44A1C7",
    "#3984B6",
    "#3067A5",
    "#274B93",
    "#1E3082",
    "#3A01DF",
    "#B45F04",
  ];

  const INITIAL_CHART_DATA: ApexAxisChartSeries = [
    {
      name: "가동률",
      type: "line",
      data: [],
    },
    {
      name: "생산량",
      type: "column",
      data: [],
    },
  ];

  const INITIAL_CHART_Y_AXIOS_OPTION = [
    {
      tickAmount: 11,
      labels: {
        formatter: (value: any, index: number) => {
          if (index === 11) {
            return "가동률";
          } else {
            return String(index * 10);
          }
        },
      },
    },
    {
      opposite: true,
      tickAmount: 11,
      labels: {
        formatter: (value: any, index: number) => {
          if (index === 11) {
            return "생산량";
          } else {
            return Math.round(value).toString();
          }
        },
      },
    },
  ];

  const INITIAL_CHART_OPTION: ApexOptions = {
    chart: {
      id: "statistics/readytime/chart",
      type: "line",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#19B9DF", "#FD6B00"],
    stroke: {
      width: [2.5, 0.1],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 2,
      colors: ["#19B9DF"],
      strokeColors: "#19B9DF",
      strokeWidth: 2,
      // hover: {
      //   size: 7,
      // }
    },
    labels: [],
    yaxis: INITIAL_CHART_Y_AXIOS_OPTION,
  };
  const [chartOptions, setChartOptions] = useState<PRESS_V2_CHART_OPTIONS>({
    data: INITIAL_CHART_DATA,
    options: {
      ...INITIAL_CHART_OPTION,
      chart: INITIAL_CHART_OPTION.chart,
    },
  });

  const PROGRESS_STYLE = {
    width: "100%",
    height: 56,
  };

  const onLegendToggle = (value: string, isVisible?: any) => {
    const isVisibleType = typeof isVisible === "boolean";

    let target = isVisibleType ? (isVisible ? "showSeries" : "hideSeries") : "resetSeries";

    ApexCharts.exec("statistics/readytime/chart", target, value);
  };

  const drawLegendView = (type: "odd" | "even") => {
    return selectedPress.map((pressInformation, index) => {
      let colorIndex = pressList.findIndex((element) => element.name === pressInformation.name);
      colorIndex = colorIndex !== -1 ? colorIndex : 0;

      const isEven = type === "even";
      const isVisible = !(!isEven && index >= 5);

      return (
        <FrequentlyFlexMiddle key={pressInformation.pk}>
          <FrequentlyChartLegend
            value={isEven ? `${pressInformation.name} 가동률` : `${pressInformation.name} 생산량`}
            type={{
              type: isEven ? "lineInCircle" : "box",
              color: isEven
                ? PRESS_RUNNING_TIME_COLOR[colorIndex]
                : PRESS_SELECTED_COLOR[colorIndex],
            }}
            compulsion={!isEven && legendSeriesMemory ? false : undefined}
            isVisible={isVisible}
            onClick={onLegendToggle}
            selectedPress={selectedPress}
          />
        </FrequentlyFlexMiddle>
      );
    });
  };

  const sendDataInTable = useMemo(() => {
    if (selectedPressInformation.pressIndex !== null) {
      const filter = pressList[selectedPressInformation.pressIndex];

      if (filter && filter.isVisible) {
        const target = analysisDowntimeResponse.filter(
          (response) => response.pk && response.pk === filter.pk,
        )[0];

        let UPH;
        if (target && target.information) {
          if (date.tableDate) {
            UPH = target.chart?.press_counts[date.tableDate];
          }
        }
        return (
          target &&
          target.information && {
            ...target.information,
            analysis_details: { ...target.information.analysis_details, UPH: UPH },
          }
        );
      }
    }
  }, [selectedPressInformation, pressList, date, analysisDowntimeResponse]);

  const setPercentageFilter = useCallback(
    (type: "running_off_idle" | "idle") => {
      let runTime = 0;
      let powerOff = 0;
      let idleTime = 0;

      let waitTime = 0;
      let qdcTime = 0;
      let errorTime = 0;

      if (selectedPressInformation.pressIndex !== null) {
        const filter = pressList[selectedPressInformation.pressIndex];
        if (filter && filter.isVisible) {
          const target = analysisDowntimeResponse.filter(
            (response) => response.pk === filter.pk,
          )[0];

          if (target && target.information) {
            const { running_time_pc, idle_time_pc, off_time_pc, idle_time_details } =
              target.information.analysis_details;

            runTime = running_time_pc;
            powerOff = off_time_pc;
            idleTime = idle_time_pc;

            waitTime = idle_time_details.idle_time_pc;
            qdcTime = idle_time_details.qdc_time_pc;
            errorTime = idle_time_details.error_time_pc;
          }
        }
      }

      if (type === "running_off_idle") {
        return [
          {
            value: runTime,
            color: "#169EBA",
            name: "가동시간",
          },
          {
            value: idleTime,
            color: "#7D30CB",
            name: "비 가동 시간",
          },
        ];
      } else {
        return true
          ? [
              {
                value: waitTime,
                color: "#F8B42A",
                name: "대기 시간",
              },
              {
                value: errorTime,
                color: "#FA163F",
                name: "에러",
              },
            ]
          : [
              {
                value: waitTime,
                color: "#F8B42A",
                name: "대기 시간",
              },
              {
                value: qdcTime,
                color: "#2C786C",
                name: "금형교체시간",
              },
              {
                value: errorTime,
                color: "#FA163F",
                name: "에러",
              },
            ];
      }
    },
    [pressList, analysisDowntimeResponse, selectedPressInformation],
  );

  const selectMachine = useCallback(
    (machine: FrequentAlreadyHaveIsVisible) => {
      let filter;

      if (machine.pk === "all") {
        if (!!machine.isVisible) {
          setAnalysisDowntimeResponse([]);
        }

        filter = pressList.map((pressInformation) => {
          return {
            ...pressInformation,
            isVisible: !machine.isVisible,
          };
        });
      } else {
        const find = pressList.find(
          (pressInformation) => pressInformation.pk === machine.pk && pressInformation.isVisible,
        );

        if (!find) {
          filter = pressList.map((pressInformation) => {
            return {
              ...pressInformation,
              isVisible: pressInformation.pk === machine.pk ? true : pressInformation.isVisible,
            };
          });
        } else {
          filter = pressList.map((pressInformation) => {
            return {
              ...pressInformation,
              isVisible:
                pressInformation.pk === machine.pk
                  ? !pressInformation.isVisible
                  : pressInformation.isVisible,
            };
          });
        }
      }

      setPressList(filter);
    },
    [pressList],
  );

  return (
    <Flex gap={"md"} direction={"column"}>
      <Text size="xl">비 가동 시간</Text>
      <ItemContainer
        style={{
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          marginTop: 0,
        }}
      >
        <FrequentlyRowItemList list={pressList} onChange={selectMachine} selected={selectedPress} />
      </ItemContainer>

      <ChartContainer>
        <FrequentlyAreaChart
          type={"line"}
          options={chartOptions.options}
          series={chartOptions.data}
          height={368}
        />
        <FrequentlyFlexMiddle styles={{ paddingBottom: 8, flexWrap: "wrap" }}>
          {drawLegendView("odd")}
        </FrequentlyFlexMiddle>
        <FrequentlyFlexMiddle styles={{ paddingBottom: 24, flexWrap: "wrap" }}>
          {drawLegendView("even")}
        </FrequentlyFlexMiddle>
      </ChartContainer>
      <ItemContainer>
        <AnalysisReadyTimeTable cnc={true} data={sendDataInTable} date={date.tableDate} />
      </ItemContainer>
      <ItemContainer style={{ backgroundColor: "transparent" }}>
        <FrequentlyProgressBar
          title={"가동시간, 비 가동 시간 비율"}
          maximum={100}
          value={setPercentageFilter("running_off_idle")}
          containerStyle={{ width: "50%", marginRight: 16 }}
          progressContainerStyle={PROGRESS_STYLE}
        />
        <FrequentlyProgressBar
          title={"비 가동 시간 원인별 비율"}
          maximum={100}
          value={setPercentageFilter("idle")}
          containerStyle={{ width: "50%" }}
          progressContainerStyle={PROGRESS_STYLE}
        />
      </ItemContainer>
      {/* {
                excelModalIsVisible &&
                <AnalysisReadyTimeExcelModalContainer isVisible={excelModalIsVisible} pressList={pressList}
                    onClose={() => excelModalVisibleHandler(false)} />
            } */}
    </Flex>
  );
};

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ChartContainer = styled.div``;
