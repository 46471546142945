import { moldStocks } from "@/api/moldStock/useMoldStockQuery";
import useWorksGetQuery from "@/api/works/useWorksGet";
import { BadgeBox } from "@/features/standard/badge/BadgeBox";
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer";
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon";
import { BadgeMain } from "@/features/standard/badge/BadgeMain";
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity";
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox";
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox";
import { theme } from "@/styles/theme";
import { Text } from "@mantine/core";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { DetailMoldInformProps } from "./moldInForm";

const Badge = Object.assign(BadgeMain, {
  Box: BadgeBox,
  SmallBox: BadgeSmallBox,
  Quantity: BadgeQuantity,
  Icon: BadgeIcon,
  Text: Text,
  TextBox: BadgeTextBox,
  Container: BadgeContainer,
});

export const MoldDataForm = ({ data }: DetailMoldInformProps) => {
  const { data: moldStock } = useQuery(
    moldStocks.get({
      query: data ? { $and: [{ moldCode: { $eq: data.code } }] } : "",
    }),
  );

  const { data: workData } = useWorksGetQuery({
    query: data ? { $and: [{ moldCode: { $eq: data.code } }] } : "",
  });

  return (
    <Badge.Container>
      <Badge.Box>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme?.colors?.violet?.[6]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color="violet">
              {moldStock?.data?.total}개
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              금형 재고 갯수
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
        <Badge.SmallBox>
          <Badge.Icon>
            <IconBuildingWarehouse size={26} color={theme?.colors?.violet?.[6]} />
          </Badge.Icon>
          <Badge.TextBox>
            <Badge.Quantity fz="lg" color="violet">
              {workData?.data?.total}개
            </Badge.Quantity>
            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
              작업 지시
            </Badge.Text>
          </Badge.TextBox>
        </Badge.SmallBox>
      </Badge.Box>
    </Badge.Container>
  );
};
