import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { DowntimeReasonsMultiAutoComplete } from "@/features/ui/multiAutoComplete/downtimeReason/downtimeReason-multiAutoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiEquipmentsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface EquipmentCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const EquipmentCreateForm = (params: EquipmentCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: MasterApiEquipmentsPostRequest) =>
      mutateEquipment.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipment"]);
        closeModal(form.values);
        customAlert("설비 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "설비코드" }),
          "설비 생성 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      siteCode: "",
      code: "",
      name: name ?? "",
      equipmentType: undefined,
      volt: undefined,
      seq: undefined,
      fromLocationCode: undefined,
      toLocationCode: undefined,
      targetLocationCode: undefined,
      downtimeReasonCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        equipmentsGetRequest: {
          siteCode: form?.values?.siteCode,
          code: form.values.code,
          name: name ?? form.values.name,
          equipmentType: form.values.equipmentType,
          volt: form.values.volt,
          seq: form.values.seq,
          fromLocationCode: form.values.fromLocationCode,
          toLocationCode: form.values.toLocationCode,
          targetLocationCode: form.values.targetLocationCode,
          downtimeReasonCode: form.values.downtimeReasonCode,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "설비코드" }),
        "설비 생성 실패",
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <SitesAutoComplete
            withAsterisk
            label="사업장코드"
            width={"100%"}
            {...form.getInputProps("siteCode")}
          />
          <TextInput withAsterisk label="설비코드" {...form.getInputProps("code")} />
          <TextInput withAsterisk label="설비명" {...form.getInputProps("name")} />
          <TextInput label="설비유형" {...form.getInputProps("equipmentType")} />
          <NumberInput label="설비전압" {...form.getInputProps("volt")} />
          <LocationAutoComplete label="원자재창고" {...form.getInputProps("fromLocationCode")} />

          <LocationAutoComplete label="입고창고" {...form.getInputProps("toLocationCode")} />
          <LocationAutoComplete
            label="품목 생산 창고"
            {...form.getInputProps("targetLocationCode")}
          />
          <TextInput type="number" label="시퀀스(순서)" {...form.getInputProps("seq")} />
          <DowntimeReasonsMultiAutoComplete
            label="비가동사유"
            {...form.getInputProps("downtimeReasonCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "equipment",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              설비 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
