import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { counterStatusColor } from "@/utils/counterStatus";
import { setDateFormatString, setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Badge, Flex, Space } from "@mantine/core";
import {
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { GradeDetailForm } from "../grade/components/form/GradeDetailForm";
import { ItemCategoryDetailForm } from "../itemCategory/form/DetailForm";
import { AbstractItemDetailForm } from "../abstractItem/form/detailForm";

const DetailWork = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface WorkHeaderProps {
  workHeaderData?: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const WorkHeader = ({ workHeaderData }: WorkHeaderProps) => {
  const { openModal } = useModal();

  const { t } = useTranslation();

  if (!workHeaderData) return null;

  const workName = workHeaderData?.item?.name;
  const id = workHeaderData?.id;
  const itemCode = workHeaderData?.item?.code;
  const productionPlan = workHeaderData?.productionPlanId;
  const scheduledAt = workHeaderData?.scheduledAt;
  const targetQuantity = workHeaderData?.targetQuantity;
  const itemUnit = workHeaderData?.item?.unitText;
  const itemSpec = workHeaderData?.item?.spec;
  const workCounter =
    workHeaderData?.externalCounterSetting as ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum;
  const equipmentCode = workHeaderData?.equipmentCode;
  const equipmentName = workHeaderData?.equipmentName;
  const moldCode = workHeaderData?.moldCode;
  const createdAt = workHeaderData?.createdAt;
  const createUser = workHeaderData?.creatorUserCode;
  const updatedAt = workHeaderData?.updatedAt;
  const updateUser = workHeaderData?.updaterUserCode;
  const description = workHeaderData?.description;

  const itemWithGrade = workHeaderData?.itemWithGrade;

  // const purchaseOrderItem = workHeaderData?.purchaseOrderItem;

  return (
    <Container>
      <DetailWork.Title>{`[작업지시] ${workName}`}</DetailWork.Title>
      <Space h="xs" />
      <DetailWork.Field>
        <DetailWork.Label>작업지시</DetailWork.Label>
        <DetailWork.Content>{id}</DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>생산 품목</DetailWork.Label>
        <Flex gap="xs" align="center">
          <DetailWork.Badge>제품</DetailWork.Badge>
          <DetailWork.Link
            onClick={() => {
              openModal(<ItemsDetailForm itemCode={itemCode} />, null, "");
            }}
          >
            {workName}
          </DetailWork.Link>
        </Flex>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>생산 품목 Grade</DetailWork.Label>
        <DetailWork.Content>
          <LinkAnchor
            onClick={() => {
              openModal(<GradeDetailForm gradeCode={itemWithGrade?.gradeCode} />, null, "");
            }}
          >
            {itemWithGrade?.gradeName}
          </LinkAnchor>
        </DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>생산 품목 Color</DetailWork.Label>
        <DetailWork.Content>
          <LinkAnchor
            onClick={() => {
              openModal(
                <ItemCategoryDetailForm ItemCategoryName={itemWithGrade?.itemCategoryName} />,
                null,
                "",
              );
            }}
          >
            {itemWithGrade?.itemCategoryName}
          </LinkAnchor>
        </DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>생산 품목 형태</DetailWork.Label>
        <DetailWork.Content>
          <LinkAnchor
            onClick={() => {
              openModal(
                <AbstractItemDetailForm AbstractItemCode={itemWithGrade?.abstractItemCode} />,
                null,
                "",
              );
            }}
          >
            {itemWithGrade?.abstractItemCode}
          </LinkAnchor>
        </DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>생산 계획</DetailWork.Label>
        <Flex gap="xs" align="center">
          <DetailWork.Badge>생산계획</DetailWork.Badge>
          <DetailWork.Link
            onClick={() =>
              openModal(<ProductionPlanViewForm ProductionPlanId={productionPlan} />, null, "")
            }
          >
            {productionPlan}
          </DetailWork.Link>
        </Flex>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>작업 일시</DetailWork.Label>
        <DetailWork.Content>{setDateFormatString(scheduledAt)}</DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>목표 수량</DetailWork.Label>
        <DetailWork.Content>
          {setToLocaleString(targetQuantity)} {itemUnit}
        </DetailWork.Content>
      </DetailWork.Field>

      <DetailWork.Field>
        <DetailWork.Label>생산 품목 규격</DetailWork.Label>
        <DetailWork.Content>{itemSpec}</DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>카운터 연동 설정</DetailWork.Label>
        <DetailWork.Content>
          <Badge color={counterStatusColor[workCounter]}>{t(workCounter)}</Badge>
        </DetailWork.Content>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>할당 설비</DetailWork.Label>
        <DetailWork.LinkWrapper>
          <DetailWork.Badge>설비</DetailWork.Badge>
          <DetailWork.Link
            onClick={() =>
              openModal(<EquipmentDetailForm equipmentCode={equipmentCode} />, null, "")
            }
          >
            {equipmentName}
          </DetailWork.Link>
        </DetailWork.LinkWrapper>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>실 금형코드</DetailWork.Label>
        <DetailWork.LinkWrapper>
          <DetailWork.Badge>금형</DetailWork.Badge>
          <DetailWork.Link
            onClick={() => openModal(<MoldDetailForm moldCode={moldCode} />, null, "")}
          >
            {moldCode}
          </DetailWork.Link>
        </DetailWork.LinkWrapper>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>생성정보</DetailWork.Label>
        <DetailWork.Link
          onClick={() => openModal(<UsersDetailForm UserCode={createUser} />, null, "")}
        >
          {createUser} ({setDateFormatStringWithTime(createdAt)})
        </DetailWork.Link>
      </DetailWork.Field>
      <DetailWork.Field>
        <DetailWork.Label>수정정보</DetailWork.Label>
        <DetailWork.Link
          onClick={() => openModal(<UsersDetailForm UserCode={createUser} />, null, "")}
        >
          {updateUser ? updateUser + " " + `(${setDateFormatStringWithTime(updatedAt)})` : ""}
        </DetailWork.Link>
      </DetailWork.Field>
      {/* {
        purchaseOrderItem &&
        <DetailWork.Field>
          <DetailWork.Label>수주정보</DetailWork.Label>
          <DetailWork.Link onClick={() =>
            openModal(
              <PurchaseOrderDetailForm contractorName={purchaseOrderItem?.contractorName} />,
              null,
              ""
            )
          }>
            {`업체명: ${purchaseOrderItem?.contractorName ?? "-"}, 수량 : ${purchaseOrderItem?.quantityOrdered}, 접수일 : ${purchaseOrderItem?.orderDate ? dayjs(purchaseOrderItem?.orderDate).format("YYYY-MM-DD") : "-"}, 납기일 : ${purchaseOrderItem?.deliveryDeadline ? dayjs(purchaseOrderItem?.deliveryDeadline).format("YYYY-MM-DD") : "-"}`}
          </DetailWork.Link>
        </DetailWork.Field>
      } */}
      <DetailWork.Field>
        <DetailWork.Label>비고</DetailWork.Label>
        <DetailWork.Content>{description}</DetailWork.Content>
      </DetailWork.Field>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--gray-0, #f8f9fa);
`;
