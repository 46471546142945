import { useDragAndDropList } from "@/hooks/useDragAndDropList";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Box, Button, Flex } from "@mantine/core";
import { createPortal } from "react-dom";

export type DragAndDropItemDataType = {
  id: string;
  content: string;
};

type DragAndDropListProps = {
  data?: DragAndDropItemDataType[];
  onSubmit: (item: DragAndDropItemDataType[]) => void;
};

const DraggablePortal = ({ children }: { children: React.ReactNode }) => {
  const mount = document.getElementById("draggable-portal"); // 포털용 div가 있는지 확인
  return mount ? createPortal(children, mount) : null;
};

export const DragAndDropList = ({ data, onSubmit }: DragAndDropListProps) => {
  const { items, onDragEnd } = useDragAndDropList(data);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Flex direction="column" gap={10} {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item: any, index: number) => (
              <Draggable key={`board-${item.id}`} draggableId={`board-${item.id}`} index={index}>
                {(provided, snapshot) => {
                  const draggableElement = (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={(theme) => ({
                        backgroundColor: snapshot.isDragging
                          ? theme.colors.blue[2]
                          : theme.colorScheme === "dark"
                            ? theme.colors.dark[6]
                            : theme.colors.gray[2],
                        textAlign: "center",
                        padding: theme.spacing.xl,
                        borderRadius: theme.radius.md,
                        cursor: "pointer",
                        boxShadow: snapshot.isDragging ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                        transition: "background-color 0.2s ease, box-shadow 0.2s ease",
                        zIndex: snapshot.isDragging ? 1000 : "auto",
                      })}
                    >
                      {index + 1} : {item.content}
                    </Box>
                  );

                  return snapshot.isDragging ? (
                    <DraggablePortal>{draggableElement}</DraggablePortal>
                  ) : (
                    draggableElement
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
      <Flex mt={10} justify="flex-end">
        <Button onClick={() => onSubmit(items)}>변경</Button>
      </Flex>
    </DragDropContext>
  );
};

export default DragAndDropList;
