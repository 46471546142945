import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Anchor, Space } from "@mantine/core";
import { MoldStocksGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

const DetailMold = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface DetailMoldInformProps {
  data: MoldStocksGet200ResponseRowsInner | any;
}

export const MoldStockDetailInform = (params: DetailMoldInformProps) => {
  const { data } = params;

  const moldStock = data?.data?.rows[0];

  const { openModal } = useModal();
  return (
    <>
      <DetailMold>
        <DetailMold.Title>{`[금형 재고 상세]`}</DetailMold.Title>
        <Space h={8} />
        <DetailMold.Field>
          <DetailMold.Label>{"금형 재고 코드"}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.code}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{"금형"}</DetailMold.Label>
          <DetailLinkWrapper>
            <DetailMold.Badge>{"금형"}</DetailMold.Badge>
            <DetailMold.Link
              onClick={() => {
                openModal(<MoldDetailForm moldCode={moldStock?.moldCode} />, null, "");
              }}
            >
              {moldStock?.moldCode}
            </DetailMold.Link>
          </DetailLinkWrapper>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{"최대 타수"}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.limitCounter}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{"점검 타수"}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.inspectCounter}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{"현재 타수"}</DetailMold.Label>
          <DetailMold.Content>{moldStock?.currentCounter}</DetailMold.Content>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{"생성정보"}</DetailMold.Label>
          <DetailMold.Link>
            <Anchor
              onClick={() => {
                openModal(<UsersDetailForm UserCode={moldStock?.creatorUserCode} />, null, "");
              }}
            >
              {moldStock?.creatorUserCode}({DatetimeUtil(moldStock?.createdAt ?? "")})
            </Anchor>
          </DetailMold.Link>
        </DetailMold.Field>
        <DetailMold.Field>
          <DetailMold.Label>{"수정정보"}</DetailMold.Label>
          <DetailMold.Link>
            <Anchor
              onClick={() => {
                openModal(<UsersDetailForm UserCode={moldStock?.updaterUserCode} />, null, "");
              }}
            >
              {moldStock?.updaterUserCode}({DatetimeUtil(moldStock?.updatedAt ?? "")})
            </Anchor>
          </DetailMold.Link>
        </DetailMold.Field>
      </DetailMold>
    </>
  );
};
