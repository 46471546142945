import { ActionIcon, Badge, Flex, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo } from "react";

import { moldStocks, mutateMoldStocks } from "@/api/moldStock/useMoldStockQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldCodeProps } from "@/features/mold/cavity/form/cavityForm";
import { MoldStockUpdateForm } from "@/features/moldStock/form/stockUpdateForm";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import {
  MasterApiMoldStocksMoldStockIdDeleteRequest,
  MoldStocksGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface Row {
  id: number | any;
  code: string;
  moldCode: string;
  limitCounter: number;
  inspectCounter: number;
  currentCounter: number;
}

export const InMoldStockTable = ({ moldCodeProps }: MoldCodeProps) => {
  const { data: moldStock } = useQuery(
    moldStocks.get({
      query: moldCodeProps ? { $and: [{ moldCode: { $eq: moldCodeProps } }] } : "",
      sort: "-id",
    }),
  );

  const { openModal } = useModal();

  const rows: MoldStocksGet200ResponseRowsInner[] = moldStock?.data.rows ?? [];

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorKey: "code",
        header: "금형 재고 코드",
        size: 80,
      },
      {
        accessorKey: "moldCode",
        header: "금형코드",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.moldCode && (
                <Badge size="sm" w={40}>
                  금형
                </Badge>
              )}
              <Text size="sm">{rows.row._valuesCache.moldCode}</Text>
            </Flex>
          );
        },
      },
      {
        accessorKey: "limitCounter",
        header: "한계 타수",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return <div style={{ textAlign: "right" }}>{rows.row._valuesCache.limitCounter}</div>;
        },
      },
      {
        accessorKey: "inspectCounter",
        header: "점검 타수",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return <div style={{ textAlign: "right" }}>{rows.row._valuesCache.inspectCounter}</div>;
        },
      },
      {
        accessorKey: "currentCounter",
        header: "현재 타수",
        size: 120,
        mantineEditTextInputProps: {
          type: "number",
          required: true,
        },
        Cell: (rows) => {
          return <div style={{ textAlign: "right" }}>{rows.row._valuesCache.currentCounter}</div>;
        },
      },
    ],
    [],
  );

  const deleteUser = useDeleteUser();

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<Row>) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteUser(row.original);
    }
  };

  const table = useMantineReactTable({
    columns,
    data: rows as Row[],
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
    enablePagination: false,
    positionActionsColumn: "last",

    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="수정">
          <ActionIcon
            onClick={() =>
              openModal(
                <MoldStockUpdateForm formatterProps={row.original} />,
                null,
                "금형 재고 수정",
                true,
              )
            }
            color="yellow"
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="삭제">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};

function useDeleteUser() {
  const queryClient = useQueryClient();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiMoldStocksMoldStockIdDeleteRequest) =>
      mutateMoldStocks
        .delete(params)
        .mutationFn(params as MasterApiMoldStocksMoldStockIdDeleteRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldStock"]);
        customAlert("금형재고 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "금형재고코드" }),
          "금형재고 삭제 실패",
          "red",
        );
      },
    },
  );

  return async (values: Row) => {
    await deleteMutate({
      moldStockId: values.id,
    });
  };
}
