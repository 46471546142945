import { useQuery } from "@tanstack/react-query";

import { MasterInstance } from "@/instance/axios";
import {
  AuthSignupPost201Response,
  UsersGet200Response,
} from "@sizlcorp/sizl-api-document/dist/models";

export type UserOption = {
  value: string;
  label: string;
};

export const useUsersMultiAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: UserOption[]) => void;
  },
) => {
  return useQuery(
    ["users-get-multi-autocomplete-keyword", keyword],
    () =>
      MasterInstance.usersFindPost({
        search: keyword,
        searchFields: ["name"],
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: AuthSignupPost201Response[]): UserOption[] =>
        data.map((User) => {
          return {
            value: String(User.name),
            label: String(User.name),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    },
  );
};

const getUserByName = (name: string | null) =>
  MasterInstance.usersGet({
    ...(name && { search: name }),
    searchFields: ["name"],
  }).then((res) => res.data);

export const useUsersMultiCode = (
  enabled: boolean = false,
  name: string | null,
  options?: {
    onSuccess: (data?: UserOption[]) => void;
  },
) => {
  return useQuery(["users-multi-get-name", name], () => getUserByName(name), {
    enabled,
    select: (data: UsersGet200Response) =>
      data?.rows?.map((User) => {
        return {
          value: User.name as string,
          label: User.name as string,
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
