import { inventories, mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { users } from "@/api/users/useUsersQuery";
import { BulkAllFormRow } from "@/features/inventory/components/Form/BulkAllFormRow";
import { useModal } from "@/features/modal/ModalStackManager";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Input, Table } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultApiWmsBatchPostRequest,
  WmsBatchPostRequest,
  WmsBatchPostRequestItemsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export interface BulkFormItemProps {
  formData: {
    lotId: number;
    lotName?: string;
    itemCode?: string;
    itemName?: string;
    spec?: string;
    quantity?: string;
    locationCode?: string;
  };
  seq: number;
  onChange: (index: number, quantity: string) => void;
  onDelete: (index: number) => void;
}

interface BulkFormProps extends WmsBatchPostRequest {
  items: {
    lotId: number;
    lotName?: string;
    itemCode?: string;
    itemName?: string;
    spec?: string;
    quantity?: string;
    locationCode?: string;
  }[];
}

export const TransferAllForm = () => {
  // 직접 해당 컴포넌트에서 조회하는 방식으로 변경
  const { data: userData } = useQuery(users.who({}));
  // const { userData } = props;

  const userLocationCode = userData?.data.locationCode;

  const form = useForm<Partial<BulkFormProps>>({
    initialValues: {
      fromLocationCode: userLocationCode ?? undefined,
      toLocationCode: undefined,
      items: [
        // {
        //     lotId: 0,
        //     lotName: undefined,
        //     itemCode: undefined,
        //     itemName: undefined,
        //     spec: undefined,
        //     quantity: undefined,
        //     locationCode: undefined
        // }
      ],
      additional: {},
    },
  });

  const queryClient = useQueryClient();
  const { mutate: transferAllMutate } = useMutation(
    (params: DefaultApiWmsBatchPostRequest) =>
      mutateInventories
        .transferAll(params)
        .mutationFn(params as DefaultApiWmsBatchPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert("벌크 이동에 성공하였습니다.", "벌크 이동 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "벌크 이동" }),
          "벌크 이동 실패",
          "red",
        );
      },
    },
  );

  const [lotId, setLotId] = useState<string>("");

  let tempLotId = "";

  // const barcodeRef = useRef<HTMLInputElement>();
  const { closeModal } = useModal();

  const onSubmit = () => {
    transferAllMutate({
      wmsBatchPostRequest: {
        fromLocationCode: form.values.fromLocationCode as string,
        toLocationCode: form.values.toLocationCode as string,
        items: form.values.items?.map((item) => ({
          lotId: Number(item.lotId),
          quantity: item.quantity,
        })) as WmsBatchPostRequestItemsInner[],
        additional: { ...form.values.additional },
        actionFrom: "WEB",
      },
    });
  };

  useQuery({
    ...inventories.get({
      query:
        lotId && form.values.fromLocationCode
          ? {
              $and: [
                {
                  lotId: { $eq: lotId },
                },
                {
                  locationCode: { $eq: form.values.fromLocationCode },
                },
                {
                  quantity: { $gt: 0 },
                },
              ],
            }
          : {},
    }),
    enabled: !!lotId,
    onSuccess: (response) => {
      const lotData = response.data.rows?.[0];
      tempLotId = "";
      setLotId("");
      if (!lotData) {
        alert("해당 LOT 정보가 없습니다.");
        return;
      }
      const { lot, itemCode, itemName, spec, quantity } = lotData;
      form.insertListItem("items", {
        lotId,
        lotName: lot?.name,
        itemCode,
        itemName,
        spec,
        quantity,
      });
    },
  });

  const handleRowChange = (index: number, quantity: string) => {
    form.setFieldValue(`items.${index}.quantity`, quantity);
  };

  const handleRowDelete = (index: number) => {
    form.removeListItem("items", index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setLotId(tempLotId);
    } else {
      tempLotId += e.key;
    }
  };

  return (
    <TransferAllFormContainer>
      <TitleHeader>
        <HeaderTitle>바코드 LOT 입력</HeaderTitle>
        <HeaderSubTitle>
          바코드를 스캔하여 자동으로 데이터를 입력합니다. <br /> 벌크 이동 버튼을 클릭하여 이동을
          완료합니다.
        </HeaderSubTitle>
      </TitleHeader>
      <BarcodeInputContainer>
        <Input.Wrapper label="바코드 입력 필드">
          <Input placeholder="바코드를 입력하세요" onKeyDown={handleKeyDown} value={tempLotId} />
        </Input.Wrapper>
        {userLocationCode !== null ? (
          <LocationContainer>
            <LocationAutoComplete
              dropdownPosition="bottom"
              label="출고 로케이션"
              defaultValue={userLocationCode}
              {...form.getInputProps("fromLocationCode")}
            />
            <LocationAutoComplete
              data-autofocus
              dropdownPosition="bottom"
              label="입고 로케이션"
              {...form.getInputProps("toLocationCode")}
            />
          </LocationContainer>
        ) : (
          <LocationContainer>
            <LocationAutoComplete
              data-autofocus
              dropdownPosition="bottom"
              label="출고 로케이션"
              {...form.getInputProps("fromLocationCode")}
            />
            <LocationAutoComplete
              dropdownPosition="bottom"
              label="입고 로케이션"
              {...form.getInputProps("toLocationCode")}
            />
          </LocationContainer>
        )}
      </BarcodeInputContainer>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th width={7}>시퀀스</Th>
              <Th width={14}>LOT ID</Th>
              <Th width={14}>로트명</Th>
              <Th width={14}>품목코드</Th>
              <Th width={14}>품목명</Th>
              <Th width={14}>수량</Th>
              <Th width={7}>&nbsp;</Th>
            </tr>
          </thead>
          <tbody>
            {form.values.items?.map((formData, index) => (
              <BulkAllFormRow
                formData={formData}
                seq={index}
                key={formData.lotId}
                onChange={handleRowChange}
                onDelete={handleRowDelete}
              />
            ))}
            {form.values.items && form.values.items.length === 0 && (
              <tr>
                <Td colSpan={8} width={100} style={{ textAlign: "center" }}>
                  바코드를 스캔하여 LOT를 등록하세요.
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <ButtonBox>
        <Button color="gray" onClick={closeModal}>
          취소
        </Button>
        <Button onClick={onSubmit}>벌크 이동</Button>
      </ButtonBox>
    </TransferAllFormContainer>
  );
};

const TransferAllFormContainer = styled.div`
  display: flex;
  width: 70rem;
  min-height: 20rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const BarcodeInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  padding: 8px;
  align-items: flex-end;
  gap: 10px;
  flex-shrink: 0;
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const ButtonBox = styled.div`
  display: flex;
  height: 44px;
  padding: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;

export const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;
