import { InspectSpecificationUpdateForm } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationUpdateForm";
import { RoutingBomRow } from "@/features/item/components/form/itemsStepperForm/routingsBomList/RoutingBomRow";
import { RoutingBomInform } from "@/features/routing/components/inform/RoutingBomInform";
import { RoutingInform } from "@/features/routing/components/inform/RoutingInform";
import { WorkHeader } from "@/features/work/WorkHeader";
import { WorkDataBar } from "@/features/work/WorkView/WorkDataBar";
import { WorkInputLot } from "@/features/work/WorkView/WorkInputLot";
import { WorkInspectRecord } from "@/features/work/WorkView/WorkInspectRecord";
import { WorkProductionHistory } from "@/features/work/WorkView/WorkProductionHistory";
import { WorkProductionInventory } from "@/features/work/WorkView/WorkProductionInventory";
import { WorkStatusHistory } from "@/features/work/WorkView/WorkStatusHistory";
import { WorkStatusTimeLine } from "@/features/work/WorkView/WorkStatusTimeLine";
import { WorkTab } from "@/features/work/WorkView/WorkTab";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export interface WorkContentProps {
  workData?: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const WorkContent = ({ workData }: WorkContentProps) => {
  const fromLocationCode = workData?.locationSetting?.fromLocation?.code;
  // workData?.routingOutsourceData?.from_location_code ??
  // workData?.routingData?.operation?.fromLocationCode;
  const toLocationCode = workData?.locationSetting?.toLocation?.code;
  // workData?.routingOutsourceData?.to_location_code ??
  // workData?.routingData?.operation?.toLocationCode;
  const defectLocationCode = workData?.locationSetting?.defectLocation?.code;
  const targetQuantity = workData?.targetQuantity;
  const summary = workData?.summary;
  const unitText = workData?.item?.unitText;

  const workDataBarData = {
    fromLocationCode,
    toLocationCode,
    defectLocationCode,
    targetQuantity,
    summary,
    unitText,
  };

  // 투입 로트 탭에 표시할 데이터를 위한 데이터
  const lstItemCode = workData?.routingData?.routingBoms?.map((bom) => bom.itemCode) ?? [];

  return (
    <>
      <WorkDataBar workDataBarData={workDataBarData} />
      <WorkHeader workHeaderData={workData} />
      <WorkTab>
        <Tabs.Panel value="routingInfo" pt="xs">
          <RoutingInform data={workData?.routingData} />
          <Tabs
            variant="outline"
            defaultValue="bom"
            styles={(theme) => ({
              tab: {
                "&:not([data-active])": {
                  backgroundColor: theme.colors?.gray?.[0],
                },
              },
            })}
          >
            <Tabs.List>
              <LeftSpace />
              <Tabs.Tab value="bom">BOM</Tabs.Tab>
              <Tabs.Tab value="inspectSpecification">검사표준</Tabs.Tab>
              <RightSpace />
            </Tabs.List>
            <Tabs.Panel value="bom">
              <RoutingBomInform />
              <RoutingBomRow
                routingBoms={
                  workData?.routingData
                    ?.routingBoms as AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner[]
                }
              />
            </Tabs.Panel>
            <Tabs.Panel value="inspectSpecification">
              <InspectSpecificationUpdateForm data={workData?.routingData} />
            </Tabs.Panel>
          </Tabs>
        </Tabs.Panel>
        {/* 투입 로트 탭 */}
        <Tabs.Panel value="inputLot" pt="xs">
          <WorkInputLot workId={workData?.id} />
        </Tabs.Panel>
        {/* 생산 가능 재고 탭 */}
        <Tabs.Panel value="productionInventory" pt="xs">
          <WorkProductionInventory lstItemCode={lstItemCode} locationCode={toLocationCode} />
        </Tabs.Panel>
        {/* 생산 기록 탭 */}
        <Tabs.Panel value="productionHistory" pt="xs">
          <WorkProductionHistory workId={workData?.id} />
        </Tabs.Panel>
        {/* 검사 기록 탭 */}
        <Tabs.Panel value="inspectRecord" pt="xs">
          <WorkInspectRecord productionPlanId={workData?.productionPlanId} />
        </Tabs.Panel>
        {/* 가동 기록 탭 */}
        <Tabs.Panel value="statusHistory" pt="xs">
          <WorkStatusHistory workId={workData?.id} />
        </Tabs.Panel>
        {/* 가동 시간 탭 */}
        <Tabs.Panel value="statusTimeLine" pt="xs">
          <WorkStatusTimeLine workId={workData?.id} />
        </Tabs.Panel>
        {/* <Tabs.Panel value="inventory" pt="xs">
            미투입 선차감 재고 현황
          </Tabs.Panel> */}
        {/* <WorkTabBody workData={workData} /> */}
      </WorkTab>
    </>
  );
};

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
