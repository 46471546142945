import { useModal } from "@/features/modal/ModalStackManager";
import { PurchaseOrderCreateForm } from "@/features/purchaseOrder/form/createForm";
import { DeliveryForm } from "@/features/purchaseOrder/form/deliveryForm";
import { PurchaseOrderItemsOutgingForm } from "@/features/purchaseOrder/form/purchaseOrderItemsOutgoingForm";
import { PurchaseOrderTable } from "@/features/purchaseOrder/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

const PurchaseOrder = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  PurchaseOrderTable: PurchaseOrderTable,
  PurchaseOrderCreateForm: PurchaseOrderCreateForm,
  DeliveryForm: DeliveryForm,
});

export const PurchaseOrderPage = () => {
  const publish = usePub();
  const { openModal } = useModal();
  const userCode = getUserCodeByUser();

  const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
  const [selectedRowsInform, setSelectedRowsInform] = useState<
    ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  >([]);
  const [data, setData] = useState<any>([]);

  const handleRowSelect = (selectedRows: any, formatterPropsData: any) => {
    setIsRowBtnActive(selectedRows.size === 1);
    setData(formatterPropsData);
    setSelectedRowsInform(selectedRowsInform);
  };

  return (
    <>
      <PurchaseOrder.Wrapper>
        <PurchaseOrder.ActionHeader>
          <PurchaseOrder.ActionButtonBox>
            <PurchaseOrder.ActionButton
              leftIcon={<IconPlus />}
              onClick={() =>
                openModal(
                  <PurchaseOrder.PurchaseOrderCreateForm />,
                  null,
                  `${userCode === "PB" ? "발주 추가" : "수주 추가"}`,
                )
              }
            >
              추가
            </PurchaseOrder.ActionButton>
            <PurchaseOrder.ActionButton
              leftIcon={<IconPlus />}
              color="orange"
              disabled={!isRowBtnActive}
              onClick={() =>
                openModal(<PurchaseOrderItemsOutgingForm formatterProps={data} />, null, "납품")
              }
            >
              납품하기
            </PurchaseOrder.ActionButton>
          </PurchaseOrder.ActionButtonBox>
          <PurchaseOrder.ActionButton
            color="red"
            rightIcon={<IconTrash />}
            onClick={() => publish("deleteSelectedItems")}
          >
            선택된 행 삭제
          </PurchaseOrder.ActionButton>
        </PurchaseOrder.ActionHeader>
        <PurchaseOrder.Header>
          <PurchaseOrder.HeaderTitle>
            {userCode === "PB" ? "발주" : "수주"}
          </PurchaseOrder.HeaderTitle>
          <PurchaseOrder.HeaderSubTitle>고객으로부터의 주문</PurchaseOrder.HeaderSubTitle>
        </PurchaseOrder.Header>
        <PurchaseOrder.PurchaseOrderTable onRowSelect={handleRowSelect} />
      </PurchaseOrder.Wrapper>
    </>
  );
};
