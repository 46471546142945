import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Checkbox, Space } from "@mantine/core";
import { AuthSignupPost201Response } from "@sizlcorp/sizl-api-document/dist/models";

const DetailUsers = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

export interface DetailUsersDetailHeaderProps {
  data: AuthSignupPost201Response | any;
}

export const UsersDetailHeader = (params: DetailUsersDetailHeaderProps) => {
  const { data } = params;

  const user = data?.data?.rows[0];

  const { openModal } = useModal();

  return (
    <>
      <DetailUsers>
        <DetailUsers.Title>{`[유저 상세]`}</DetailUsers.Title>
        <Space h={8} />
        <DetailUsers.Field>
          <DetailUsers.Label>{"유저 코드"}</DetailUsers.Label>
          <DetailUsers.Content>{user?.code}</DetailUsers.Content>
        </DetailUsers.Field>
        <DetailUsers.Field>
          <DetailUsers.Label>{"유저 명"}</DetailUsers.Label>
          <DetailUsers.Content>{user?.name}</DetailUsers.Content>
        </DetailUsers.Field>
        <DetailUsers.Field>
          <DetailUsers.Label>{"로그인 아이디"}</DetailUsers.Label>
          <DetailUsers.Content>{user?.username}</DetailUsers.Content>
        </DetailUsers.Field>
        <DetailUsers.Field>
          <DetailUsers.Label>{"설비코드"}</DetailUsers.Label>
          <DetailUsers.Link
            onClick={() =>
              openModal(<EquipmentDetailForm equipmentCode={user?.equipmentCode} />, null, "")
            }
          >
            {user?.equipmentCode}
          </DetailUsers.Link>
        </DetailUsers.Field>
        <DetailUsers.Field>
          <DetailUsers.Label>{"로케이션코드"}</DetailUsers.Label>
          <DetailUsers.Content>{user?.locationCode}</DetailUsers.Content>
        </DetailUsers.Field>
        <DetailUsers.Field>
          <DetailUsers.Label>{"프린터 코드"}</DetailUsers.Label>
          <DetailUsers.Content>{user?.printerCode}</DetailUsers.Content>
        </DetailUsers.Field>
        <DetailUsers.Field>
          <DetailUsers.Label>{"부서 코드"}</DetailUsers.Label>
          <DetailUsers.Content>{user?.departmentCode}</DetailUsers.Content>
        </DetailUsers.Field>
      </DetailUsers>
    </>
  );
};
