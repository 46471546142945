import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderDetailForm } from "@/features/order/form/detailForm";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { css } from "@emotion/css";
import { Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import {
  PurchaseOrdersGet200Response,
  PurchaseOrdersGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus } from "@tabler/icons-react";
import { Column, SelectColumn } from "react-data-grid";

export const OrderCompletePage = () => {
  const { openModal } = useModal();

  const columns: readonly Column<PurchaseOrdersGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "id",
      name: "발주번호",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(<OrderDetailForm purchaseOrderId={formatterProps.row.id} />, null, "")
              }
            >
              {formatterProps.row.id}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "code",
      name: "발주코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "status",
      name: "발주 상태",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            variant="filled"
            color={
              purchaseOrderStatusColor[
                formatterProps.row.status as keyof typeof purchaseOrderStatusColor
              ]
            }
          >
            {setPurchaseOrderStatus(formatterProps.row.status ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "partner.name",
      name: "협력사명",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                null,
                "협력사 상세",
              )
            }
          >
            {formatterProps.row.partner?.name}
          </LinkAnchor>
        );
      },
    },
    {
      key: "manager",
      name: "담당자",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps.row?.userCode} />,
                null,
                "사용자 상세",
              )
            }
          >
            {formatterProps.row.manager}
          </LinkAnchor>
        );
      },
    },
    {
      key: "numberOfPurchaseOrderItems",
      name: "발주항목 갯수",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const betweenDecreaseQuantity = row?.numberOfPurchaseOrderItems ?? 0;
        return <Flex justify="flex-end">{betweenDecreaseQuantity}</Flex>;
      },
    },
    {
      key: "targetLocationName",
      name: "입고 로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps?.row?.targetLocationCode} />,
                null,
                "로케이션 상세",
              )
            }
          >
            {formatterProps.row.targetLocationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "orderDate",
      name: "발주일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.orderDate !== null
              ? timeUtil(formatterProps.row.orderDate as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      name: "접수일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "deliveryDeadline",
      name: "납기일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.deliveryDeadline !== null
              ? timeUtil(formatterProps.row.deliveryDeadline as string)
              : ""}
          </div>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          발주 완료
        </Text>
        <Text fz="xs">공급업체에 주문 완료한 내역</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<PurchaseOrdersGet200Response, PurchaseOrdersGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<PurchaseOrdersGet200Response> />
      </Flex>
    </Flex>
  );
};
