import { mutateRoutingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { RoutingOutsourceCreateForm } from "@/features/RoutingOutsource/form/CreateForm";
import { RoutingOutsourceDetailForm } from "@/features/RoutingOutsource/form/DetailForm";
import { RoutingOutsourceUpdateForm } from "@/features/RoutingOutsource/form/UpdateForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  DefaultApiRoutingOutsourcesRoutingOutsourceIdDeleteRequest,
  RoutingOutsourcesGet200Response,
  RoutingOutsourcesGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";

export const RoutingOutsourcePage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiRoutingOutsourcesRoutingOutsourceIdDeleteRequest) =>
      mutateRoutingOutsources
        .delete(params)
        .mutationFn(params as DefaultApiRoutingOutsourcesRoutingOutsourceIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert("외주처 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "외주처 코드" }),
          "외주처 삭제 실패",
          "red",
        );
      },
    },
  );

  const columns: readonly Column<RoutingOutsourcesGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "name",
        name: "외주처명",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <RoutingOutsourceDetailForm name={formatterProps.row.name} />,
                  null,
                  "",
                  true,
                )
              }
            >
              {formatterProps.row.name}
            </LinkAnchor>
          );
        },
      },
      {
        key: "routingCode",
        name: "라우팅코드",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <RoutingDetailForm routingCode={formatterProps.row.routingCode} />,
                  null,
                  "",
                  true,
                )
              }
            >
              {formatterProps.row.routingCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "fromLocationName",
        name: "자재 출고 로케이션이름",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={formatterProps.row.fromLocationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.fromLocationName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "toLocationName",
        name: "재공품 입고 로케이션이름",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={formatterProps.row.toLocationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.toLocationName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "options",
        name: "액션",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const updateRoutingOutsourceActionView = () => {
            openModal(
              <RoutingOutsourceUpdateForm formatterProps={formatterProps?.row} />,
              null,
              "외주처 수정",
              true,
            );
          };

          const deleteRoutingOutsourceAction = () => {
            openModal(
              <ConfirmForm
                message="선택한 행을 삭제하시겠습니까?"
                yesCallback={() =>
                  deleteMutate({ routingOutsourceId: formatterProps?.row.id as number })
                }
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              "외주처 삭제",
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon
                variant="subtle"
                onClick={updateRoutingOutsourceActionView}
                color="yellow"
              >
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                variant="subtle"
                onClick={() => deleteRoutingOutsourceAction()}
                color="red"
              >
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 외주처를 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ routingOutsourceId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "외주처 삭제",
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() => openModal(<RoutingOutsourceCreateForm />, null, "외주처 추가", true)}
          createDataTitle="외주처"
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          외주처 리스트
        </Text>
        <Text fz="xs">우리 회사의 외주처에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<RoutingOutsourcesGet200Response, RoutingOutsourcesGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<RoutingOutsourcesGet200Response> />
      </Flex>
    </Flex>
  );
};
