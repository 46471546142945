import { Flex, MultiSelect, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useMemo, useState } from "react";

import { FKInfoLabel } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import {
  useLocationsMultiAutoComplete,
  useLocationsMultiCode,
} from "./multiAuto-useGetLocationsQuery";

interface LocationMultiProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface LocationsMultiAutoCompleteProps
  extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value: string[];
  onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
  maxDropdownHeight?: number;
  width?: string;
}

export const LocationsMultiAutoComplete = (params: LocationsMultiAutoCompleteProps) => {
  const { value: locations, onChange, maxDropdownHeight, width, ...etcParams } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useLocationsMultiAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useLocationsMultiCode(!!searchKeyword, searchKeyword ?? null);

  const onChangeHandler = (e: string[]) => {
    onChange && onChange(e);
  };

  const SelectLocation = forwardRef<HTMLDivElement, LocationMultiProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(Color명: {code})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  // data prop에 들어가는 중복 제거된 data를 useMemo로 감싸기
  const filteredOptions = useMemo(() => {
    const combinedOptions = [
      ...(options ?? []),
      ...(initialOptions ?? []),
      ...(locations.map((value) => ({ label: value, value })) ?? []),
    ];

    return combinedOptions.reduce(
      (unique: LocationMultiProps[], option: LocationMultiProps) =>
        unique.some((u) => u.value === option.value) ? unique : [...unique, option],
      [],
    );
  }, [options, initialOptions, locations]);

  return (
    <MultiSelect
      clearable
      value={locations}
      data={filteredOptions}
      onChange={onChangeHandler}
      searchable
      itemComponent={SelectLocation}
      searchValue={searchKeyword}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onSearchChange={setSearchKeyword}
      nothingFound="No Options"
      rightSection={LocationInfo({
        Location: locations,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const LocationInfo = (params: { Location?: string[]; onChange: (e: string[]) => void }) => {
  const { Location, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange([]);
  };

  return Location?.length ? (
    <FKInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />
    </FKInfoLabel>
  ) : null;
};
