import { useModal } from "@/features/modal/ModalStackManager";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { LotForm } from "@/features/ui/form/lots/form";
import { useLotMutations } from "@/hooks/useLotMutations";
import { Button, Flex } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInner } from "@sizlcorp/sizl-api-document/dist/models";

interface NewLotCreateInputProps {
  lotDescription: string;
  itemCode: string;
  newIdValue: number;
  defaultValue: string;
  disabled: boolean;
  onLotCreated: (lotData: ItemsGet200ResponseRowsInnerLotsInner) => void; // 외부에서 폼 상태를 업데이트할 콜백
}

export const NewLotCreateInput = ({
  lotDescription,
  itemCode,
  newIdValue,
  defaultValue,
  disabled,
  onLotCreated,
  ...etcProps
}: NewLotCreateInputProps) => {
  const { openModal, closeModal } = useModal();
  const { postLotsMutate } = useLotMutations();

  const handleRegister = (values: any) => {
    postLotsMutate.mutate(
      {
        lotsGetRequest: {
          name: values.name,
          expiration: values.expiredAt,
          itemCode: values.itemCode,
        },
      },
      {
        onSuccess: (data) => {
          onLotCreated(data.data);
          closeModal({});
          alert("로트를 생성했습니다.");
        },
      },
    );
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <LotForm itemCodeValue={itemCode} onSubmit={handleRegister} />,
      null,
      "신규 LOT 등록",
      true,
    );
  };

  return (
    <Flex justify="space-between" align="flex-end" w="100%">
      <LotSelectComplete
        withAsterisk
        w="100%"
        label={"LOT 정보"}
        description={`LOT 관리 기준 : ${lotDescription}`}
        dropdownPosition="bottom"
        mr="sm"
        itemCodeValue={itemCode}
        newIdValue={newIdValue}
        defaultValue={defaultValue}
        disabled={disabled}
        {...etcProps}
      />
      <Button disabled={disabled} onClick={addNewModalHandler}>
        신규로트 생성
      </Button>
    </Flex>
  );
};
