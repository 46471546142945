import { bomHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { BOMTableRow } from "@/features/bomList/bomTableRow";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import { Button, Flex, Pagination, Table } from "@mantine/core";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import { SelectColumn } from "react-data-grid";

const BOMListWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const BOMListPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { openModal } = useModal();
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = bomHeader.filter((item) => item.category === "text" && !item.isEnum);

  const pageSize = 10;
  const totalRows = BOMListPageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = BOMListPageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "partnerCode",
      name: "협력사",
      sortable: true,
      resizable: true,
    },
    {
      key: "modelName",
      name: "모델명",
      sortable: true,
      resizable: true,
    },
    {
      key: "bomCode",
      name: "BOMList코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemType",
      name: "품목군",
      sortable: true,
      resizable: true,
    },
    {
      key: "unitText",
      name: "단위",
      sortable: true,
      resizable: true,
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
    },
  ];

  return (
    <BOMListWrap.Wrapper>
      <BOMListWrap.Header>
        <BOMListWrap.HeaderTitle>BOMList 관리</BOMListWrap.HeaderTitle>
        <BOMListWrap.HeaderSubTitle>
          {"우리 회사에서 다루는 BOM에 대해 전개 조회할 수 있는 페이지 입니다."}
        </BOMListWrap.HeaderSubTitle>
      </BOMListWrap.Header>

      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={bomHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={bomHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%" direction="column" gap="xs" justify="space-between">
        <Table withBorder withColumnBorders bg="white">
          <thead>
            <tr>
              <th></th>
              <th>협력사</th>
              <th>모델명</th>
              <th>BOM코드</th>
              <th>품목명</th>
              <th>품목군</th>
              <th>단위</th>
              <th>공정코드</th>
            </tr>
          </thead>
          <tbody>
            {paginatedRows.map((item) => {
              return <BOMTableRow bomData={item} key={item.bomCode} />;
            })}
          </tbody>
        </Table>
        <Pagination
          onChange={setPage}
          value={activePage}
          total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
          size="lg"
          radius="sm"
          position="center"
        />
      </Flex>
    </BOMListWrap.Wrapper>
  );
};

const BOMListPageMockupData = [
  {
    partnerCode: "P001",
    modelName: "ModelA",
    bomCode: "BOMList001",
    itemName: "ItemA",
    spec: "SpecA-001",
    itemType: "TypeA",
    unitText: "EA",
    operationCode: "OP001",
    details: [
      {
        code: "부자재 code",
        itemName: "부자재",
        spec: "-",
        isBasicSpare: true,
        category: "-",
        itemType: "부자재",
        specification: "-",
        unitText: "EA",
        usageQuantity: 1,
        productionProcess: "-",
        bom: "",
      },
      {
        code: "F코드",
        itemName: "G품명",
        spec: "-",
        isBasicSpare: false,
        category: "-",
        itemType: "원자재",
        specification: "-",
        unitText: "kg",
        usageQuantity: 1,
        productionProcess: "-",
        bom: "",
      },
    ],
  },
  {
    partnerCode: "P002",
    modelName: "ModelB",
    bomCode: "BOMList002",
    itemName: "ItemB",
    spec: "SpecB-002",
    itemType: "TypeB",
    unitText: "SET",
    operationCode: "OP002",
  },
  {
    partnerCode: "P003",
    modelName: "ModelC",
    bomCode: "BOMList003",
    itemName: "ItemC",
    spec: "SpecC-003",
    itemType: "TypeC",
    unitText: "PCS",
    operationCode: "OP003",
  },
  {
    partnerCode: "P004",
    modelName: "ModelD",
    bomCode: "BOMList004",
    itemName: "ItemD",
    spec: "SpecD-004",
    itemType: "TypeD",
    unitText: "KG",
    operationCode: "OP004",
    details: [
      {
        code: "부자재 code",
        itemName: "부자재",
        spec: "-",
        isBasicSpare: false,
        category: "-",
        itemType: "부자재",
        specification: "-",
        unitText: "EA",
        usageQuantity: 1,
        productionProcess: "",
        bom: "",
      },
      {
        code: "F코드",
        itemName: "G품명",
        spec: "-",
        isBasicSpare: false,
        category: "-",
        itemType: "원자재",
        specification: "-",
        unitText: "kg",
        usageQuantity: 1,
        productionProcess: "",
        bom: "",
      },
      {
        code: "bom test3",
        itemName: "원자재",
        spec: "-",
        isBasicSpare: false,
        category: "-",
        itemType: "원자재",
        specification: "-",
        unitText: "EA",
        usageQuantity: 1,
        productionProcess: "",
        bom: "",
      },
    ],
  },
  {
    partnerCode: "P005",
    modelName: "ModelE",
    bomCode: "BOMList005",
    itemName: "ItemE",
    spec: "SpecE-005",
    itemType: "TypeE",
    unitText: "EA",
    operationCode: "OP005",
  },
  {
    partnerCode: "P006",
    modelName: "ModelF",
    bomCode: "BOMList006",
    itemName: "ItemF",
    spec: "SpecF-006",
    itemType: "TypeF",
    unitText: "BOX",
    operationCode: "OP006",
  },
  {
    partnerCode: "P007",
    modelName: "ModelG",
    bomCode: "BOMList007",
    itemName: "ItemG",
    spec: "SpecG-007",
    itemType: "TypeG",
    unitText: "SET",
    operationCode: "OP007",
  },
  {
    partnerCode: "P008",
    modelName: "ModelH",
    bomCode: "BOMList008",
    itemName: "ItemH",
    spec: "SpecH-008",
    itemType: "TypeH",
    unitText: "PCS",
    operationCode: "OP008",
  },
  {
    partnerCode: "P009",
    modelName: "ModelI",
    bomCode: "BOMList009",
    itemName: "ItemI",
    spec: "SpecI-009",
    itemType: "TypeI",
    unitText: "KG",
    operationCode: "OP009",
  },
  {
    partnerCode: "P010",
    modelName: "ModelJ",
    bomCode: "BOMList010",
    itemName: "ItemJ",
    spec: "SpecJ-010",
    itemType: "TypeJ",
    unitText: "BOX",
    operationCode: "OP010",
  },
  {
    partnerCode: "P011",
    modelName: "ModelK",
    bomCode: "BOMList011",
    itemName: "ItemK",
    spec: "SpecK-011",
    itemType: "TypeK",
    unitText: "EA",
    operationCode: "OP011",
  },
  {
    partnerCode: "P012",
    modelName: "ModelL",
    bomCode: "BOMList012",
    itemName: "ItemL",
    spec: "SpecL-012",
    itemType: "TypeL",
    unitText: "PCS",
    operationCode: "OP012",
  },
  {
    partnerCode: "P013",
    modelName: "ModelM",
    bomCode: "BOMList013",
    itemName: "ItemM",
    spec: "SpecM-013",
    itemType: "TypeM",
    unitText: "SET",
    operationCode: "OP013",
  },
  {
    partnerCode: "P014",
    modelName: "ModelN",
    bomCode: "BOMList014",
    itemName: "ItemN",
    spec: "SpecN-014",
    itemType: "TypeN",
    unitText: "BOX",
    operationCode: "OP014",
  },
  {
    partnerCode: "P015",
    modelName: "ModelO",
    bomCode: "BOMList015",
    itemName: "ItemO",
    spec: "SpecO-015",
    itemType: "TypeO",
    unitText: "EA",
    operationCode: "OP015",
  },
  {
    partnerCode: "P016",
    modelName: "ModelP",
    bomCode: "BOMList016",
    itemName: "ItemP",
    spec: "SpecP-016",
    itemType: "TypeP",
    unitText: "SET",
    operationCode: "OP016",
  },
  {
    partnerCode: "P017",
    modelName: "ModelQ",
    bomCode: "BOMList017",
    itemName: "ItemQ",
    spec: "SpecQ-017",
    itemType: "TypeQ",
    unitText: "KG",
    operationCode: "OP017",
  },
  {
    partnerCode: "P018",
    modelName: "ModelR",
    bomCode: "BOMList018",
    itemName: "ItemR",
    spec: "SpecR-018",
    itemType: "TypeR",
    unitText: "PCS",
    operationCode: "OP018",
  },
  {
    partnerCode: "P019",
    modelName: "ModelS",
    bomCode: "BOMList019",
    itemName: "ItemS",
    spec: "SpecS-019",
    itemType: "TypeS",
    unitText: "BOX",
    operationCode: "OP019",
  },
  {
    partnerCode: "P020",
    modelName: "ModelT",
    bomCode: "BOMList020",
    itemName: "ItemT",
    spec: "SpecT-020",
    itemType: "TypeT",
    unitText: "EA",
    operationCode: "OP020",
  },
];
