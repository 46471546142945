import { useModal } from "@/features/modal/ModalStackManager";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";
import PMV2PressCapacityChart from "../../../features/ui/PMS/chart/AnalysisPressChart";

export const AnalysisAbilityPage = () => {
  const { openModal } = useModal();

  const Header = {
    equipment: ["기계 제조사", "기계 이름", "제조 연월일", "제조 번호"],
    item: ["모델", "CODE", "품명", "SPEC"],
    todayCh1: ["일 평균 ch1"],
    todayCh2: ["일 평균 ch2"],
    todayTotal: ["일 평균 토탈 톤"],
  };

  const BodyData = {
    equipment: [
      {
        "기계 제조사": "제조사1",
        "기계 이름": "이름1",
        "제조 연월일": "2022-01-01",
        "제조 번호": "1234",
      },
    ],
    item: [{ 모델: "전체", CODE: "-", 품명: "-", SPEC: "-" }],
    todayCh1: [{ "일 평균 ch1": "0 ton" }],
    todayCh2: [{ "일 평균 ch2": "0 ton" }],
    todayTotal: [{ "일 평균 토탈 톤": "0 ton" }],
  };

  return (
    <Flex gap="md" direction="column">
      <Flex justify="space-between">
        <Text size="xl">능력</Text>
        <ActionIcon
          onClick={() => {
            openModal(<MachineSearchBox />, null, "기계 검색");
          }}
        >
          <IconReportSearch />
        </ActionIcon>
      </Flex>
      <CustomTable headers={Header.equipment} data={BodyData.equipment} />
      <Flex gap="md">
        <CustomTable headers={Header.item} data={BodyData.item} width="30%" />
        <PMV2PressCapacityChart />
      </Flex>
      <Flex gap="md">
        <CustomTable headers={Header.todayCh1} data={BodyData.todayCh1} />
        <CustomTable headers={Header.todayCh2} data={BodyData.todayCh2} />
        <CustomTable headers={Header.todayTotal} data={BodyData.todayTotal} />
      </Flex>
    </Flex>
  );
};
