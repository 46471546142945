import { DeliveryStatusTable } from "@/features/deliveryStatus/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Button } from "@mantine/core";

const DeliveryStatus = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  DeliveryStatusTable: DeliveryStatusTable,
});

export const DeliveryStatusPage = () => {
  return (
    <>
      <DeliveryStatus.Wrapper>
        <DeliveryStatus.Header>
          <DeliveryStatus.HeaderTitle>납품현황 리스트</DeliveryStatus.HeaderTitle>
          <DeliveryStatus.HeaderSubTitle>
            납품현황을 조회할 수 있는 페이지 입니다.
          </DeliveryStatus.HeaderSubTitle>
        </DeliveryStatus.Header>
        <DeliveryStatus.DeliveryStatusTable />
      </DeliveryStatus.Wrapper>
    </>
  );
};
