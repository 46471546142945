import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import DatetimeUtil from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Space, Title } from "@mantine/core";
import { OperationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

export const DetailHeader = (params: { operationData: OperationsGet200ResponseRowsInner }) => {
  const {
    name,
    code,
    fromLocationCode,
    fromLocationName,
    toLocationCode,
    toLocationName,
    siteCode,
    createdAt,
    creatorUserCode,
  } = params.operationData || {};

  const { openModal } = useModal();

  return (
    <Container>
      <Title>
        {name} ({code})
      </Title>
      <Space h="md" />
      <ContentField>
        <Label>사업장</Label>
        <Content>{siteCode}</Content>
      </ContentField>
      <ContentField>
        <Label>코드</Label>
        <Content>{code}</Content>
      </ContentField>
      <ContentField>
        <Label>출고로케이션코드</Label>
        <Content>
          <DetailLink
            onClick={() =>
              openModal(<LocationDetailForm locationCode={fromLocationCode} />, null, "")
            }
          >
            {fromLocationCode}
          </DetailLink>
        </Content>
      </ContentField>
      <ContentField>
        <Label>출고로케이션이름</Label>
        <Content>{fromLocationName}</Content>
      </ContentField>
      <ContentField>
        <Label>입고로케이션코드</Label>
        <Content>
          <DetailLink
            onClick={() =>
              openModal(<LocationDetailForm locationCode={toLocationCode} />, null, "")
            }
          >
            {toLocationCode}
          </DetailLink>
        </Content>
      </ContentField>
      <ContentField>
        <Label>입고로케이션이름</Label>
        <Content>{toLocationName}</Content>
      </ContentField>
      <ContentField>
        <Label>생성자 (생성일)</Label>
        <Content>
          {creatorUserCode} ({createdAt ? DatetimeUtil(createdAt) : ""})
        </Content>
      </ContentField>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[0]};
`;

const ContentField = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 23px;
  align-self: stretch;
`;

const Label = styled.label`
  display: flex;
  width: 160px;
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
`;

const Content = styled.span`
  display: flex;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
