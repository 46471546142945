import { mutateMolds } from "@/api/mold/useMoldQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldCreateForm } from "@/features/mold/form/createForm";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { MoldUpdateForm } from "@/features/mold/form/updateForm";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  MasterApiMoldsMoldIdDeleteRequest,
  MoldsGet200Response,
  MoldsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";

export const MoldPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiMoldsMoldIdDeleteRequest) =>
      mutateMolds.delete(params).mutationFn(params as MasterApiMoldsMoldIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert("금형 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "금형 코드" }),
          "금형 삭제 실패",
          "red",
        );
      },
    },
  );

  const columns: readonly Column<MoldsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "code",
        name: "금형코드",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(<MoldDetailForm moldCode={formatterProps.row.code} />, null, "")
              }
            >
              {formatterProps.row.code}
            </LinkAnchor>
          );
        },
      },
      {
        key: "siteCode",
        name: "사업장코드",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(<SiteDetailForm siteCode={formatterProps.row.siteCode} />, null, "")
              }
            >
              {formatterProps.row.siteCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "capacity",
        name: "금형 용량",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return <Flex justify="flex-end">{formatterProps.row.capacity}</Flex>;
        },
      },
      {
        key: "options",
        name: "액션",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailMoldView = () => {
            openModal(<MoldDetailForm moldCode={formatterProps?.row.code} />, null, "금형 상세");
          };

          const updateMoldActionView = () => {
            openModal(
              <MoldUpdateForm formatterProps={formatterProps?.row} />,
              {},
              "금형 수정",
              true,
            );
          };

          const deleteMoldAction = () => {
            openModal(
              <ConfirmForm
                message="선택한 행을 삭제하시겠습니까?"
                yesCallback={() =>
                  deleteMutate({
                    moldId: formatterProps.row.id as number,
                  })
                }
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              "금형 삭제",
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={detailMoldView} color="blue">
                <IconSearch />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={updateMoldActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteMoldAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [closeModal, deleteMutate, openModal],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 금형을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ moldId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "금형 삭제",
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() => openModal(<MoldCreateForm />, null, "금형 추가", true)}
          createDataTitle="금형"
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          금형 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 금형에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<MoldsGet200Response, MoldsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<MoldsGet200Response> />
      </Flex>
    </Flex>
  );
};
