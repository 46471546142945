import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import { Flex, Text, Tooltip } from "@mantine/core";
import { LocationDetailForm } from "../location/detail/LocationDetailForm";

interface WorkLocationCellProps {
  data: {
    defectLocationCode?: string;
    defectLocationName?: string;
    targetLocationCode?: string;
    targetLocationName?: string;
  };
}

export const WorkLocationCell = ({ data }: WorkLocationCellProps) => {
  const { openModal } = useModal();

  const { defectLocationCode, defectLocationName, targetLocationCode, targetLocationName } = data;

  const targetCode = targetLocationCode ?? "-";
  const defectCode = defectLocationCode ?? "-";

  return (
    <Flex direction="column">
      <DetailLink
        onClick={() => {
          if (defectLocationCode)
            openModal(<LocationDetailForm locationCode={defectCode} />, null, "");
        }}
        justify="flex-start"
      >
        <Tooltip
          label={
            <Text fz="sm" fw={700}>
              {defectLocationName}
            </Text>
          }
        >
          <Text
            fz="sm"
            fw={700}
            w="fit-content"
            c={targetLocationCode ? theme.colors?.blue?.[7] : undefined}
            span
          >
            {defectLocationName}
          </Text>
        </Tooltip>
      </DetailLink>
      <DetailLink
        onClick={() => {
          if (targetLocationCode)
            openModal(<LocationDetailForm locationCode={targetCode} />, null, "");
        }}
        justify="flex-start"
      >
        <Tooltip
          label={
            <Text fz="sm" fw={700}>
              {targetLocationName}
            </Text>
          }
        >
          <Text
            fz="sm"
            fw={700}
            w="fit-content"
            c={targetLocationCode ? theme.colors?.blue?.[7] : undefined}
            span
          >
            {targetLocationName}
          </Text>
        </Tooltip>
      </DetailLink>
    </Flex>
  );
};
