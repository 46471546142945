import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { LeftSpace } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderItemsCreateForm } from "@/features/orderItems/form/createForm";
import { DeliveryIncomingForm } from "@/features/orderItems/form/deliveryIncomingForm";
import { ArrivalDeliveryStockPlanForm } from "@/features/purchaseOrderItems/form/arrivalDeliveryStockPlanForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useLoader } from "@/hooks/useLoader";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button, Tabs } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { OrderDetailActionBar } from "../header/orderDetailActionBar";
import { OrderDetailHeader } from "../header/orderDetailHeader";
import { OrderDetailTable } from "../table/OrderDetailTable";

const Detail = Object.assign(
  {},
  {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
    ActionBar: OrderDetailActionBar,
    ActionHeader: ActionHeader,
    ActionButtonBox: ActionButtonBox,
    ActionButton: Button,
    OrderItemsCreateForm: OrderItemsCreateForm,
  },
);

interface OrderDetailFormProps {
  purchaseOrderId: number | undefined;
}

export const OrderDetailForm = (params: OrderDetailFormProps) => {
  const { purchaseOrderId } = params;
  const userCode = getUserCodeByUser();
  const { data: orderData } = useQuery(
    purchaseOrders.detail({
      purchaseOrderId: purchaseOrderId ?? 0,
      populate: ["location", "user", "partner", "purchaseOrderItems"],
    }),
  );

  const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
  const [selectedRowsInform, setSelectedRowsInform] = useState<
    ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  >([]);
  const [data, setData] = useState<any>([]);
  const { openModal } = useModal();
  const { setLoading, LoadingOverlay } = useLoader();

  const handleRowSelect = (selectedRows: any, formatterPropsData: any) => {
    setIsRowBtnActive(selectedRows.size === 1);
    setData(formatterPropsData);
    setSelectedRowsInform(selectedRowsInform);
  };

  return (
    <>
      <Detail.Container>
        <LoadingOverlay />
        <Detail.ActionBar data={orderData?.data} />
        <Detail.Wrapper>
          <Detail.Content>
            {/* <OrderDetailDataHeader /> */}
            <OrderDetailHeader data={orderData?.data} />
            <Tabs defaultValue="order">
              <LeftSpace />
              <Tabs.List>
                <Tabs.Tab value="order">
                  {userCode === "FLOUR" ? "수주 항목" : "발주 항목"}
                </Tabs.Tab>
                <Tabs.Tab value="history">히스토리</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="order">
                <Detail.ActionHeader>
                  <Detail.ActionButtonBox>
                    <Detail.ActionButton
                      leftIcon={<IconPlus />}
                      onClick={() =>
                        openModal(
                          <Detail.OrderItemsCreateForm orderId={orderData?.data?.id} />,
                          null,
                          `${userCode === "FLOUR" ? "수주 항목 추가" : "발주 항목 추가"}`,
                        )
                      }
                    >
                      추가
                    </Detail.ActionButton>
                    <Detail.ActionButton
                      leftIcon={<IconPlus />}
                      color="green"
                      disabled={!isRowBtnActive}
                      onClick={() =>
                        openModal(
                          <ArrivalDeliveryStockPlanForm formatterProps={data} />,
                          null,
                          "입고계획 작성",
                        )
                      }
                    >
                      입고계획 작성
                    </Detail.ActionButton>
                    <Detail.ActionButton
                      leftIcon={<IconPlus />}
                      color="green"
                      disabled={!isRowBtnActive}
                      onClick={() =>
                        openModal(
                          <DeliveryIncomingForm purchaseOrder={data} setLoading={setLoading} />,
                          null,
                          "납품",
                        )
                      }
                    >
                      납품
                    </Detail.ActionButton>
                  </Detail.ActionButtonBox>
                </Detail.ActionHeader>
                <OrderDetailTable onRowSelect={handleRowSelect} orderId={orderData?.data?.id} />
              </Tabs.Panel>
              <Tabs.Panel value="history">
                {userCode === "FLOUR" ? "수주 항목" : "발주 항목"} 히스토리
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};
