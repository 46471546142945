import { InspectionDetailForm } from "@/features/inspectionRecords/form/detailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { ActionIcon, Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  InspectionsGet200Response,
  InspectionsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle, IconSearch } from "@tabler/icons-react";
import { Column, SelectColumn } from "react-data-grid";

// 우리 회사에서 다루는 모든 검사 기록을 확인할 수 있습니다.
export const InspectionRecordsPage = () => {
  const { openModal, closeModal } = useModal();
  const { resetQueryStrings } = useStandardLayout();

  const columns: readonly Column<InspectionsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }: any) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(<ItemsDetailForm itemCode={row.itemCode} />, null, "재고 상세")
            }
          >
            {row.itemCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "lotName",
      name: "로트명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Flex>{row.lot?.name}</Flex>;
      },
    },
    {
      key: "additional.productionPlanId",
      name: "생산계획번호",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Flex w="100%" h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(
                  <ProductionPlanViewForm ProductionPlanId={row?.productionPlanId} />,
                  null,
                  "생산계획 상세",
                )
              }
            >
              {/* <Text ta="end">{row?.productionPlanId}</Text> */}
              {row?.productionPlanId}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "summary",
      name: "검사 기준 요약",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const summaryNames = formatterProps.row.specificationData?.criteria?.map(
          (data) => data.name,
        );

        return (
          <Flex>
            {summaryNames && summaryNames?.length > 1
              ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
              : summaryNames && summaryNames[0]}
          </Flex>
        );
      },
    },
    {
      key: "updatedAt",
      name: "검사일자",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <Flex>{DatetimeUtil(row.updatedAt ?? "")}</Flex>,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const detailInspectionActionView = () => {
          openModal(
            <InspectionDetailForm inspectionId={formatterProps?.row.id} />,
            null,
            "검사기록 상세",
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={detailInspectionActionView} color="blue">
              <IconSearch />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="flex-end" rowGap="md">
        <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
          검색값 초기화
        </Button>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          검사기록 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 품목 검사기록에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<InspectionsGet200Response, InspectionsGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<InspectionsGet200Response> />
      </Flex>
    </Flex>
  );
};
