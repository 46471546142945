import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import timeUtil from "@/utils/timeUtil";
import { Checkbox, Space } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

const DetailLot = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

export interface DetailLotInformProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | undefined;
}

export const LotDetailInForm = (params: DetailLotInformProps) => {
  const { data } = params;
  const { t } = useTranslation();
  return (
    <>
      <DetailLot>
        <DetailLot.Title>{`[로트 상세]`}</DetailLot.Title>
        <Space h={8} />
        <DetailLot.Field>
          <DetailLot.Label>{"로트 번호"}</DetailLot.Label>
          <DetailLot.Content>{data?.lotId}</DetailLot.Content>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"로트명"}</DetailLot.Label>
          <DetailLot.Content>{data?.lot?.name}</DetailLot.Content>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"로트 유효기한"}</DetailLot.Label>
          <DetailLot.Content>{data?.lot?.expiration}</DetailLot.Content>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"생성일"}</DetailLot.Label>
          <DetailLot.Content>{timeUtil(data?.createdAt ?? "")}</DetailLot.Content>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"품목코드"}</DetailLot.Label>
          <DetailLot.Content>{data?.itemCode}</DetailLot.Content>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"품목 명"}</DetailLot.Label>
          <DetailLot.Content>{data?.itemName}</DetailLot.Content>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"품목군"}</DetailLot.Label>
          <DetailLot.Badge>{t(data?.itemType ?? "")}</DetailLot.Badge>
        </DetailLot.Field>
        <DetailLot.Field>
          <DetailLot.Label>{"최종 재고"}</DetailLot.Label>
          <DetailLot.Content>
            {data?.quantity} {data?.unitText}
          </DetailLot.Content>
        </DetailLot.Field>
      </DetailLot>
    </>
  );
};
