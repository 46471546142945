import { mutateDepartment } from "@/api/department/useDepartmentQuery";
import { DepartmentCreateForm } from "@/features/department/form/createForm";
import { DepartmentUpdateForm } from "@/features/department/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  DepartmentsGet200Response,
  DepartmentsGet200ResponseRowsInner,
  MasterApiDepartmentsDepartmentIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import type { Column } from "react-data-grid";
import { SelectColumn } from "react-data-grid";

export const DepartmentPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiDepartmentsDepartmentIdDeleteRequest) =>
      mutateDepartment.delete(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customAlert("부서가 성공적으로 삭제되었습니다.", "삭제 성공", "green");
        refetch();
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "부서 삭제에 실패하였습니다.",
          "삭제 실패",
          "red",
        );
      },
    },
  );

  const columns: readonly Column<DepartmentsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "code",
      name: "부서 코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "name",
      name: "부서명",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateDepartmentActionView = () => {
          openModal(<DepartmentUpdateForm {...formatterProps.row} />, {}, "부서 수정", true);
        };

        const deleteDepartmentAction = () => {
          openModal(
            <ConfirmForm
              message="선택한 행을 삭제하시겠습니까?"
              yesCallback={() => deleteMutate({ departmentId: formatterProps?.row.id as number })}
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "부서 삭제",
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updateDepartmentActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deleteDepartmentAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 부서를 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ departmentId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "부서 삭제",
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() => openModal(<DepartmentCreateForm code={""} />, null, "부서 추가", true)}
          createDataTitle="부서"
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          부서 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 부서에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<DepartmentsGet200Response, DepartmentsGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<DepartmentsGet200Response> />
      </Flex>
    </Flex>
  );
};
