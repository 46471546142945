import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

import { routings } from "@/api/routing/useRoutingQuery";
import { RoutingBomList } from "@/custom/features/item/components/form/itemStepperForm/RoutingList";
import { InspectSpecificationUpdateForm } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationUpdateForm";
import { RoutingInform } from "@/features/routing/components/inform/RoutingInform";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";
import { RoutingDetailActionHeader } from "../header/RoutingDetailActionHeader";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
});

interface RoutingDetailFormProps {
  routingCode: string | undefined;
}
export const RoutingDetailForm = ({ routingCode }: RoutingDetailFormProps) => {
  const { data: routing } = useQuery(
    routings.get({
      query: {
        $and: [{ code: { $eq: routingCode } }],
      },
      populate: ["routingBoms"],
    }),
  );

  const routingData = routing?.data?.rows && routing?.data?.rows[0];

  return (
    <>
      <Detail.Container>
        <RoutingDetailActionHeader data={routingData} />
        <Detail.Wrapper>
          <Detail.Content>
            <RoutingInform data={routingData} />
            <Tabs
              variant="outline"
              defaultValue="bom"
              styles={(theme) => ({
                tab: {
                  "&:not([data-active])": {
                    backgroundColor: theme.colors?.gray?.[0],
                  },
                },
              })}
            >
              <Tabs.List>
                <LeftSpace />
                <Tabs.Tab value="bom">BOM</Tabs.Tab>
                <Tabs.Tab value="inspectSpecification">검사표준</Tabs.Tab>
                <RightSpace />
              </Tabs.List>
              <Tabs.Panel value="bom">
                <RoutingBomList formData={routing?.data?.rows} />
              </Tabs.Panel>
              <Tabs.Panel value="inspectSpecification">
                <InspectSpecificationUpdateForm data={routingData} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
