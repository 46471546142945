import useInventoriesGetQuery from "@/api/inventories/useInventoriesGetQuery";
import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Input, Table } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultApiLotsGetLotNamePostRequest,
  DefaultApiLotsPostRequest,
  DefaultApiWmsBulkInputPostRequest,
  WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner,
  WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { BulkIncomingFormRow } from "./BulkIncomingFormRow";
import { Td } from "./OutgoingAllForm";

interface IncomingBulkFormProps
  extends WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner {
  items: {
    itemCode: string;
    itemName: string;
    locationCode: string;
    locationName: string;
    spec: string;
    quantity: string;
    lotId: number;
    lotInfo: WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo;
    actionFrom: string;
  }[];
}

export const IncomingBulkForm = () => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const form = useForm<Partial<IncomingBulkFormProps>>({
    initialValues: {
      items: [],
      itemCode: undefined,
      locationCode: undefined,
      quantity: undefined,
      lotId: undefined,
      lotInfo: {
        name: undefined,
        expiration: undefined,
      },
      actionFrom: undefined,
    },
  });

  const { mutate: bulkIncomingMutate } = useMutation(
    (params: DefaultApiWmsBulkInputPostRequest) =>
      mutateInventories
        .bulkIncoming(params)
        .mutationFn(params as DefaultApiWmsBulkInputPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        closeModal(form.values);
        customAlert("벌크 입고에 성공하였습니다.", "벌크 입고 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "벌크 입고" }),
          "벌크 입고 등록 실패",
          "red",
        );
      },
    },
  );

  const { mutate: getLotName } = useMutation((params: DefaultApiLotsGetLotNamePostRequest) =>
    mutateLots.getLotName(params).mutationFn(params as DefaultApiLotsGetLotNamePostRequest | any),
  );

  const { mutate: postLotsMutate } = useMutation(
    (params: DefaultApiLotsPostRequest) =>
      mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lots"]);
      },
    },
  );

  const [lotId, setLotId] = useState<string>("");

  let tempLotId = "";

  const onSubmit = () => {
    const items: IncomingBulkFormProps["items"] = form.values?.items || [];

    bulkIncomingMutate({
      wmsBulkInputPostRequest: {
        locationCode: items.map((item) => item.locationCode),
        itemCode: items.map((item) => item.itemCode),
        quantity: items.map((item) => item.quantity),
        lotId: items.map((item) => item.lotId),
        lotInfo: items.map((item) => item.lotInfo),
        actionFrom: items.map((item) => item.actionFrom),
      },
    });
  };

  useInventoriesGetQuery(
    {
      query: lotId
        ? {
            $and: [
              {
                lotId: { $eq: lotId },
              },
              {
                quantity: { $gt: 0 },
              },
            ],
          }
        : {},
    },
    {
      onSuccess: (response) => {
        const lotData = response.rows?.[0];
        tempLotId = "";
        setLotId("");
        if (!lotData) {
          alert("해당 LOT 정보가 없습니다.");
          return;
        }

        const { lot, itemCode, itemName, spec, quantity, locationCode, locationName } = lotData;

        getLotName(
          {
            lotsGetLotNamePostRequest: {
              itemCode: [lotData.itemCode],
            },
          },
          {
            onSuccess: (res) => {
              postLotsMutate(
                {
                  lotsGetRequest: {
                    name: res.data[0],
                    itemCode: lotData.itemCode,
                  },
                },
                {
                  onSuccess: (res) => {
                    form.insertListItem("items", {
                      lotId: res?.data?.id,
                      itemCode,
                      itemName,
                      spec,
                      quantity,
                      locationCode,
                      locationName,
                      lotInfo: { name: res?.data?.name, expiration: res?.data?.expiration },
                      actionFrom: "WEB",
                    });
                  },
                },
              );
            },
          },
        );
      },
    },
  );

  const handleRowChange = (index: number, quantity: string) => {
    form.setFieldValue(`items.${index}.quantity`, quantity);
  };

  const handleRowDelete = (index: number) => {
    form.removeListItem("items", index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setLotId(tempLotId);
    } else {
      tempLotId += e.key;
    }
  };

  return (
    <IncomingBarcodeFormContainer>
      <TitleHeader>
        <HeaderTitle>바코드 LOT 입력</HeaderTitle>
        <HeaderSubTitle>
          바코드를 스캔하여 자동으로 데이터를 입력합니다. <br /> 등록 버튼을 클릭하여 입고를
          완료합니다.
        </HeaderSubTitle>
      </TitleHeader>
      <BarcodeInputContainer>
        <Input.Wrapper label="바코드 입력 필드">
          <Input
            placeholder="바코드를 입력하세요"
            onKeyDown={handleKeyDown}
            value={tempLotId}
            data-autofocus
          />
        </Input.Wrapper>
      </BarcodeInputContainer>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th width={8}>LOT ID</Th>
              <Th width={14}>로트명</Th>
              <Th width={14}>로케이션 코드</Th>
              <Th width={14}>로케이션 명</Th>
              <Th width={14}>품목코드</Th>
              <Th width={14}>품목명</Th>
              <Th width={10}>규격</Th>
              <Th width={14}>수량</Th>
              <Th width={7}>&nbsp;</Th>
            </tr>
          </thead>
          <tbody>
            {form.values?.items?.map((formData, index) => (
              <BulkIncomingFormRow
                formData={formData}
                seq={index}
                key={formData.lotId}
                onChange={handleRowChange}
                onDelete={handleRowDelete}
              />
            ))}
            {form.values.items && form.values.items.length === 0 && (
              <tr>
                <Td colSpan={8} width={100} style={{ textAlign: "center" }}>
                  바코드를 스캔하여 LOT를 등록하세요.
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <ButtonBox>
        <Button color="gray" onClick={closeModal}>
          취소
        </Button>
        <Button onClick={onSubmit}>재고 입고</Button>
      </ButtonBox>
    </IncomingBarcodeFormContainer>
  );
};

const IncomingBarcodeFormContainer = styled.div`
  display: flex;
  width: 70rem;
  min-height: 20rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const BarcodeInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  padding: 8px;
  align-items: flex-end;
  gap: 10px;
  flex-shrink: 0;
`;

const ButtonBox = styled.div`
  display: flex;
  height: 44px;
  padding: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
`;
