import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, NumberInput, TextInput } from "@mantine/core";

import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";

import { mutateMolds } from "@/api/mold/useMoldQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldsMoldIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const MoldUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;

  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiMoldsMoldIdPutRequest) =>
      mutateMolds.update(params).mutationFn(params as MasterApiMoldsMoldIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["molds"]);
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      siteCode: formatterProps.siteCode,
      capacity: formatterProps.capacity ?? 0,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate(
        {
          moldId: formatterProps.id as number,
          moldsGetRequest: {
            code: form.values.code!,
            siteCode: form.values.siteCode!,
            capacity: form.values.capacity!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert("금형 수정에 성공하였습니다.", "수정 성공", "green");
            } else {
              customAlert("금형 수정에 실패하였습니다.", "수정 실패", "red");
            }
          },
        },
      );
    } catch (e) {
      customAlert("금형 수정에 실패하였습니다.", "수정 실패", "red");
    }
  };

  return (
    <>
      <Update.Wrapper>
        <Update.Input
          label="금형코드"
          withAsterisk
          defaultValue={formatterProps?.code}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "red !important" : "",
              borderColor: form.isDirty("code") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <SitesAutoComplete
          label="사업장 코드"
          width="100%"
          defaultValue={formatterProps?.siteCode}
          {...form.getInputProps("siteCode")}
          error={form.isDirty("siteCode") ? "저장 필요" : ""}
        />
        <NumberInput
          label="금형 용량"
          // defaultValue={formatterProps?.capacity}
          {...form.getInputProps("capacity")}
          value={!formatterProps.capacity ? 0 : Number(formatterProps.capacity)}
          error={form.isDirty("capacity") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("capacity") ? "red !important" : "",
              borderColor: form.isDirty("capacity") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "mold",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            금형 수정
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
