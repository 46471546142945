import { consumeTypeColor } from "@/constants/routings";
import { BomByOperationCreateForm } from "@/features/bomSearch/table/form/BomByOperationCreateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Table, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  ItemsGet200ResponseRowsInnerRoutingsInner,
  ItemsOneStepCreatePostRequestRoutingsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RoutingBomItemRow } from "./RoutingBomItemRow";

interface RoutingBomRowPreviewProps {
  bomRow: ItemsOneStepCreatePostRequestRoutingsInner;
  rowIndex: number;
}

// 품목 생성 시 데이터 미리보기를 위한 컴포넌트
export const RoutingBomRowPreview = ({ bomRow, rowIndex }: RoutingBomRowPreviewProps) => {
  const { t } = useTranslation();
  const [opened, { toggle }] = useDisclosure(false);
  const { openModal } = useModal();

  const isPreview = (bomRow as ItemsGet200ResponseRowsInnerRoutingsInner)?.id === undefined;

  const openCreateModal = ({ routingCode }: { routingCode: string | undefined }) => {
    openModal(<BomByOperationCreateForm routingCode={routingCode} />, null, "BOM 추가");
  };

  return (
    <Fragment>
      <tr key={bomRow.code ?? "" + rowIndex}>
        <td>
          <Flex justify="center" align="center">
            {bomRow.routingBoms && bomRow.routingBoms.length && !opened ? (
              <IconPlus onClick={toggle} />
            ) : (
              <IconMinus onClick={toggle} />
            )}
          </Flex>
        </td>
        <td>
          <Text ta="end">{bomRow.seq}</Text>
        </td>
        <td>{bomRow.operationCode}</td>
        <td>
          <Badge color={consumeTypeColor[bomRow.consumeType!]}>{t(bomRow.consumeType ?? "")}</Badge>
        </td>
      </tr>
      {bomRow.routingBoms && opened && (
        <tr style={{ backgroundColor: "#f3f0ff" }}>
          <td></td>
          <td colSpan={5}>
            <Table withColumnBorders>
              <thead>
                <tr>
                  <Th width={15}>품목군</Th>
                  <Th width={15}>품목명</Th>
                  <Th width={15}>Grade명</Th>
                  <Th width={15}>Color</Th>
                  <Th width={15}>형태</Th>
                  <Th width={10}>단위</Th>
                  <Th width={10}>비율 (%)</Th>
                  {!isPreview && <Th width={10}>액션</Th>}
                </tr>
              </thead>
              <tbody>
                {bomRow.routingBoms &&
                  bomRow.routingBoms.map((bom, i) => {
                    // 여기서 itemWithGrade 가져와서 바인딩 및 테이블 수정하면 될듯
                    // 품목 등록 시 미리보기할 때는 타입이 없음.. 이거 파악해서 넣는거 필요함

                    return <RoutingBomItemRow key={bom.itemCode} bom={bom} />;
                  })}
                {!isPreview && (
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      <Button
                        leftIcon={<IconPlus />}
                        variant={"subtle"}
                        onClick={() => openCreateModal({ routingCode: bomRow.code })}
                      >
                        새로운 BOM 추가
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </Fragment>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
