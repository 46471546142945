import { useModal } from "@/features/modal/ModalStackManager";
import { OrderItemsCreateForm } from "@/features/orderItems/form/createForm";
import { DeliveryIncomingForm } from "@/features/orderItems/form/deliveryIncomingForm";
import { OrderItemsTable } from "@/features/orderItems/table";
import { ArrivalDeliveryStockPlanForm } from "@/features/purchaseOrderItems/form/arrivalDeliveryStockPlanForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconPaperBag, IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

const OrderItems = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  OrderItemsTable: OrderItemsTable,
  OrderItemsCreateForm: OrderItemsCreateForm,
});

export const OrderItemsPage = () => {
  const publish = usePub();
  const userCode = getUserCodeByUser();
  const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
  const [selectedRowsInform, setSelectedRowsInform] = useState<
    ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  >([]);
  const [data, setData] = useState<AuthSignupPost201ResponseEquipmentPurchaseOrderItem>();
  const { openModal } = useModal();
  const { setLoading, LoadingOverlay } = useLoader();

  const handleRowSelect = (
    selectedRows: any,
    formatterPropsData: AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  ) => {
    setIsRowBtnActive(selectedRows.size === 1);
    setData(formatterPropsData);
    setSelectedRowsInform(selectedRowsInform);
  };
  return (
    <>
      <OrderItems.Wrapper>
        <LoadingOverlay />
        <OrderItems.ActionHeader>
          <OrderItems.ActionButtonBox>
            <OrderItems.ActionButton
              leftIcon={<IconPlus />}
              onClick={() =>
                openModal(
                  <OrderItems.OrderItemsCreateForm />,
                  null,
                  `${userCode === "FLOUR" ? "수주 항목 추가" : "발주 항목 추가"}`,
                )
              }
            >
              추가
            </OrderItems.ActionButton>
            <OrderItems.ActionButton
              leftIcon={<IconPaperBag />}
              color="green"
              disabled={!isRowBtnActive}
              onClick={() =>
                openModal(
                  <ArrivalDeliveryStockPlanForm formatterProps={data} />,
                  null,
                  "입고계획 작성",
                  true,
                )
              }
            >
              입고계획 작성
            </OrderItems.ActionButton>
            <OrderItems.ActionButton
              leftIcon={<IconPlus />}
              color="green"
              disabled={!isRowBtnActive}
              onClick={() =>
                openModal(
                  <DeliveryIncomingForm purchaseOrder={data} setLoading={setLoading} />,
                  null,
                  "입고",
                )
              }
            >
              입고
            </OrderItems.ActionButton>
          </OrderItems.ActionButtonBox>
          <OrderItems.ActionButton
            color="red"
            rightIcon={<IconTrash />}
            onClick={() => publish("deleteSelectedItems")}
          >
            선택된 행 삭제
          </OrderItems.ActionButton>
        </OrderItems.ActionHeader>
        <OrderItems.Header>
          <OrderItems.HeaderTitle>
            {userCode === "FLOUR" ? "수주 항목" : "발주 항목"}
          </OrderItems.HeaderTitle>
          <OrderItems.HeaderSubTitle>품목별 공급업체 주문 건</OrderItems.HeaderSubTitle>
        </OrderItems.Header>
        <OrderItems.OrderItemsTable onRowSelect={handleRowSelect} />
      </OrderItems.Wrapper>
    </>
  );
};
