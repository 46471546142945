import { lackSafetyHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, Select, Text } from "@mantine/core";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const LackSafetyWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
};

export const LackSafetyPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = lackSafetyHeader.filter(
    (item) => item.category === "text" && !item.isEnum,
  );

  const pageSize = 10;
  const totalRows = LackSafetyPageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = LackSafetyPageMockupData.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "locationName",
      name: "로케이션/공정명",
      sortable: true,
      resizable: true,
    },
    {
      key: "lotNo",
      name: "Lot 번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "lotDate",
      name: "Lot 유효기한",
      sortable: true,
      resizable: true,
    },
    {
      key: "safetyStock",
      name: "안전재고",
      sortable: true,
      resizable: true,
    },
    {
      key: "currentStock",
      name: "현재고",
      sortable: true,
      resizable: true,
    },
    {
      key: "imbalance",
      name: "과부족량",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Text fw={700} color={row.imbalance > 0 ? "blue" : "red"}>
            {row.imbalance}
          </Text>
        );
      },
    },
    {
      key: "description",
      name: "비고",
      sortable: true,
      resizable: true,
    },
  ];

  return (
    <LackSafetyWrap.Wrapper>
      <LackSafetyWrap.Header>
        <LackSafetyWrap.HeaderTitle>안전재고 부족조회</LackSafetyWrap.HeaderTitle>
        <LackSafetyWrap.HeaderSubTitle>
          {
            "우리 회사에 관리된 재고에서 안전재고 수준을 기준으로 현재 재고가 부족한 품목을 조회합니다."
          }
        </LackSafetyWrap.HeaderSubTitle>
      </LackSafetyWrap.Header>
      <Flex w="100%" justify="space-between" gap="xs" align="flex-end">
        <Flex gap="xs">
          <Select label="로케이션" placeholder="로케이션 선택" data={["생산창고", "입고창고"]} />
        </Flex>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={lackSafetyHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={lackSafetyHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </LackSafetyWrap.Wrapper>
  );
};

const LackSafetyPageMockupData = [
  {
    itemName: "Bolt 10mm",
    itemCode: "B001",
    spec: "10mm",
    locationName: "창고 A",
    lotNo: "L001",
    lotDate: "2024-12-30 15:45:30",
    safetyStock: 100,
    currentStock: 80,
    imbalance: -20,
    description: "안전재고 부족",
  },
  {
    itemName: "Nut 12mm",
    itemCode: "N002",
    spec: "12mm",
    locationName: "창고 B",
    lotNo: "L002",
    lotDate: "2024-12-29 14:32:15",
    safetyStock: 200,
    currentStock: 230,
    imbalance: 30,
    description: "",
  },
  {
    itemName: "Washer 15mm",
    itemCode: "W003",
    spec: "15mm",
    locationName: "창고 A",
    lotNo: "L003",
    lotDate: "2024-12-28 13:22:50",
    safetyStock: 150,
    currentStock: 100,
    imbalance: -50,
    description: "추가 발주 필요",
  },
  {
    itemName: "Screw M5",
    itemCode: "S004",
    spec: "M5",
    locationName: "공정 1",
    lotNo: "L004",
    lotDate: "2024-12-27 12:15:00",
    safetyStock: 500,
    currentStock: 450,
    imbalance: -50,
    description: "",
  },
  {
    itemName: "Pipe 25mm",
    itemCode: "P005",
    spec: "25mm",
    locationName: "창고 C",
    lotNo: "L005",
    lotDate: "2024-12-26 11:45:45",
    safetyStock: 300,
    currentStock: 350,
    imbalance: 50,
    description: "재고 과잉",
  },
  {
    itemName: "Clamp 20mm",
    itemCode: "C006",
    spec: "20mm",
    locationName: "공정 2",
    lotNo: "L006",
    lotDate: "2024-12-25 10:22:12",
    safetyStock: 400,
    currentStock: 390,
    imbalance: -10,
    description: "",
  },
  {
    itemName: "Gear S1",
    itemCode: "G007",
    spec: "S1",
    locationName: "창고 B",
    lotNo: "L007",
    lotDate: "2024-12-24 09:18:20",
    safetyStock: 250,
    currentStock: 220,
    imbalance: -30,
    description: "생산용",
  },
  {
    itemName: "Bearing 6200",
    itemCode: "B008",
    spec: "6200",
    locationName: "창고 D",
    lotNo: "L008",
    lotDate: "2024-12-23 08:45:00",
    safetyStock: 100,
    currentStock: 110,
    imbalance: 10,
    description: "",
  },
  {
    itemName: "Filter 50mm",
    itemCode: "F009",
    spec: "50mm",
    locationName: "공정 3",
    lotNo: "L009",
    lotDate: "2024-12-22 07:32:33",
    safetyStock: 75,
    currentStock: 50,
    imbalance: -25,
    description: "필수 부품",
  },
  {
    itemName: "Spring Tension",
    itemCode: "S010",
    spec: "Tension",
    locationName: "창고 E",
    lotNo: "L010",
    lotDate: "2024-12-21 06:25:10",
    safetyStock: 80,
    currentStock: 90,
    imbalance: 10,
    description: "",
  },
  {
    itemName: "Valve 2inch",
    itemCode: "V011",
    spec: "2inch",
    locationName: "공정 4",
    lotNo: "L011",
    lotDate: "2024-12-20 05:50:45",
    safetyStock: 50,
    currentStock: 60,
    imbalance: 10,
    description: "",
  },
  {
    itemName: "Connector 15mm",
    itemCode: "C012",
    spec: "15mm",
    locationName: "창고 F",
    lotNo: "L012",
    lotDate: "2024-12-19 04:10:30",
    safetyStock: 120,
    currentStock: 100,
    imbalance: -20,
    description: "안전재고 부족",
  },
  {
    itemName: "Gasket 3mm",
    itemCode: "G013",
    spec: "3mm",
    locationName: "창고 B",
    lotNo: "L013",
    lotDate: "2024-12-18 03:35:22",
    safetyStock: 70,
    currentStock: 80,
    imbalance: 10,
    description: "",
  },
  {
    itemName: "O-Ring 10mm",
    itemCode: "O014",
    spec: "10mm",
    locationName: "공정 5",
    lotNo: "L014",
    lotDate: "2024-12-17 02:50:55",
    safetyStock: 50,
    currentStock: 30,
    imbalance: -20,
    description: "필수 발주",
  },
  {
    itemName: "Seal 5mm",
    itemCode: "S015",
    spec: "5mm",
    locationName: "창고 G",
    lotNo: "L015",
    lotDate: "2024-12-16 01:22:13",
    safetyStock: 60,
    currentStock: 65,
    imbalance: 5,
    description: "",
  },
  {
    itemName: "Rod 2m",
    itemCode: "R016",
    spec: "2m",
    locationName: "공정 6",
    lotNo: "L016",
    lotDate: "2024-12-15 00:10:00",
    safetyStock: 150,
    currentStock: 140,
    imbalance: -10,
    description: "",
  },
  {
    itemName: "Hose 1/2inch",
    itemCode: "H017",
    spec: "1/2inch",
    locationName: "창고 H",
    lotNo: "L017",
    lotDate: "2024-12-14 23:50:20",
    safetyStock: 200,
    currentStock: 220,
    imbalance: 20,
    description: "",
  },
  {
    itemName: "Chain S2",
    itemCode: "C018",
    spec: "S2",
    locationName: "창고 A",
    lotNo: "L018",
    lotDate: "2024-12-13 22:45:30",
    safetyStock: 75,
    currentStock: 70,
    imbalance: -5,
    description: "",
  },
  {
    itemName: "Tape Industrial",
    itemCode: "T019",
    spec: "Industrial",
    locationName: "창고 B",
    lotNo: "L019",
    lotDate: "2024-12-12 21:10:45",
    safetyStock: 400,
    currentStock: 350,
    imbalance: -50,
    description: "안전재고 부족",
  },
  {
    itemName: "Pin 5mm",
    itemCode: "P020",
    spec: "5mm",
    locationName: "공정 7",
    lotNo: "L020",
    lotDate: "2024-12-11 20:22:30",
    safetyStock: 150,
    currentStock: 100,
    imbalance: -50,
    description: "재고 부족",
  },
  {
    itemName: "Bracket Large",
    itemCode: "B021",
    spec: "Large",
    locationName: "창고 C",
    lotNo: "L021",
    lotDate: "2024-12-10 19:50:50",
    safetyStock: 60,
    currentStock: 75,
    imbalance: 15,
    description: "",
  },
  {
    itemName: "Sleeve 20mm",
    itemCode: "S022",
    spec: "20mm",
    locationName: "창고 D",
    lotNo: "L022",
    lotDate: "2024-12-09 18:45:00",
    safetyStock: 130,
    currentStock: 140,
    imbalance: 10,
    description: "",
  },
  {
    itemName: "Blade Sharp",
    itemCode: "B023",
    spec: "Sharp",
    locationName: "공정 8",
    lotNo: "L023",
    lotDate: "2024-12-08 17:10:40",
    safetyStock: 50,
    currentStock: 45,
    imbalance: -5,
    description: "",
  },
  {
    itemName: "Belt Drive",
    itemCode: "B024",
    spec: "Drive",
    locationName: "창고 E",
    lotNo: "L024",
    lotDate: "2024-12-07 16:22:13",
    safetyStock: 90,
    currentStock: 95,
    imbalance: 5,
    description: "",
  },
  {
    itemName: "Cover Plastic",
    itemCode: "C025",
    spec: "Plastic",
    locationName: "창고 F",
    lotNo: "L025",
    lotDate: "2024-12-06 15:12:05",
    safetyStock: 200,
    currentStock: 170,
    imbalance: -30,
    description: "추가 발주 필요",
  },
  {
    itemName: "Panel Control",
    itemCode: "P026",
    spec: "Control",
    locationName: "공정 9",
    lotNo: "L026",
    lotDate: "2024-12-05 14:55:55",
    safetyStock: 50,
    currentStock: 40,
    imbalance: -10,
    description: "안전재고 부족",
  },
  {
    itemName: "Motor Electric",
    itemCode: "M027",
    spec: "Electric",
    locationName: "창고 G",
    lotNo: "L027",
    lotDate: "2024-12-04 13:25:25",
    safetyStock: 300,
    currentStock: 290,
    imbalance: -10,
    description: "",
  },
  {
    itemName: "Shaft 10mm",
    itemCode: "S028",
    spec: "10mm",
    locationName: "창고 H",
    lotNo: "L028",
    lotDate: "2024-12-03 12:32:00",
    safetyStock: 100,
    currentStock: 110,
    imbalance: 10,
    description: "",
  },
  {
    itemName: "Bearing Roller",
    itemCode: "B029",
    spec: "Roller",
    locationName: "창고 A",
    lotNo: "L029",
    lotDate: "2024-12-02 11:15:45",
    safetyStock: 200,
    currentStock: 190,
    imbalance: -10,
    description: "",
  },
  {
    itemName: "Switch On/Off",
    itemCode: "S030",
    spec: "On/Off",
    locationName: "창고 B",
    lotNo: "L030",
    lotDate: "2024-12-01 10:22:13",
    safetyStock: 50,
    currentStock: 55,
    imbalance: 5,
    description: "",
  },
  {
    itemName: "Wheel Small",
    itemCode: "W031",
    spec: "Small",
    locationName: "공정 10",
    lotNo: "L031",
    lotDate: "2024-11-30 09:45:10",
    safetyStock: 60,
    currentStock: 50,
    imbalance: -10,
    description: "추가 발주 필요",
  },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
