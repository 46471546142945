import { inspections } from "@/api/inspect/inspectSpecification/useInspectionQuery";
import { DateSlashtimeUtil } from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Pagination, Table, Text } from "@mantine/core";
import { InspectionsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

interface WorkInspectRecordProps {
  productionPlanId: number | undefined;
}
export const WorkInspectRecord = (props: WorkInspectRecordProps) => {
  const { productionPlanId } = props;
  const [activePage, setActivePage] = useState<number>(1);

  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

  const { data } = useQuery(
    inspections.get({
      query: {
        $and: [{ productionPlanId: productionPlanId }],
      },
      populate: ["lot"],
      sort: "-id",
    }),
  );

  const rows: InspectionsGet200ResponseRowsInner[] = data?.data?.rows || [];

  const toggleRow = (id: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  let recordIndex = 0;

  return (
    <WorkTabBodyContainer>
      <TabBodyTitle>
        <Text fz="xl" fw={500}>
          검사 기록
        </Text>
        <Text fz="xs" fw={400}>
          생산 계획에 대한 검사 기록을 표시합니다.
        </Text>
      </TabBodyTitle>
      <TabBodyTable>
        <Thead>
          <Tr>
            <Th width={40}></Th>
            <Th style={{ width: "95%" }}>검사 번호</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.length ? (
            rows.map((inspection, index) => (
              <>
                <Tr>
                  <Td width={60}>
                    {!expandedRows[inspection.id] && (
                      <IconPlus onClick={() => toggleRow(inspection.id)} />
                    )}
                    {expandedRows[inspection.id] && (
                      <IconMinus onClick={() => toggleRow(inspection.id)} />
                    )}
                  </Td>
                  <Td width={160} style={{ justifyContent: "center" }}>
                    {inspection.id}
                  </Td>
                  <Td style={{ fontWeight: "bold" }} width={430}>
                    로트 번호 : {inspection.lotId}
                  </Td>
                  <Td style={{ fontWeight: "bold" }} width={430}>
                    로트명 : {inspection.lot?.name}
                  </Td>
                </Tr>
                {expandedRows[inspection.id] && (
                  <>
                    <Tr>
                      <Th width={60}></Th>
                      <Th width={160}>검사항목</Th>
                      <Th width={300}>초품</Th>
                      <Th width={300}>중품</Th>
                      <Th width={300}>종품</Th>
                    </Tr>
                    {inspection?.specificationData?.criteria?.map((criteria) => (
                      <>
                        <Tr>
                          <Td width={60}></Td>
                          <Td width={160}>{criteria.name}</Td>
                          {inspection?.record?.[recordIndex]?.map(
                            (recordItem: any, itemIndex: number) => {
                              const widths =
                                inspection?.record?.[recordIndex]?.length === 3
                                  ? [300, 300, 300]
                                  : inspection?.record?.[recordIndex]?.length === 2
                                    ? [450, 450]
                                    : [900];
                              return (
                                <>
                                  <Td width={widths[itemIndex]} key={itemIndex}>
                                    {(() => {
                                      const resultValue = recordItem?.isSaved
                                        ? recordItem?.value
                                          ? "합격"
                                          : "불합격"
                                        : "";
                                      const resultName = recordItem?.isSaved
                                        ? recordItem?.creatorUserName
                                        : "";
                                      const resultDate = recordItem?.isSaved
                                        ? DateSlashtimeUtil(recordItem?.createdAt)
                                        : "";
                                      return resultValue ? (
                                        <>
                                          <Text>
                                            <Text
                                              style={{
                                                color: resultValue === "합격" ? "green" : "red",
                                              }}
                                            >
                                              {`${resultValue}`}
                                            </Text>
                                            {`\n검사자 : ${resultName}\n검사날짜 : ${resultDate}`}
                                          </Text>
                                        </>
                                      ) : (
                                        ""
                                      );
                                    })()}
                                  </Td>
                                </>
                              );
                            },
                          )}
                        </Tr>
                      </>
                    ))}
                  </>
                )}
              </>
            ))
          ) : (
            <NoDataTr>
              <Text>검사 기록이 존재하지 않습니다.</Text>
            </NoDataTr>
          )}
        </Tbody>
      </TabBodyTable>
      <PaginationWrapper>
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={data?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </PaginationWrapper>
    </WorkTabBodyContainer>
  );
};

const WorkTabBodyContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 0.5rem;
`;

const TabBodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const TabBodyTable = styled(Table)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Tr = styled.tr`
  display: flex;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  white-space: pre-wrap;
`;

const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NoDataTr = styled.tr`
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
`;
