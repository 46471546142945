import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { mutateRoutingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const RoutingOutsourceUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest) =>
      mutateRoutingOutsources
        .update(params)
        .mutationFn(params as DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["routingOutsources"]);
        closeModal(formatterProps);
        customAlert("외주처 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "외주처" }),
          "외주처 생성 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      name: formatterProps.name,
      routingCode: formatterProps.routingCode,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        routingOutsourceId: formatterProps?.id,
        routingOutsourcesGetRequest: {
          name: form.values.name!,
          routingCode: form.values.routingCode!, // sample data -> "opop", "op001"
          fromLocationCode: form.values.fromLocationCode!,
          toLocationCode: form.values.toLocationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "외주처" }),
        "외주처 생성 실패",
        "red",
      );
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label="외주처명"
            withAsterisk
            defaultValue={formatterProps?.name}
            {...form.getInputProps("name")}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <RoutingAutoComplete
            label="라우팅코드"
            withAsterisk
            width={"100%"}
            defaultValue={formatterProps?.routingCode}
            {...form.getInputProps("routingCode")}
            error={form.isDirty("routingCode") ? "저장 필요" : ""}
          />
          <LocationAutoComplete
            label="자재 출고 로케이션코드"
            withAsterisk
            width={"100%"}
            defaultValue={formatterProps?.fromLocationCode}
            {...form.getInputProps("fromLocationCode")}
            error={form.isDirty("fromLocationCode") ? "저장 필요" : ""}
          />
          <LocationAutoComplete
            label="재공품 입고 로케이션코드"
            withAsterisk
            width={"100%"}
            defaultValue={formatterProps?.toLocationCode}
            {...form.getInputProps("toLocationCode")}
            error={form.isDirty("toLocationCode") ? "저장 필요" : ""}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routingOutsource",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              외주처 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
