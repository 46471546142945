import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";

import { mutateRoutings } from "@/api/routing/useRoutingQuery";
import { consumeType, routingType } from "@/constants/routings";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiRoutingsRoutingIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const RoutingEditForm = (params: { formatterProps: any }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiRoutingsRoutingIdPutRequest) =>
      mutateRoutings
        .update(params)
        .mutationFn(params as DefaultApiRoutingsRoutingIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["routings"]);
        closeModal(formatterProps);
        customAlert("라우팅 수정에 성공하였습니다.", "수정 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "라우팅코드" }),
          "라우팅 수정 실패",
          "red",
        );
      },
    },
  );

  const lnRoutingType = routingType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const form = useForm({
    initialValues: {
      itemCode: formatterProps.itemCode,
      code: formatterProps.code,
      routingType: formatterProps.routingType,
      moldCode: formatterProps.moldCode,
      consumeType: formatterProps.consumeType,
      operationCode: formatterProps.operationCode,
      seq: formatterProps.seq,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        routingId: formatterProps?.id,
        routingsGetRequest: {
          itemCode: form.values.itemCode ?? (formatterProps.itemCode as any),
          code: form.values.code ?? (formatterProps.code as any),
          routingType: form.values.routingType ?? (formatterProps.routingType as any),
          moldCode: form.values.moldCode ?? (formatterProps.moldCode as any),
          consumeType: form.values.consumeType ?? (formatterProps.consumeType as any),
          operationCode: form.values.operationCode ?? (formatterProps.operationCode as any),
          seq: form.values.seq ?? (formatterProps.seq as any),
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "라우팅코드" }),
        "라우팅 수정 실패",
        "red",
      );
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <ItemAutoComplete
            withAsterisk
            label="품목코드"
            defaultValue={formatterProps?.itemCode}
            {...form.getInputProps("itemCode")}
            error={form.isDirty("itemCode") ? "저장 필요" : ""}
          />
          <OperationAutoComplete
            withAsterisk
            label="공정코드"
            defaultValue={formatterProps?.operationCode}
            {...form.getInputProps("operationCode")}
            error={form.isDirty("operationCode") ? "저장 필요" : ""}
          />
          <TextInput
            withAsterisk
            label="라우팅코드"
            defaultValue={formatterProps?.code}
            {...form.getInputProps("code")}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code") ? "red !important" : "",
                borderColor: form.isDirty("code") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <Select
            withAsterisk
            label="라우팅유형"
            defaultValue={formatterProps?.routingType}
            data={lnRoutingType}
            {...form.getInputProps("routingType")}
            error={form.isDirty("routingType") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("routingType") ? "red !important" : "",
                borderColor: form.isDirty("routingType") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <MoldAutoComplete
            label="금형코드"
            defaultValue={formatterProps?.moldCode}
            {...form.getInputProps("moldCode")}
            error={form.isDirty("moldCode") ? "저장 필요" : ""}
          />
          <Select
            withAsterisk
            label="소모 시점"
            defaultValue={formatterProps?.consumeType}
            data={lnConsumeType}
            {...form.getInputProps("consumeType")}
            error={form.isDirty("consumeType") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("consumeType") ? "red !important" : "inherit",
                borderColor: form.isDirty("consumeType") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            withAsterisk
            label="라우팅순서"
            defaultValue={formatterProps?.seq}
            {...form.getInputProps("seq")}
            error={form.isDirty("seq") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("seq") ? "red !important" : "",
                borderColor: form.isDirty("seq") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />

          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routing",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              라우팅 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
