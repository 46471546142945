import { useModal } from "@/features/modal/ModalStackManager";
import { MoldCavityCreateForm } from "@/features/mold/cavity/form/cavityCreateForm";
import { MoldCavityTable } from "@/features/mold/cavity/table";
import { FormMain } from "@/features/standard/Form/FormMain";
import { Box, Button, Text, Title } from "@mantine/core";

const Cavity = Object.assign(FormMain, {
  Text: Text,
  Title: Title,
  Box: Box,
  Button: Button,
});

export interface MoldCodeProps {
  moldCodeProps?: string | undefined;
}

export const CavityForm = ({ moldCodeProps }: MoldCodeProps) => {
  const { openModal } = useModal();

  return (
    <>
      <Cavity.Title order={3}>캐비티</Cavity.Title>
      <Cavity.Text pb={12}>금형의 캐비티 설정을 보여줍니다.</Cavity.Text>
      <Cavity.Box
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
          padding: theme.spacing.md,
          borderRadius: theme.radius.sm,
          marginBottom: theme.spacing.md,
        })}
      >
        <Cavity.Button
          onClick={() => {
            openModal(
              <MoldCavityCreateForm moldCodeProps={moldCodeProps} />,
              null,
              "캐비티 추가",
              true,
            );
          }}
        >
          캐비티 추가
        </Cavity.Button>
      </Cavity.Box>
      <MoldCavityTable moldCodeProps={moldCodeProps} />
    </>
  );
};
