import { CommonCodeCreateForm } from "@/features/commonCode/form/createForm";
import { CommonPartCodeCreateForm } from "@/features/commonCode/form/partCreateForm";
import { CommonPartCodeUpdateForm } from "@/features/commonCode/form/partUpdateForm";
import { CommonCodeUpdateForm } from "@/features/commonCode/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Checkbox, Flex, Pagination, Text } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

interface CommonCode {
  commonCode: string;
  codeName: string;
}

interface CategoryDetail {
  categoryCode: string;
  categoryName: string;
  systemUse: boolean;
  active: boolean;
}

interface CategoryDetails {
  [key: string]: CategoryDetail[]; // 각 공통 코드(CMM001, CMM002 등)가 key로 사용됨
}

interface CommonCodePageMockupDataType {
  commonCodes: CommonCode[]; // 공통 코드 목록
  categoryDetails: CategoryDetails; // 각 공통 코드에 대한 세부 항목
}

const CommonWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
  CreateForm: CommonCodeCreateForm,
  UpdateForm: CommonCodeUpdateForm,
  PartCreateForm: CommonPartCodeCreateForm,
  PartUpdateForm: CommonPartCodeUpdateForm,
};

export const CommonCodePage = () => {
  const { openModal } = useModal();

  const [code, setCode] = useState<string>(""); // 초기값을 빈 문자열로 설정
  const [details, setDetails] = useState<any[]>([]); // 우측 detail 데이터를 저장하는 상태
  const [parentActivePage, setParentActivePage] = useState(1); // 좌측 테이블의 페이지 상태
  const [childActivePage, setChildActivePage] = useState(1); // 우측 테이블의 페이지 상태

  const pageSize = 10;

  // 선택된 공통 코드에 따른 필터링된 detail 데이터를 업데이트
  useEffect(() => {
    if (code && CommonCodePageMockupData.categoryDetails[code]) {
      setDetails(CommonCodePageMockupData.categoryDetails[code]);
      setChildActivePage(1); // 새 코드 선택 시, 우측 페이지네이션 초기화
    } else {
      setDetails([]); // code가 빈 문자열이면 details는 빈 배열로 설정
    }
  }, [code]);

  // 좌측 데이터의 총 갯수 및 페이지 계산
  const totalParentRows = CommonCodePageMockupData.commonCodes.length;
  const parentPaginatedRows = CommonCodePageMockupData.commonCodes.slice(
    (parentActivePage - 1) * pageSize,
    parentActivePage * pageSize,
  );

  // 우측 데이터의 총 갯수 및 페이지 계산
  const totalChildRows = details.length;
  const childPaginatedRows = details.slice(
    (childActivePage - 1) * pageSize,
    childActivePage * pageSize,
  );

  const parentColumns: readonly Column<any>[] = [
    {
      key: "commonCode",
      name: "공통코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "codeName",
      name: "코드명",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <CommonWrap.UpdateForm formatterProps={formatterProps.row} />,
                  {},
                  "공통코드 수정",
                  true,
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                window.confirm("정말로 삭제하시겠습니까?") &&
                  customAlert("공통코드 삭제에 실패하였습니다.", "삭제 실패", "red");
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  const childColumns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "categoryCode",
      name: "구분 코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "categoryName",
      name: "구분명",
      sortable: true,
      resizable: true,
    },
    {
      key: "systemUse",
      name: "시스템 사용",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Checkbox checked={row.systemUse} />;
      },
    },
    {
      key: "active",
      name: "사용 여부",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return <Checkbox checked={row.active} />;
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <CommonWrap.PartUpdateForm formatterProps={formatterProps.row} />,
                  {},
                  "구분코드 수정",
                  true,
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                window.confirm("정말로 삭제하시겠습니까?") &&
                  customAlert("구분코드 삭제에 실패하였습니다.", "삭제 실패", "red");
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  return (
    <CommonWrap.Wrapper>
      <CommonWrap.ActionHeader>
        <CommonWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() => openModal(<CommonWrap.CreateForm />, null, "공통 코드 추가", true)}
        >
          공통 코드 추가
        </CommonWrap.ActionButton>
        <CommonWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => alert("삭제")}
        >
          선택된 행 삭제
        </CommonWrap.ActionButton>
      </CommonWrap.ActionHeader>

      <Flex w="100%" h="100%" gap="sm">
        {/* 왼쪽 공통코드 테이블 */}
        <GridWrapper>
          <DataGrid
            columns={parentColumns ?? []}
            rows={parentPaginatedRows ?? []}
            rowHeight={40}
            rowKeyGetter={(row) => row.commonCode}
            onRowClick={(row) => setCode(row.commonCode)} // 코드 선택 시 상태 업데이트
          />
          <Pagination
            onChange={setParentActivePage} // 페이지 변경 핸들러
            value={parentActivePage}
            total={Math.ceil(totalParentRows / pageSize)} // 데이터에 따라 동적 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>

        {/* 우측 구분코드 테이블 (선택된 공통코드에 따라) */}
        <GridWrapper>
          {code ? (
            <Flex direction="column" gap="sm" style={{ border: "1px solid black" }} p="md">
              <Text></Text>
              <CommonWrap.ActionHeader>
                <CommonWrap.ActionButton
                  leftIcon={<IconPlus />}
                  onClick={() =>
                    openModal(<CommonWrap.PartCreateForm />, null, "구분 코드 추가", true)
                  }
                >
                  구분 코드 추가
                </CommonWrap.ActionButton>
                <CommonWrap.ActionButton
                  color="red"
                  rightIcon={<IconTrash />}
                  onClick={() => {
                    window.confirm("정말로 삭제하시겠습니까?") &&
                      customAlert("구분 코드 삭제에 실패하였습니다.", "삭제 실패", "red");
                  }}
                >
                  선택된 행 삭제
                </CommonWrap.ActionButton>
              </CommonWrap.ActionHeader>
              <DataGrid
                columns={childColumns ?? []}
                rows={childPaginatedRows ?? []} // 선택된 코드의 detail 데이터
                rowHeight={40}
                rowKeyGetter={(row) => row.categoryCode}
              />
              <Pagination
                onChange={setChildActivePage} // 우측 페이지네이션 핸들러
                value={childActivePage}
                total={Math.ceil(totalChildRows / pageSize)} // 데이터에 따라 동적 계산
                size="lg"
                radius="sm"
                position="center"
              />
            </Flex>
          ) : (
            <p>공통 코드를 선택하세요.</p> // 코드가 선택되지 않은 경우
          )}
        </GridWrapper>
      </Flex>
    </CommonWrap.Wrapper>
  );
};

const CommonCodePageMockupData: CommonCodePageMockupDataType = {
  commonCodes: [
    { commonCode: "CMM001", codeName: "품목구분" },
    { commonCode: "CMM002", codeName: "조달구분" },
    { commonCode: "CMM003", codeName: "형태" },
    { commonCode: "CMM004", codeName: "품목대분류" },
    { commonCode: "CMM007", codeName: "단위" },
    { commonCode: "EQM010", codeName: "기계종류" },
    { commonCode: "EQM011", codeName: "용접종류" },
    { commonCode: "EQM020", codeName: "주변장치종류" },
    { commonCode: "CMM030", codeName: "공정자사구분" },
  ],
  categoryDetails: {
    CMM001: [
      { categoryCode: "01", categoryName: "원자재", systemUse: true, active: true },
      { categoryCode: "02", categoryName: "부자재", systemUse: true, active: true },
      { categoryCode: "03", categoryName: "부자재 B", systemUse: true, active: true },
      { categoryCode: "04", categoryName: "완제품", systemUse: true, active: true },
      { categoryCode: "05", categoryName: "상품", systemUse: true, active: true },
      { categoryCode: "07", categoryName: "추가구분", systemUse: false, active: true },
      { categoryCode: "22", categoryName: "상품", systemUse: false, active: true },
      { categoryCode: "33", categoryName: "재공품", systemUse: false, active: true },
      { categoryCode: "44", categoryName: "테스트", systemUse: false, active: true },
      { categoryCode: "44444", categoryName: "원자재", systemUse: false, active: false },
    ],
    CMM002: [
      { categoryCode: "1", categoryName: "adqqww", systemUse: false, active: true },
      { categoryCode: "10", categoryName: "구매", systemUse: true, active: true },
      { categoryCode: "20", categoryName: "생산", systemUse: true, active: true },
      { categoryCode: "30", categoryName: "외주", systemUse: true, active: true },
      { categoryCode: "40", categoryName: "테스트", systemUse: false, active: true },
      { categoryCode: "sds", categoryName: "asdas", systemUse: false, active: true },
    ],
    CMM003: [
      { categoryCode: "10", categoryName: "형태유형1", systemUse: false, active: true },
      { categoryCode: "11", categoryName: "aaa", systemUse: false, active: true },
    ],
    CMM004: [{ categoryCode: "10", categoryName: "대분류1", systemUse: false, active: true }],
    CMM007: [
      { categoryCode: "EA", categoryName: "EA", systemUse: false, active: true },
      { categoryCode: "TON", categoryName: "TON", systemUse: false, active: true },
      { categoryCode: "g", categoryName: "g", systemUse: false, active: true },
      { categoryCode: "kg", categoryName: "kg", systemUse: false, active: true },
    ],
    EQM010: [
      { categoryCode: "01", categoryName: "프레스", systemUse: true, active: true },
      { categoryCode: "02", categoryName: "용접기", systemUse: true, active: true },
      { categoryCode: "03", categoryName: "로봇", systemUse: false, active: true },
      { categoryCode: "09", categoryName: "추가구분", systemUse: false, active: true },
      { categoryCode: "10", categoryName: "추가구분", systemUse: false, active: true },
    ],
    EQM011: [
      { categoryCode: "01", categoryName: "아르곤", systemUse: false, active: true },
      { categoryCode: "02", categoryName: "스팟", systemUse: false, active: true },
      { categoryCode: "03", categoryName: "추가구분", systemUse: false, active: true },
    ],
  },
};

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
