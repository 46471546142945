import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { Box, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  DefaultApiRoutingBomsRoutingBomIdPutRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  RoutingBomsGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const BomByOperationUpdateWrap = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

type BomByOperationUpdateFormProps = {
  data: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
};

export const BomByOperationUpdateForm = ({ data }: BomByOperationUpdateFormProps) => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiRoutingBomsRoutingBomIdPutRequest) =>
      mutateRoutingBoms.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customAlert("Bom 수정에 성공하였습니다.", "수정 성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "Bom 수정에 실패하였습니다.",
          "수정 실패",
          "red",
        );
      },
    },
  );

  const form = useForm<RoutingBomsGetRequest>({
    initialValues: {
      itemCode: data.itemCode as string,
      seq: data.seq,
      quantityNumerator: data.quantityNumerator,
      description: data.description as string,
      routingCode: data.routingCode as string,
    },
    validate: {
      itemCode: (value) => {
        if (!value) {
          return "아이템 코드를 입력해주세요";
        }
      },
      seq: (value) => {
        if (!value) {
          return "시퀀스를 입력해주세요";
        }
      },
      quantityNumerator: (value) => {
        if (!value) {
          return "수량을 입력해주세요";
        }
      },
    },
  });

  const onSubmit = (values: RoutingBomsGetRequest) => {
    updateMutate(
      {
        routingBomId: data.id as number,
        routingBomsGetRequest: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["items"]);
          closeModal({});
        },
      },
    );
  };

  return (
    <Box>
      <form
        style={{
          width: "100%",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
        }}
        onSubmit={form.onSubmit((values) => onSubmit(values))}
      >
        <BomByOperationUpdateWrap.Input label="시퀀스" {...form.getInputProps("seq")} />
        <BomByOperationUpdateWrap.ItemAutoComplete
          getItemType={[
            ItemsGet200ResponseRowsInnerItemTypeEnum.MATERIAL,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SUBSIDIARY,
          ]}
          label="아이템 이름"
          {...form.getInputProps("itemCode")}
        />
        <BomByOperationUpdateWrap.Input
          label="비율 (%)"
          {...form.getInputProps("quantityNumerator")}
        />
        {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && (
          <BomByOperationUpdateWrap.Input label="비고" {...form.getInputProps("description")} />
        )}
        <Group position="right" mt="md">
          <BomByOperationUpdateWrap.Button type="submit" color="yellow">
            {"수정"}
          </BomByOperationUpdateWrap.Button>
        </Group>
      </form>
    </Box>
  );
};
