import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { consumeTypeColor } from "@/constants/routings";
import { ItemAutoComplete } from "@/custom/features/ui/autoComplete/item/item-autoComplete";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import styled from "@emotion/styled";
import { Badge, Button, Flex, Input, Table, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  DefaultApiRoutingBomsPostRequest,
  DefaultApiRoutingBomsRoutingBomIdDeleteRequest,
  DefaultApiRoutingBomsRoutingBomIdPutRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  ItemsGet200ResponseRowsInnerRoutingsInner,
  ItemsGetRequestItemTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconMinus, IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface RoutingBomRowProps {
  bomRow: ItemsGet200ResponseRowsInnerRoutingsInner;
  rowIndex: number;
  bomNumerator: number;
  setBomNumerator: (numerator: number) => void;
}

export const RoutingBomRow = ({
  bomRow,
  rowIndex,
  bomNumerator,
  setBomNumerator,
}: RoutingBomRowProps) => {
  const { t } = useTranslation();
  const [opened, { toggle }] = useDisclosure(false);
  const { closeModal } = useModal();

  const [bomRows, setBomRows] = useState({
    ...bomRow,
    routingBoms: bomRow.routingBoms?.map((bom) => ({
      ...bom,
      itemWithGrade: {
        itemType: bom.itemWithGrade?.itemType,
        gradeName: bom.itemWithGrade?.gradeName,
        itemCategoryName: bom.itemWithGrade?.itemCategoryName,
        abstractItemCode: bom.itemWithGrade?.abstractItemCode,
      },
      description: bom.description,
      quantityNumerator: bom.quantityNumerator,
    })),
  });

  const queryClient = useQueryClient();

  const isPreview = (bomRow as ItemsGet200ResponseRowsInnerRoutingsInner)?.id === undefined;

  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiRoutingBomsRoutingBomIdDeleteRequest) =>
      mutateRoutingBoms.delete(params).mutationFn(undefined),
    {
      onSuccess: (_, { routingBomId }) => {
        // 상태 업데이트: 삭제된 항목 제거
        setBomRows((prev) => ({
          ...prev,
          routingBoms: prev.routingBoms?.filter((bom) => bom.id !== routingBomId),
        }));

        queryClient.invalidateQueries(["items"]);
        queryClient.invalidateQueries(["routungs"]);
        queryClient.invalidateQueries(["routingBoms"]);
        customAlert("삭제되었습니다.", "성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "Bom 삭제에 실패하였습니다.",
          "등록 실패",
          "red",
        );
      },
    },
  );

  const { mutateAsync: updateMutateAsync } = useMutation(
    (params: DefaultApiRoutingBomsRoutingBomIdPutRequest) =>
      mutateRoutingBoms.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["items"]);
        queryClient.invalidateQueries(["routungs"]);
        queryClient.invalidateQueries(["routingBoms"]);
        customAlert("Bom 수정에 성공하였습니다.", "성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "Bom 수정에 실패하였습니다.",
          "등록 실패",
          "red",
        );
      },
    },
  );

  const { mutateAsync: createMutateAsync } = useMutation(
    (params: DefaultApiRoutingBomsPostRequest) =>
      mutateRoutingBoms.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["items"]);
        queryClient.invalidateQueries(["routungs"]);
        queryClient.invalidateQueries(["routingBoms"]);
        customAlert("새로운 BOM 생성에 성공하였습니다.", "성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "새로운 BOM 생성에 실패하였습니다.",
          "등록 실패",
          "red",
        );
      },
    },
  );

  const handleUpdateBom = async () => {
    if (!bomRow.routingBoms) return;

    try {
      const newBomRows = [...(bomRows.routingBoms ?? [])];
      // ID 없는 경우 생성
      const createPromises = bomRows.routingBoms
        ?.filter((bom) => !bom.id)
        .map((bom) =>
          createMutateAsync({
            routingBomsGetRequest: {
              routingCode: bomRows.code as string,
              itemCode: bom.itemCode as string,
              quantityNumerator: bom.quantityNumerator as string,
              seq: bom.seq as number,
              description: bom.description as string,
            },
          }),
        );

      // ID 있는 경우 업데이트
      const updatePromises = bomRows.routingBoms
        ?.filter((bom) => bom.id)
        .map((bom) =>
          updateMutateAsync({
            routingBomId: bom.id as number,
            routingBomsGetRequest: {
              routingCode: bomRows.code as string,
              itemCode: bom.itemCode as string,
              quantityNumerator: bom.quantityNumerator as string,
              seq: bom.seq as number,
              description: bom.description as string,
            },
          }),
        );

      setBomRows((prev) => ({
        ...prev,
        routingBoms: newBomRows,
      }));
      // 모든 요청 완료 대기
      await Promise.all([...(createPromises ?? []), ...(updatePromises ?? [])]);

      customAlert("모든 BOM 업데이트가 성공적으로 처리되었습니다.", "성공", "green");
      closeModal(null);
    } catch (error) {
      customAlert("BOM 업데이트 중 오류가 발생했습니다.", "실패", "red");
    }
  };

  const handleAddNewBom = () => {
    setBomRows((prev) => ({
      ...prev, // 기존 상태 유지
      routingBoms: [
        ...(prev.routingBoms ?? []), // 기존 배열 복사
        {
          seq: (prev.routingBoms?.length ?? 0) + 1,
          itemCode: "",
          itemType: "",
          itemWithGrade: {
            itemType: "",
            gradeName: "",
            itemCategoryName: "",
            abstractItemCode: "",
          },
          description: "",
          quantityNumerator: "0",
          id: undefined,
        },
      ],
    }));
  };

  useEffect(() => {
    setBomNumerator(
      bomRows.routingBoms?.reduce((acc, bom) => acc + Number(bom.quantityNumerator || 0), 0) || 0,
    );
  }, [bomRows.routingBoms, bomRows, bomRow]);

  const handleDeleteBom = ({ routingBomId }: { routingBomId: number | undefined }) => {
    setBomRows((prev) => ({
      ...prev,
      routingBoms: prev.routingBoms?.filter((row) =>
        routingBomId !== undefined ? row.id !== routingBomId : row.id !== undefined,
      ),
    }));

    // routingBomId가 정의되어 있을 경우 API 호출
    if (routingBomId !== undefined) {
      deleteMutate({
        routingBomId: routingBomId,
      });
    }
  };

  const getItemTypeFromBom = (itemType: any) => {
    if (Array.isArray(itemType)) {
      return itemType.map((item) => item as ItemsGet200ResponseRowsInnerItemTypeEnum);
    }
    if (itemType) {
      return [itemType as ItemsGet200ResponseRowsInnerItemTypeEnum];
    }
    return null;
  };

  return (
    <Fragment>
      <tr key={bomRow.code ?? "" + rowIndex}>
        <td>
          <Flex justify="center" align="center">
            {bomRow.routingBoms && bomRow.routingBoms.length && !opened ? (
              <IconPlus onClick={toggle} />
            ) : (
              <IconMinus onClick={toggle} />
            )}
          </Flex>
        </td>
        <td>
          <Text ta="end">{bomRow.seq}</Text>
        </td>
        <td>{bomRow.operationCode}</td>
        <td>
          <Badge color={consumeTypeColor[bomRow.consumeType!]}>{t(bomRow.consumeType ?? "")}</Badge>
        </td>
        <td>
          <Button
            fz="sm"
            color="yellow"
            rightIcon={<IconEdit />}
            onClick={handleUpdateBom}
            disabled={bomNumerator !== 100}
          >
            수정하기
          </Button>
        </td>
      </tr>
      {bomRows.routingBoms && opened ? (
        <tr style={{ backgroundColor: "#f3f0ff" }}>
          <td></td>
          <td colSpan={6}>
            <Table withColumnBorders>
              <thead>
                <tr>
                  <Th width={5}>시퀀스</Th>
                  <Th width={15}>품목명</Th>
                  <Th width={10}>비율 (%)</Th>
                  <Th width={10}>비고</Th>
                  {!isPreview && <Th width={5}>액션</Th>}
                </tr>
              </thead>
              <tbody>
                {bomRows.routingBoms &&
                  bomRows.routingBoms.map((bom, i) => {
                    return (
                      <tr key={bom.itemCode ?? "" + i}>
                        <td>
                          <Input
                            type="number"
                            defaultValue={bom.seq}
                            onChange={(e) => {
                              const updatedSeq = Number(e.currentTarget.value);
                              // 상태 업데이트
                              setBomRows((prev) => {
                                const updatedBoms = [...(prev.routingBoms || [])];
                                updatedBoms[i] = {
                                  ...updatedBoms[i],
                                  seq: updatedSeq,
                                };
                                return { ...prev, routingBoms: updatedBoms };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <ItemAutoComplete
                            defaultValue={bom.itemCode}
                            getItemType={
                              getItemTypeFromBom(bom.itemWithGrade?.itemType) ||
                              getItemTypeFromBom(bom?.itemType) || [
                                ItemsGetRequestItemTypeEnum.MATERIAL,
                                ItemsGetRequestItemTypeEnum.SEMI_PRODUCT,
                                ItemsGetRequestItemTypeEnum.SUBSIDIARY,
                              ]
                            }
                            getGrade={bom.itemWithGrade?.gradeName ?? bom?.gradeName}
                            getCategory={
                              bom.itemWithGrade?.itemCategoryName ?? bom?.itemCategoryName
                            }
                            getAbstractItem={
                              bom.itemWithGrade?.abstractItemCode ?? bom?.abstractItemCode
                            }
                            onChangeItem={(item) => {
                              // 상태 업데이트
                              setBomRows((prev) => {
                                const updatedBoms = [...(prev.routingBoms || [])];
                                updatedBoms[i] = {
                                  ...updatedBoms[i],
                                  itemCode: item.value,
                                  gradeName: item.gradeName,
                                  itemCategoryName: item.category,
                                  abstractItemCode: item.abstractItem,
                                  itemType: item.type,
                                  itemWithGrade: {
                                    itemType: item.type,
                                    gradeName: item.gradeName,
                                    itemCategoryName: item.category,
                                    abstractItemCode: item.abstractItem,
                                  },
                                };
                                return { ...prev, routingBoms: updatedBoms };
                              });
                            }}
                            disabled={bom.id !== undefined}
                          />
                        </td>
                        <td>
                          <Input
                            type="text" // type="number"는 제한적이므로 text로 처리 후 검증
                            value={bom.quantityNumerator}
                            onChange={(e) => {
                              const inputValue = e.currentTarget.value;

                              // 정규식으로 소수점 1자리까지만 허용
                              if (/^\d*(\.\d{0,1})?$/.test(inputValue)) {
                                setBomRows((prev) => {
                                  const updatedBoms = [...(prev.routingBoms || [])];
                                  updatedBoms[i] = {
                                    ...updatedBoms[i],
                                    quantityNumerator: inputValue, // 실시간 검증된 값 반영
                                  };
                                  return { ...prev, routingBoms: updatedBoms };
                                });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            defaultValue={bom.description}
                            onChange={(e) => {
                              const updatedDescription = e.currentTarget.value;
                              // 상태 업데이트
                              setBomRows((prev) => {
                                const updatedBoms = [...(prev.routingBoms || [])];
                                updatedBoms[i] = {
                                  ...updatedBoms[i],
                                  description: updatedDescription,
                                };
                                return { ...prev, routingBoms: updatedBoms };
                              });
                            }}
                          />
                        </td>
                        {!isPreview && (
                          <td width={20}>
                            <Button
                              w={32}
                              variant="subtle"
                              onClick={() =>
                                handleDeleteBom({
                                  routingBomId: (
                                    bom as AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner
                                  ).id,
                                })
                              }
                              leftIcon={<IconTrash />}
                              color="red"
                            ></Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                {!isPreview && (
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center" }}>
                      <Button leftIcon={<IconPlus />} variant={"subtle"} onClick={handleAddNewBom}>
                        새로운 BOM 추가
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </td>
        </tr>
      ) : bomRow.routingBoms?.length === 0 ? (
        <tr>
          <td colSpan={8} style={{ textAlign: "center" }}>
            <Button leftIcon={<IconPlus />} variant={"subtle"} onClick={handleAddNewBom}>
              새로운 BOM 추가
            </Button>
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
