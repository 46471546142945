import { useCheckboxContext } from "@/features/workByManagement/context/CheckboxProvider";
import { theme } from "@/styles/theme";
import { Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksItemGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DefectTypeItem } from "../ui/DefectTypeSet";
import { SocketData } from "./context/WorkProvider";
import { useWorkActions } from "./hooks/useWorkActions";
import { useWorkState } from "./hooks/useWorkState";
import { WorkEquipmentCell } from "./WorkEquipmentCell";
import { WorkNumberInputCell } from "./WorkNumberInputCell";
import { WorkProgressCell } from "./WorkProgressCell";
import { WorkQuantityCell } from "./WorkQuantityCell";
import { Td, WorkTr } from "./WorkTableRow";

interface WorkOperationProps {
  rowData: WorksItemGet200ResponseRowsInner;
  socketData?: SocketData;
  socket?: any;
  defectTypes: DefectTypeItem[] | null;
  bgColor?: string;
  formReset: boolean;
  children?: React.ReactNode;
}

// 작업지시 Row의 작업 상태별 색상 정의
const workStatusColors = {
  WAITING: "",
  WORKING: theme.colors?.green?.[0],
  PAUSED: theme.colors?.orange?.[0],
  DONE: theme.colors?.gray?.[4],
};

const defaultWorkStatusColor = theme.colors?.gray?.[4];

export const WorkOperationTableRow = ({
  rowData,
  socketData,
  socket,
  defectTypes,
  formReset,
  children,
}: WorkOperationProps) => {
  const state = useWorkState();
  const actions = useWorkActions();
  const { areAllChecked } = useCheckboxContext();

  const trackingStatus = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)
    .trackingStatus;
  const lastWorkDate = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate
    ? dayjs((rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate).format(
        "YYYY-MM-DD",
      )
    : "";
  const [opened, { toggle, open }] = useDisclosure(false);

  const [socketCounter, setSocketCounter] = useState(
    socketData?.activeWorkId === rowData.id ? socketData?.equipmentCounter : null,
  );
  useEffect(() => {
    if (socketData?.activeWorkId === rowData.id) {
      setSocketCounter(socketData?.equipmentCounter);
    }
  }, [socketData, rowData.id, socketCounter]);

  const [localSocketCounter, setLocalSocketCounter] = useState(socketCounter);

  //다른 방법이 있지 않을까..?
  useEffect(() => {
    areAllChecked() && open();
  }, [areAllChecked()]);

  useEffect(() => {
    setLocalSocketCounter(socketCounter);
    handleQuantityChange({
      key: rowData.id?.toString() ?? "",
      value: socketCounter ?? rowData?.summary?.end ?? "0",
    });
  }, [socketCounter]);

  const handleQuantityChange = ({ key, value }: { key: string; value: string }) => {
    const data = state.tempQuantity.find(
      (quantity) => quantity.key === (rowData.id && rowData?.id.toString()),
    );
    if (data) {
      data.value = value;
    } else {
      state.tempQuantity.push({ key, value });
    }
    actions.setTempQuantity(state.tempQuantity);
  };

  const handleDefectChange = ({ key, value }: { key: string; value: string }) => {
    const workData = state.tempDefects.find(
      (defect) => defect.id === (rowData.id && rowData?.id.toString()),
    );
    const data = workData?.defect.find((defect) => defect.key === key);
    if (data) {
      data.value = value;
    } else {
      workData
        ? workData.defect.push({ key, value })
        : state.tempDefects.push({
            id: rowData.id ? rowData.id.toString() : undefined,
            defect: [{ key, value }],
          });
    }
    actions.setTempDefects(state.tempDefects);
  };
  return (
    <>
      <WorkTr
        color={
          rowData.id && trackingStatus ? workStatusColors[trackingStatus] : defaultWorkStatusColor
        }
      >
        <Td width={3}>
          <Text ta="center">
            {!opened && (
              <IconPlus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
            {opened && (
              <IconMinus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
          </Text>
        </Td>
        <Td minWidth={5} width={8}>
          <Text ta="right">{rowData.scheduleSeq}</Text>
        </Td>
        <Td minWidth={8} width={16}>
          <Text>{lastWorkDate}</Text>
        </Td>
        <Td minWidth={2} width={6}>
          <Text>{rowData?.id}</Text>
        </Td>
        <Td minWidth={3} width={8}>
          <Text>{rowData?.routingOutsourceId ? "사외외주실적" : ""}</Text>
        </Td>
        <Td minWidth={12} width={20}>
          <WorkEquipmentCell
            data={{ equipmentCode: rowData.equipmentCode, equipmentName: rowData.equipmentName }}
          />
        </Td>
        <Td minWidth={22} width={31}>
          <WorkProgressCell
            data={{
              itemName: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                ?.name as string,
              itemCode: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                ?.code as string,
              itemId: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                ?.id as number,
              itemSpec: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                ?.name as string,
              workId: rowData.id,
              routingCode: rowData.routingCode,
              percent: rowData.summary?.percent ?? "0",
              trackingStatus: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)
                ?.trackingStatus,
              purchaseOrderItem: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)
                ?.purchaseOrderItem,
            }}
          />
        </Td>
        <Td minWidth={18} width={31}>
          <WorkQuantityCell
            data={{
              targetQuantity: rowData.targetQuantity,
              totalQuantity: rowData.summary?.totalQuantity,
              todoQuantity: rowData.summary?.todoQuantity,
              defectTotal: rowData.summary?.defectTotal,
              unitText: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                ?.unitText as string,
            }}
          />
        </Td>
        <Td minWidth={8} width={14}>
          {rowData?.id ? (
            <WorkNumberInputCell
              data={{
                key: rowData.id.toString(),
                defaultValue: rowData?.summary?.end ?? "0",
                value:
                  localSocketCounter !== undefined && localSocketCounter !== null
                    ? localSocketCounter
                    : (state.tempQuantity.find((data) => data.key === rowData.id?.toString())
                        ?.value ??
                      rowData?.summary?.end ??
                      "0"),
                onChange: handleQuantityChange,
                formReset: formReset ?? false,
              }}
            />
          ) : null}
        </Td>
        {defectTypes?.map((defectType: DefectTypeItem) => (
          <Td key={defectType.value} minWidth={8} width={14}>
            {rowData?.id ? (
              <WorkNumberInputCell
                data={{
                  key: defectType.value,
                  defaultValue:
                    (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "0",
                  value:
                    (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "",
                  onChange: handleDefectChange,
                  formReset: formReset ?? false,
                }}
              />
            ) : null}
          </Td>
        ))}
      </WorkTr>
      {opened && children}
    </>
  );
};
