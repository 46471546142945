import { InvoiceStatementDetailForm } from "@/features/invoiceStatement/detail/detailForm";
import { InvoiceSearchBox } from "@/features/invoiceStatement/invoiceSearchBox";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { invoiceTypeColor, setInvoiceStatus } from "@/utils/invoiceStatus";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import {
  InvoicesGet200Response,
  InvoicesGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus } from "@tabler/icons-react";
import { Column, SelectColumn } from "react-data-grid";

export const InvoiceStatementCompletePage = () => {
  const { openModal } = useModal();

  const columns: readonly Column<InvoicesGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
    },
    {
      key: "id",
      name: "거래명세서 ID",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Flex h="100%" justify="flex-end" align="center">
            <LinkAnchor
              onClick={() =>
                openModal(
                  <InvoiceStatementDetailForm invoiceId={formatterProps.row.id} />,
                  null,
                  "거래명세서 상세",
                )
              }
            >
              {formatterProps.row.id}
            </LinkAnchor>
          </Flex>
        );
      },
    },
    {
      key: "code",
      name: "거래명세서 코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "invoiceType",
      name: "거래명세서 타입",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <Badge
            variant="filled"
            color={invoiceTypeColor[row.invoiceType as keyof typeof invoiceTypeColor]}
          >
            {setInvoiceStatus(row.invoiceType ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "status",
      name: "거래명세서 상태",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Badge
            variant="filled"
            color={
              purchaseOrderStatusColor[
                formatterProps.row.status as keyof typeof purchaseOrderStatusColor
              ]
            }
          >
            {setPurchaseOrderStatus(formatterProps.row.status ?? "")}
          </Badge>
        );
      },
    },
    {
      key: "numberOfInvoiceItems",
      name: "거래명세서 갯수",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const numberOfInvoiceItems = row?.numberOfInvoiceItems ?? 0;
        return <Flex justify="flex-end">{numberOfInvoiceItems}</Flex>;
      },
    },
    {
      key: "locationName",
      name: "출고 로케이션 명",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps?.row?.locationCode} />,
                null,
                "로케이션 상세",
              )
            }
          >
            {formatterProps.row.locationName}
          </LinkAnchor>
        );
      },
    },
    {
      key: "invoiceDate",
      name: "거래명세서일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.invoiceDate !== null
              ? timeUtil(formatterProps.row.invoiceDate as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      name: "접수일",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <div>
            {formatterProps.row.createdAt !== null
              ? DatetimeUtil(formatterProps.row.createdAt as string)
              : ""}
          </div>
        );
      },
    },
    {
      key: "creatorUserName",
      name: "작성자",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <UsersDetailForm UserCode={formatterProps.row?.creatorUserCode} />,
                null,
                "사용자 상세",
              )
            }
          >
            {formatterProps.row.creatorUserName}
          </LinkAnchor>
        );
      },
    },
  ];

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column" mt="sm">
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          거래명세서 완료
        </Text>
        <Text fz="xs">고객으로부터의 주문한 내역</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <InvoiceSearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<InvoicesGet200Response, InvoicesGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<InvoicesGet200Response> />
      </Flex>
    </Flex>
  );
};
