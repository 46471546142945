import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemsUpdateForm } from "@/features/item/components/form/ItemsUpdateForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import customAlert from "@/features/ui/alert/alert";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { ActionIcon, Avatar, Badge, Checkbox, Flex, Text, Tooltip } from "@mantine/core";
import {
  ItemsGet200Response,
  ItemsGet200ResponseRowsInner,
  MasterApiItemsItemIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { ActionHeader } from "./StandardInfoBestPracticeActionHeader";
import { Filter } from "./StandardInfoBestPracticeFilter";
import { LinkAnchor } from "./StandardInfoBestPracticeLinkAnchor";
import { StandardInfoBestPracticePaginationBox } from "./StandardInfoBestPracticePaginationBox";
import { mutateItems } from "./StandardInfoBestPracticeQuery";
import { SearchBox } from "./StandardInfoBestPracticeSearchBox";
import { Sorter } from "./StandardInfoBestPracticeSorter";
import { StandardInfoBestPracticeTable } from "./StandardInfoBestPracticeTable";

export const StandardInfoBestPracticePage = () => {
  const { openModal } = useModal();
  const { selectedRows } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation((params: MasterApiItemsItemIdDeleteRequest) =>
    mutateItems.delete(params).mutationFn(params as MasterApiItemsItemIdDeleteRequest | any),
  );

  const columns: readonly Column<ItemsGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "name",
        name: "품목명",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(<ItemsDetailForm itemCode={formatterProps?.row.code} />, null, "품목 상세");
          };

          return <LinkAnchor onClick={detailItemView}>{formatterProps.row.name}</LinkAnchor>;
        },
      },
      {
        key: "itemType",
        name: "품목군",
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <Badge size="lg" color={itemTypeColor[row.itemType]}>
              {t(row.itemType)}
            </Badge>
          );
        },
      },
      {
        key: "code",
        name: "Grade",
        sortable: true,
        resizable: true,
      },
      {
        key: "itemCategoryName",
        name: "Color",
        width: 80,
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() => {
                openModal(
                  <ItemCategoryDetailForm ItemCategoryName={formatterProps.row.itemCategoryName} />,
                  null,
                  "",
                );
              }}
            >
              {formatterProps.row.itemCategoryName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "abstractItemCode",
        name: "형태",
        width: 80,
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() => {
                openModal(
                  <AbstractItemDetailForm AbstractItemCode={formatterProps.row.abstractItemCode} />,
                  null,
                  "",
                  true,
                );
              }}
            >
              {formatterProps.row.abstractItemCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "spec",
        name: "규격",
        width: 80,
        sortable: true,
        resizable: true,
      },
      {
        key: "height",
        name: "높이",
        width: 80,
        sortable: true,
        resizable: true,
        cellClass: css`
          justify-content: flex-end;
        `,
      },
      {
        key: "depth",
        name: "깊이",
        width: 80,
        sortable: true,
        resizable: true,
        cellClass: css`
          justify-content: flex-end;
        `,
      },
      {
        key: "width",
        name: "폭",
        width: 80,
        sortable: true,
        resizable: true,
        cellClass: css`
          justify-content: flex-end;
        `,
      },
      {
        key: "texture",
        name: "재질",
        width: 80,
        sortable: true,
        resizable: true,
      },
      {
        key: "standardUph",
        name: "기준 UPH",
        width: 80,
        sortable: true,
        resizable: true,
      },
      {
        key: "unitText",
        name: "단위",
        width: 80,
        sortable: true,
        resizable: true,
      },
      {
        key: "lot",
        name: "LOT 관리 기준",
        width: 200,
        formatter: (row) => {
          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <Checkbox
                value="usesLotName"
                label="이름"
                mr={12}
                disabled
                checked={row?.row.usesLotName}
              />
              <Checkbox
                value="useLotExpiration"
                label="유효기한"
                disabled
                checked={row?.row.usesLotExpiration}
              />
            </Flex>
          );
        },
      },
      {
        key: "options",
        name: "액션",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const detailItemView = () => {
            openModal(<ItemsDetailForm itemCode={formatterProps?.row.code} />, null, "품목 상세");
          };

          const updateItemActionView = async () => {
            openModal(
              <ItemsUpdateForm formatterProps={formatterProps?.row} />,
              null,
              "품목 수정",
              true,
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={detailItemView} color="blue">
                <IconSearch />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={updateItemActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                variant="subtle"
                onClick={() => {
                  window.confirm("정말로 삭제하시겠습니까?");
                  deleteMutate(
                    {
                      itemId: formatterProps.row.id as number,
                    },
                    {
                      onSuccess: (res) => {
                        if (res.status === 200) {
                          customAlert("품목 삭제에 성공하였습니다.", "삭제 성공", "green");
                        } else {
                          customAlert("품목 삭제에 실패하였습니다.", "삭제 실패", "red");
                        }
                      },
                    },
                  );
                }}
                color="red"
              >
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [openModal],
  ); // openModal이 외부에 정의된 함수일 경우 의존성에 추가

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 품목을 선택해주세요.", "삭제 실패", "red");
      return;
    }
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      [...selectedRows].forEach((row) => {
        deleteMutate({ itemId: row });
      });

      customAlert("품목 삭제에 성공하였습니다.", "삭제 성공", "green");

      selectedRows.clear();
    }
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          품목 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 원/부자재, 제품, 반제품 등에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardInfoBestPracticeTableWrapper>
          <StandardInfoBestPracticeTable<ItemsGet200Response, ItemsGet200ResponseRowsInner>
            columns={columns}
          />
        </StandardInfoBestPracticeTableWrapper>
        <StandardInfoBestPracticePaginationBox<ItemsGet200Response> />
      </Flex>
    </Flex>
  );
};

const flexGrowable = css`
  flex: 1 1 auto;
`;

const flexStable = css`
  flex: 0 0 auto;
`;

const StandardInfoBestPracticeTableWrapper = styled(Flex)`
  & *[role="grid"] {
    width: 100%;
    height: 100%;
  }
`;
