import { Flex, MultiSelect, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useMemo, useState } from "react";

import { FKInfoLabel } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import { useUsersMultiAutoComplete, useUsersMultiCode } from "./multiAuto-useGetUsersQuery";

interface UserMultiProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface UsersMultiAutoCompleteProps
  extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value: string[];
  onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
  maxDropdownHeight?: number;
  width?: string;
}

export const UsersMultiAutoComplete = (params: UsersMultiAutoCompleteProps) => {
  const { value: users, onChange, maxDropdownHeight, width, ...etcParams } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useUsersMultiAutoComplete(focused, searchKeyword);
  const { data: initialOptions } = useUsersMultiCode(!!searchKeyword, searchKeyword ?? null);

  const onChangeHandler = (e: string[]) => {
    onChange && onChange(e);
  };

  const SelectUser = forwardRef<HTMLDivElement, UserMultiProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(사용자명: {code})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  // data prop에 들어가는 중복 제거된 data를 useMemo로 감싸기
  const filteredOptions = useMemo(() => {
    const combinedOptions = [
      ...(options ?? []),
      ...(initialOptions ?? []),
      ...(users.map((value) => ({ label: value, value })) ?? []),
    ];

    return combinedOptions.reduce(
      (unique: UserMultiProps[], option: UserMultiProps) =>
        unique.some((u) => u.value === option.value) ? unique : [...unique, option],
      [],
    );
  }, [options, initialOptions, users]);

  return (
    <MultiSelect
      clearable
      value={users}
      data={filteredOptions}
      onChange={onChangeHandler}
      searchable
      itemComponent={SelectUser}
      searchValue={searchKeyword}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onSearchChange={setSearchKeyword}
      nothingFound="No Options"
      rightSection={UserInfo({
        User: users,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const UserInfo = (params: { User?: string[]; onChange: (e: string[]) => void }) => {
  const { User, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange([]);
  };

  return User?.length ? (
    <FKInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />
    </FKInfoLabel>
  ) : null;
};
