import { useModal } from "@/features/modal/ModalStackManager";
import PMV2PressCapacityChart from "@/features/ui/PMS/chart/AnalysisPressChart";
import { MachineSearchBox } from "@/features/ui/PMS/machineSearchBox";
import CustomTable from "@/features/ui/table/CustomTable";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";

export const AnalysisOutputPage = () => {
  const { openModal } = useModal();

  const Header = {
    equipment: ["기계 제조사", "기계 이름", "제조 연월일", "제조 번호"],
    item: ["모델", "CODE", "품명", "SPEC"],
    production: ["제품별 일 생산 수량", "제품별 시간당 평균 생산 수량"],
    work: ["제품별 가동 시간", "제품별 비가동 시간"],
    error: ["에러 번호", "에러 명칭", "에러 발생 시간"],
    mold: ["금형명", "금형 교체 시작 시간 ~ 완료 시간"],
  };

  const BodyData = {
    equipment: [
      {
        "기계 제조사": "제조사1",
        "기계 이름": "이름1",
        "제조 연월일": "2022-01-01",
        "제조 번호": "1234",
      },
    ],
    item: [{ 모델: "전체", CODE: "-", 품명: "-", SPEC: "-" }],
    production: [{ "제품별 일 생산 수량": "100 ea", "제품별 시간당 평균 생산 수량": "10 ea" }],
    work: [{ "제품별 가동 시간": "100", "제품별 비가동 시간": "10" }],
    error: [{ "에러 번호": "1", "에러 명칭": "에러1", "에러 발생 시간": "2022-01-01" }],
    mold: [{ 금형명: "금형1", "금형 교체 시작 시간 ~ 완료 시간": "2022-01-01 ~ 2022-01-02" }],
  };

  return (
    <>
      <Flex gap="md" direction="column">
        <Flex justify="space-between">
          <Text size="xl">생산량</Text>
          <ActionIcon
            onClick={() => {
              openModal(<MachineSearchBox />, null, "기계 검색");
            }}
          >
            <IconReportSearch />
          </ActionIcon>
        </Flex>
        <CustomTable headers={Header.equipment} data={BodyData.equipment} />
        <Flex gap="md">
          <CustomTable headers={Header.item} data={BodyData.item} width="30%" />
          <PMV2PressCapacityChart />
        </Flex>
        <Flex gap="md">
          <Flex direction={"column"} gap="md">
            <CustomTable headers={Header.production} data={BodyData.production} />
            <CustomTable headers={Header.work} data={BodyData.work} />
          </Flex>
          <CustomTable headers={Header.error} data={BodyData.error} width="45%" />
          <CustomTable headers={Header.mold} data={BodyData.mold} width="25%" />
        </Flex>
      </Flex>
    </>
  );
};
