import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import timeUtil from "@/utils/timeUtil";
import { Checkbox, Space } from "@mantine/core";
import { PartnersGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

const PartnerDetail = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

export interface PartnerDetailHeaderProps {
  data: AxiosResponse<PartnersGet200Response, any> | undefined;
}

export const PartnerDetailHeader = (params: PartnerDetailHeaderProps) => {
  const { data } = params;

  const partner = data?.data?.rows && data?.data?.rows[0];
  const { openModal } = useModal();

  return (
    <>
      <PartnerDetail>
        <PartnerDetail.Title>
          {`[협력사]`} {partner?.id}
        </PartnerDetail.Title>
        <Space h={8} />
        <PartnerDetail.Field>
          <PartnerDetail.Label>{"협력사 코드"}</PartnerDetail.Label>
          <PartnerDetail.Content>{partner?.code}</PartnerDetail.Content>
        </PartnerDetail.Field>
        <PartnerDetail.Field>
          <PartnerDetail.Label>{"협력사 이름"}</PartnerDetail.Label>
          <PartnerDetail.Content>{partner?.name}</PartnerDetail.Content>
        </PartnerDetail.Field>
        <PartnerDetail.Field>
          <PartnerDetail.Label>{"협력사 전화번호"}</PartnerDetail.Label>
          <PartnerDetail.Content>{partner?.contact}</PartnerDetail.Content>
        </PartnerDetail.Field>
        <PartnerDetail.Field>
          <PartnerDetail.Label>{"협력사 타입"}</PartnerDetail.Label>
          <PartnerDetail.Content>
            <PartnerDetail.Badge>{partner?.type}</PartnerDetail.Badge>
          </PartnerDetail.Content>
        </PartnerDetail.Field>
        <PartnerDetail.Field>
          <PartnerDetail.Label>{"생성일시"}</PartnerDetail.Label>
          <PartnerDetail.Content>
            {partner?.creatorUserCode} {timeUtil(partner?.createdAt ?? "")}
          </PartnerDetail.Content>
        </PartnerDetail.Field>
        <PartnerDetail.Field>
          <PartnerDetail.Label>{"수정일시"}</PartnerDetail.Label>
          <PartnerDetail.Content>
            {partner?.updaterUserCode} {partner?.updatedAt ? timeUtil(partner?.updatedAt) : ""}
          </PartnerDetail.Content>
        </PartnerDetail.Field>
      </PartnerDetail>
    </>
  );
};
