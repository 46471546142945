import { useQuery } from "@tanstack/react-query";

import DefaultInstance from "@/instance/axios";
import {
  ItemGradeViewGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

type FormValues = {
  itemType: string[];
  gradeName: string;
  itemCategoryCode: string;
  abstractItemCode: string;
};

export type ItemOption = {
  value: string;
  label: string;
  gradeName: string;
  category: string;
  abstractItem: string;
  type: string;
  group: string;
};
const getAutoCompleteKeyword = (
  keyword: string,
  formValues: FormValues,
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[],
) => {
  // MasterInstance.itemsFindPost(
  return DefaultInstance.itemGradeViewFindPost(
    {
      query: JSON.stringify({
        $and: [
          formValues.itemType &&
          formValues.itemType.length &&
          formValues.itemType.some((type) => type !== "")
            ? {
                itemType: {
                  $in: formValues.itemType.filter((type) => type !== ""),
                },
              }
            : null,
          formValues.gradeName && formValues.gradeName !== ""
            ? {
                gradeName: {
                  $like: `%${formValues.gradeName}%`,
                },
              }
            : null,
          formValues.itemCategoryCode && formValues.itemCategoryCode !== ""
            ? {
                itemCategoryName: {
                  $like: `%${formValues.itemCategoryCode}%`,
                },
              }
            : null,
          formValues.abstractItemCode && formValues.abstractItemCode !== ""
            ? {
                abstractItemCode: {
                  $like: `%${formValues.abstractItemCode}%`,
                },
              }
            : null,
        ].filter(Boolean),
      }),
      search: keyword,
      searchFields: ["name", "code"],
      populate: ["itemCategory"],
    },
    {
      params: {
        ...(!keyword && { limit: 50 }),
        // query: {
        //   itemType: getItemType
        // },
      },
    },
  ).then((res: AxiosResponse<ItemGradeViewGet200ResponseRowsInner[]>) => res.data);
};

export const useItemsAutoComplete = (
  focus = false,
  keyword = "",
  formValues: FormValues,
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[],
  options?: {
    onSuccess: (data: ItemOption[]) => void;
  },
) => {
  const { t } = useTranslation();

  return useQuery(
    [
      "items-get-autocomplete-keyword",
      keyword,
      JSON.stringify(formValues),
      JSON.stringify(getItemType),
    ],
    () => getAutoCompleteKeyword(keyword, formValues, getItemType),
    {
      enabled: focus,
      select: (data: ItemGradeViewGet200ResponseRowsInner[]) => {
        return (data?.map((item) => {
          return {
            value: item.code,
            label: `품명: ${item.name}, Grade: ${item.gradeName ?? "x"}, Color: ${item.itemCategoryName ?? "x"}, 형태: ${item.abstractItemCode ?? "x"}`,
            spec: item.spec,
            gradeName: item.gradeName ?? "",
            category: item.itemCategoryName ?? "",
            abstractItem: item.abstractItemCode ?? "",
            type: item.itemType,
            group: t(item.itemType),
          };
        }) ?? []) as ItemOption[];
      },
      onSuccess: options?.onSuccess,
    },
  );
};

const getItemByCode = (code: string | null) =>
  DefaultInstance.itemGradeViewFindPost(
    {
      ...(code && { search: code }),
      searchFields: ["code"],
    },
    {
      ...(!code && { limit: 50 }),
      params: {
        query: {},
      },
    },
  ).then((res: AxiosResponse<ItemGradeViewGet200ResponseRowsInner[]>) => res.data);

export const useItemsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: ItemOption[]) => void;
  },
) => {
  const { t } = useTranslation();
  return useQuery(["items-get-code", code], () => getItemByCode(code), {
    enabled: enabled ?? false,
    select: (data: ItemGradeViewGet200ResponseRowsInner[]) => {
      return (data?.map((item) => {
        return {
          value: item.code,
          label: `품명: ${item.name}, Grade: ${item.gradeName ?? "x"}, Color: ${item.itemCategoryName ?? "x"}, 형태: ${item.abstractItemCode ?? "x"}`,
          spec: item.spec,
          gradeName: item.gradeName ?? "",
          category: item.itemCategoryName ?? "",
          abstractItem: item.abstractItemCode ?? "",
          type: item.itemType,
          group: t(item.itemType),
        };
      }) ?? []) as ItemOption[];
    },
    onSuccess: options?.onSuccess,
  });
};
