import { CavityForm } from "@/features/mold/cavity/form/cavityForm";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Flex, NumberInput, Text, TextInput } from "@mantine/core";

import { mutateMoldStocks } from "@/api/moldStock/useMoldStockQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldStocksPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  CavityForm: CavityForm,
});

export const MoldStockCreateForm = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiMoldStocksPostRequest) =>
      mutateMoldStocks.create(params).mutationFn(params as MasterApiMoldStocksPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldStock"]);
        closeModal(form.values);
        customAlert("금형재고 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "금형재고코드" }),
          "금형재고 생성 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      moldCode: undefined,
      limitCounter: undefined,
      inspectCounter: undefined,
      currentCounter: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate({
        moldStocksGetRequest: {
          code: form.values.code!,
          moldCode: form.values.moldCode!,
          limitCounter: form.values.limitCounter!,
          inspectCounter: form.values.inspectCounter!,
          currentCounter: form.values.currentCounter!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "금형재고코드" }),
        "금형재고 생성 실패",
        "red",
      );
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input label="금형 재고 코드" withAsterisk {...form.getInputProps("code")} />
      <MoldAutoComplete label="금형코드" withAsterisk {...form.getInputProps("moldCode")} />
      <Create.NumberInput label="최대 타수" {...form.getInputProps("limitCounter")} />
      <Create.NumberInput label="검사 타수" {...form.getInputProps("inspectCounter")} />
      <Create.NumberInput label="현재 타수" {...form.getInputProps("currentCounter")} />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          disabled={validateFormValues({
            pageType: "moldStock",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          금형재고 생성
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
