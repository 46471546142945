import {
  MRT_PaginationState,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo, useState } from "react";

import { equipments } from "@/api/equipment/useEquipmentQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingOutsourceDetailForm } from "@/features/RoutingOutsource/form/DetailForm";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { setToLocaleString } from "@/utils/unitMark";
import { trackingStatusColor } from "@/utils/workStatus";
import { Anchor, Badge } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DetailEquipmentProps } from "../form/EquipmentDetailForm";

export interface Row {
  id: number | any;
  productionPlanId: number | any;
  scheduledAt: string | any;
  targetQuantity: number | any;
  trackingStatus: string | any;
  item: {
    code: string | any;
    name: string | any;
    spec: string | any;
    unitText: string | any;
  };
  routingOutsourceId: number | any;
  routingOutsourceData: {
    name: string | any;
  };
  summary: {
    totalQuantity: number | any;
    defectTotal: number | any;
    todoQuantity: number | any;
  };
}

type TrackingStatus = "WAITING" | "WORKING" | "PAUSED" | "DONE" | "DEFAULT";

export const EquipmentWorksTabs = (
  { equipmentCode }: DetailEquipmentProps,
  trackingStatus: TrackingStatus,
) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });

  const { data: equipmentData } = useQuery(
    equipments.get({
      query: {
        $and: [{ code: { $eq: equipmentCode } }],
      },
      populate: ["location", "works"],
      pageSize: pagination.pageSize,
      page: pagination.pageIndex,
    }),
  );

  const rows: Row[] | AuthSignupPost201ResponseEquipmentWorksInner[] =
    (equipmentData?.data.rows && equipmentData?.data.rows[0]?.works) ?? [];

  const { openModal } = useModal();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorFn: (row) => row.id,
        accessorKey: "id",
        header: "작업번호",
        enableColumnFilter: false,
        size: 60,
        Cell: (rows) => {
          return (
            <DetailLink
              onClick={() => {
                openModal(<WorkView workId={rows.row.original.id} />, null, "");
              }}
              justify="flex-end"
            >
              {rows.row.original.id}
            </DetailLink>
          );
        },
      },
      {
        accessorFn: (row: any) => (
          <DetailLink
            onClick={() => {
              openModal(<ItemsDetailForm itemCode={row.item.code} />, null, "품목 상세");
            }}
            justify="flex-start"
          >
            {row.item.code}
          </DetailLink>
        ),
        accessorKey: "itemCode",
        header: "품목코드",
        size: 80,
      },
      {
        accessorFn: (row) => row.item.name,
        accessorKey: "itemName",
        header: "품목명",
        size: 120,
      },
      {
        accessorFn: (row) => row.item.spec,
        accessorKey: "spec",
        header: "규격",
        size: 160,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => t(row.trackingStatus),
        accessorKey: "trackingStatus",
        header: "작업상태",
        enableColumnFilter: false,
        size: 80,
        Cell: (rows) => {
          const workLogType = rows.row._valuesCache.workLogType as keyof typeof trackingStatusColor;
          return (
            <Badge color={trackingStatus ? trackingStatusColor[trackingStatus] : ""}>
              {t(rows.row._valuesCache.trackingStatus)}
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => row.targetQuantity,
        accessorKey: "targetQuantity",
        header: "계획 수량",
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          const targetQuantity =
            setToLocaleString(rows.row._valuesCache.targetQuantity) === "NaN"
              ? "0"
              : setToLocaleString(rows.row._valuesCache.targetQuantity);
          return (
            <div style={{ textAlign: "right" }}>
              {targetQuantity} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.summary.todoQuantity,
        accessorKey: "todoQuantity",
        header: "생산 잔량",
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.todoQuantity)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.summary.totalQuantity,
        accessorKey: "totalQuantity",
        header: "총 생산수량",
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.totalQuantity)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.summary.defectTotal,
        accessorKey: "defectTotal",
        header: "불량수량",
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.item.unitText;
          return (
            <div style={{ textAlign: "right" }}>
              {setToLocaleString(rows.row._valuesCache.defectTotal)} {unitText}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.routingOutsourceData?.name,
        accessorKey: "routingOutsourceData",
        header: "외주처",
        size: 80,
        Cell: (rows) => {
          return (
            <Anchor
              onClick={() =>
                openModal(
                  <RoutingOutsourceDetailForm name={rows.row.original.routingOutsourceData.name} />,
                  null,
                  "",
                )
              }
            >
              {rows.row._valuesCache.routingOutsourceData !== null
                ? rows.row.original.routingOutsourceData?.name
                : ""}
            </Anchor>
          );
        },
      },
      {
        accessorFn: (row) => dayjs(row.scheduledAt).format("YYYY-MM-DD"),
        accessorKey: "scheduledAt",
        header: "작업일",
        size: 140,
      },
    ],
    [],
  );

  return (
    <MantineReactCommonTable
      data={rows as unknown as Row[]}
      columns={columns}
      totalCount={equipmentData?.data.total}
      totalPages={equipmentData?.data.totalPages}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};
