import {
  Configuration,
  DefaultApi,
  EtcApi,
  KpiApi,
  MasterApi,
  MesApi,
  ProductionActionApi,
  QualityApi,
  ScmApi,
  SpcApi,
  UserApi,
  WmsApi,
} from "@sizlcorp/sizl-api-document/dist/models";
import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "https://dev-mes3-api.sizl.co.kr";

export const axiosInstance: any = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("authToken"),
  },
  timeout: 1000 * 60 * 60 * 24 * 7,
});

axiosInstance.interceptors.request.use((config: any) => {
  if (!config.headers) return config;
  const accessToken = localStorage.getItem("authToken");

  if (accessToken && config.headers) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (error: any) => {
    if (
      error.config &&
      error.response &&
      error.response.status === 200
      // error.response.status !== 401 &&
      // error.response.status !== 400
    ) {
      error.config._retry = true;
      const refreshToken = localStorage.getItem("authToken");
      error.config.headers.refreshToken = refreshToken;

      return axios.create({
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + refreshToken,
          "Content-Type": "application/json",
          withCredentials: true,
        },
      });
      // .then((res) => {
      //     if (res.status === 200 && res.data.refreshToken) {
      //         localStorage.setItem('authToken', res.data.accessToken)
      //         const accessToken = localStorage.getItem('authToken')
      //         error.config.headers["Authorization"] = accessToken
      //         return axiosInstance(error.config)
      //     }
      // })
    }

    return Promise.reject(error);
  },
);

const defaultAxios = (url: string) => {
  return new DefaultApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const masterAxios = (url: string) => {
  return new MasterApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const userAxios = (url: string) => {
  return new UserApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const productionAxios = (url: string) => {
  return new ProductionActionApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const spcAxios = (url: string) => {
  return new SpcApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const scmAxios = (url: string) => {
  return new ScmApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const wmsAxios = (url: string) => {
  return new WmsApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};
const kpiAxios = (url: string) => {
  return new KpiApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const qualityAxios = (url: string) => {
  return new QualityApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const mesAxios = (url: string) => {
  return new MesApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

const etcAxios = (url: string) => {
  return new EtcApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance,
  );
};

export const ProductionInstance = productionAxios(BASE_URL);
export const UserInstance = userAxios(BASE_URL);
export const DefaultInstance = defaultAxios(BASE_URL);
export const MasterInstance = masterAxios(BASE_URL);
export const SpcInstance = spcAxios(BASE_URL);
export const ScmInstance = scmAxios(BASE_URL);
export const WmsInstance = wmsAxios(BASE_URL);
export const KpiInstance = kpiAxios(BASE_URL);
export const QualityInstance = qualityAxios(BASE_URL);
export const MesInstance = mesAxios(BASE_URL);
export const EtcInstance = etcAxios(BASE_URL);

export default DefaultInstance;
