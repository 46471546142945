import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { Td } from "@/features/workByManagement/WorkTableRow";
import { theme } from "@/styles/theme";
import { setToLocaleString } from "@/utils/unitMark";
import { Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { GradeDetailForm } from "../grade/components/form/GradeDetailForm";
import { ItemCategoryDetailForm } from "../itemCategory/form/DetailForm";
import { AbstractItemDetailForm } from "../abstractItem/form/detailForm";

interface WorkProductionPlanTableRowProps {
  data: any; // TODO: 품목코드, 품목명, 목표수량
  children: React.ReactNode;
}

export const WorkProductionPlanTableRow = ({ data, children }: WorkProductionPlanTableRowProps) => {
  const {
    id,
    itemGradeName,
    itemGradeCode,
    itemCategoryName,
    itemType,
    abstractItemCode,
    itemCode,
    itemName,
    targetQuantity,
    unit,
  } = data;
  const { openModal } = useModal();
  const [opened, { toggle }] = useDisclosure(false);
  const { t } = useTranslation();

  return (
    <>
      <tr>
        <Td width={3}>
          <Text ta="center">
            {!opened && (
              <IconPlus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
            {opened && (
              <IconMinus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
          </Text>
        </Td>
        <Td width={11}>
          <DetailLink
            onClick={() => openModal(<ProductionPlanViewForm ProductionPlanId={id} />, null, "")}
            justify="flex-start"
          >
            <Tooltip label={itemName}>
              <Text c={theme.colors?.blue?.[7]} fw={700} span>
                {itemName}
              </Text>
            </Tooltip>
          </DetailLink>
        </Td>
        <Td width={11}>
          <Text fz="sm" span>
            {t(itemType ?? "")}
          </Text>
        </Td>
        <Td width={11}>
          <DetailLink
            onClick={() => {
              openModal(<GradeDetailForm gradeCode={itemGradeCode} />, null, "Grade 상세");
            }}
            justify="flex-start"
          >
            <Tooltip label={itemGradeName}>
              <Text c={theme.colors?.blue?.[7]} fz="sm" fw={700} span>
                {itemGradeName}
              </Text>
            </Tooltip>
          </DetailLink>
        </Td>
        <Td width={11}>
          <DetailLink
            onClick={() => {
              openModal(
                <ItemCategoryDetailForm ItemCategoryName={itemCategoryName} />,
                null,
                "Color 상세",
              );
            }}
            justify="flex-start"
          >
            <Tooltip label={itemCategoryName}>
              <Text c={theme.colors?.blue?.[7]} fz="sm" fw={700} span>
                {itemCategoryName}
              </Text>
            </Tooltip>
          </DetailLink>
        </Td>
        <Td width={11}>
          <DetailLink
            onClick={() => {
              openModal(
                <AbstractItemDetailForm AbstractItemCode={abstractItemCode} />,
                null,
                "형태 상세",
              );
            }}
            justify="flex-start"
          >
            <Tooltip label={abstractItemCode}>
              <Text c={theme.colors?.blue?.[7]} fz="sm" fw={700} span>
                {abstractItemCode}
              </Text>
            </Tooltip>
          </DetailLink>
        </Td>
        <Td width={23}>
          <Text fz="md" ta="right">
            {setToLocaleString(targetQuantity)} {unit}
          </Text>
        </Td>
        <Td width={32}>-</Td>
      </tr>
      {opened && children}
    </>
  );
};
