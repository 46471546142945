import { mutateLots } from "@/api/lots/useLotsQuery";
import { LotDetailForm } from "@/features/lot/form/lotDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { ActionIcon, Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  DefaultApiLotsLotIdDeleteRequest,
  ItemsGet200ResponseRowsInnerLotsInner,
  LotsGet200Response,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";

export const LotsPage = () => {
  const { openModal, closeModal } = useModal();
  const { resetQueryStrings } = useStandardLayout();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiLotsLotIdDeleteRequest) => mutateLots.delete(params).mutationFn(undefined),
    {
      onSuccess: () => {
        refetch();
        customAlert("로트 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: (error) => {
        customAlert("이미 사용된 Lot입니다.", "로트 삭제 실패", "red");
      },
    },
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 로트를 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ lotId: row.id });
          });
          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "로트 삭제",
    );
  };

  // DefaultApiLotsGetRequest
  const columns: readonly Column<ItemsGet200ResponseRowsInnerLotsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "name",
        name: "로트명",
        sortable: true,
        resizable: true,
      },
      {
        key: "itemCode",
        name: "아이템코드",
        sortable: true,
        resizable: true,
      },
      {
        key: "createdAt",
        name: "생성일",
        sortable: true,
        resizable: true,
        formatter: ({ row }) => <span>{DatetimeUtil(row.createdAt as string)}</span>,
      },
      {
        key: "updatedAt",
        name: "수정일",
        sortable: true,
        resizable: true,
        formatter: ({ row }) => <span>{DatetimeUtil(row.updatedAt as string)}</span>,
      },
      {
        key: "action",
        name: "액션",
        resizable: true,
        formatter: ({ row }) => {
          const detailLotView = () => {
            openModal(<LotDetailForm lotName={row?.name} lotId={row?.id} />, null, "");
          };

          const deleteLotAction = () => {
            deleteMutate({ lotId: row.id as number });
          };

          return (
            <Flex h="100%" justify="left" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={detailLotView} color="blue">
                <IconSearch />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={deleteLotAction} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [],
  );

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <Flex w={"100%"} gap="md" justify={"flex-end"}>
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            검색값 초기화
          </Button>
          <Button rightIcon={<IconTrash />} color="red" onClick={handleDelete}>
            선택된 행 삭제
          </Button>
        </Flex>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          Lot 리스트
        </Text>
        <Text fz="xs">우리 회사에서 다루는 Lot에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip
              label={
                '사용자 정의 필터입니다. "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을 입력합니다.'
              }
            >
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<LotsGet200Response, ItemsGet200ResponseRowsInnerLotsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<LotsGet200Response> />
      </Flex>
    </Flex>
  );
};
