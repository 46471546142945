import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { LeftSpace } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { ArrivalDeliveryStockPlanForm } from "@/features/purchaseOrderItems/form/arrivalDeliveryStockPlanForm";
import { PurchaseOrderItemsCreateForm } from "@/features/purchaseOrderItems/form/createForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button, Flex, Tabs } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PurchaseOrderDetailActionBar } from "../header/purchaseOrderDetailActionBar";
import { PurchaseOrderDetailHeader } from "../header/purchaseOrderDetailHeader";
import { PurchaseOrderDetailTable } from "../table/purchaseOrderDetailTable";
import { DeliveryForm } from "./deliveryForm";

const Detail = Object.assign({}, Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
  ActionBar: PurchaseOrderDetailActionBar,
  ActionHeader: ActionHeader,
  ActionButtonBox: ActionButtonBox,
  ActionButton: Button,
  PurchaseOrderItemsCreateForm: PurchaseOrderItemsCreateForm,
});

interface PurchaseOrderDetailFormProps {
  purchaseOrderId: number;
}

export const PurchaseOrderDetailForm = (params: PurchaseOrderDetailFormProps) => {
  const { purchaseOrderId } = params;

  const { data: purchaseData } = useQuery(
    purchaseOrders.detail({
      purchaseOrderId: purchaseOrderId,
      populate: ["location", "user", "partner", "purchaseOrderItems"],
    }),
  );
  const userCode = getUserCodeByUser();
  const { openModal } = useModal();
  const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
  const [selectedRowsInform, setSelectedRowsInform] = useState<
    ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  >([]);
  const [data, setData] = useState<any>([]);

  const handleRowSelect = (selectedRows: any, formatterPropsData: any) => {
    setIsRowBtnActive(selectedRows.size === 1);
    setData(formatterPropsData);
    setSelectedRowsInform(selectedRowsInform);
  };

  return (
    <>
      <Detail.Container>
        <Detail.ActionBar data={purchaseData?.data} />
        <Detail.Wrapper>
          <Detail.Content>
            {/* <PurchaseOrderDetailDataHeader /> */}
            <PurchaseOrderDetailHeader data={purchaseData?.data} />
            <Tabs defaultValue="purchaseOrder">
              <LeftSpace />
              <Tabs.List>
                <Tabs.Tab value="purchaseOrder">
                  {userCode === "PB" ? "발주 항목" : "수주 항목"}
                </Tabs.Tab>
                <Tabs.Tab value="history">히스토리</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="purchaseOrder">
                <Flex direction={"column"} gap="md">
                  <Detail.ActionHeader>
                    <Detail.ActionButtonBox>
                      <Detail.ActionButton
                        leftIcon={<IconPlus />}
                        onClick={() =>
                          openModal(
                            <Detail.PurchaseOrderItemsCreateForm
                              purchaseOrderId={purchaseData?.data?.id}
                            />,
                            null,
                            `${userCode === "PB" ? "발주 항목 추가" : "수주 항목 추가"}`,
                          )
                        }
                      >
                        추가
                      </Detail.ActionButton>
                      <Detail.ActionButton
                        leftIcon={<IconPlus />}
                        color="orange"
                        disabled={!isRowBtnActive}
                        onClick={() =>
                          openModal(<DeliveryForm formatterProps={data} />, null, "납품")
                        }
                      >
                        납품
                      </Detail.ActionButton>
                      <Detail.ActionButton
                        leftIcon={<IconPlus />}
                        color="orange"
                        disabled={!isRowBtnActive}
                        onClick={() =>
                          openModal(
                            <ArrivalDeliveryStockPlanForm formatterProps={data} />,
                            null,
                            "출고계획 작성",
                          )
                        }
                      >
                        출고계획 작성
                      </Detail.ActionButton>
                      <Detail.ActionButton
                        leftIcon={<IconPlus />}
                        color="orange"
                        disabled={!isRowBtnActive}
                        onClick={() =>
                          openModal(
                            <ProductionPlanCreateForm formatterProps={data} />,
                            null,
                            "생산계획 생성",
                            true,
                          )
                        }
                      >
                        생산계획 생성
                      </Detail.ActionButton>
                    </Detail.ActionButtonBox>
                  </Detail.ActionHeader>
                  <PurchaseOrderDetailTable
                    onRowSelect={handleRowSelect}
                    purchaseOrderId={purchaseOrderId}
                  />
                </Flex>
              </Tabs.Panel>
              <Tabs.Panel value="history">2</Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};
