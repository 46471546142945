import { mutateOperations } from "@/api/operations/useOperationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationsCreateForm } from "@/features/operations/components/form/OperationsCreateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { DefaultApiOperationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import customAlert from "../../alert/alert";
import {
  OperationOption,
  useOperationsAutoComplete,
  useOperationsCode,
} from "./auto-useGetOperationsQuery";

interface OperationProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface OperationAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

export const OperationAutoComplete = (params: OperationAutoCompleteProps) => {
  const { value: operationCode, onChange, maxDropdownHeight, width, ...etcParams } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useOperationsAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useOperationsCode(!!operationCode, operationCode ?? null);

  let selectedRoute = initialOptions?.find((item) => {
    return item.value === operationCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedRoute = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, OperationProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{code}</Text>
            <Text fz="xs"> (name: {name === "null" ? "-" : name})</Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  return (
    <OperationSelect
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedRoute?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedRoute?.value}
            </div>
          )}
        </div>
      )}
      value={operationCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: OperationProps[], option: OperationProps) => {
          return unique.some((u) => u.value === option.value) ? unique : [...unique, option];
        },
        [],
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={OperationInfo({
        route: selectedRoute as OperationOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewOperation({
        operationCode: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const OperationInfo = (params: {
  route?: OperationOption;
  onChange: (operationCode: string | null) => void;
}) => {
  const { route, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return route?.value ? (
    <OperationInfoLabel>
      {/* ({route.value})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </OperationInfoLabel>
  ) : null;
};

const AddNewOperation = (params: {
  operationCode: string;
  onChange: (itemCode: string) => void;
}) => {
  const { operationCode, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiOperationsPostRequest) =>
      mutateOperations.create(params).mutationFn(params as DefaultApiOperationsPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["operations"]);
        closeModal(res.data);
        customAlert("공정 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "공정코드" }),
          "공정 생성 실패",
          "red",
        );
      },
    },
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate({
      operationsGetRequest: {
        code: values.code,
        name: values.name,
        siteCode: values.siteCode,
        fromLocationCode: values.fromLocationCode,
        toLocationCode: values.toLocationCode,
      },
    });
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <OperationsCreateForm code={operationCode} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      "신규 공정 등록",
      true,
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 공정({operationCode}) 등록
    </Button>
  );
};
const OperationInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const OperationSelect = styled(Select)<{ value: string | null | undefined }>`
  width: 100%;

  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? "0.8rem !important" : "")};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
