import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { ReactNode, forwardRef, useState } from "react";
import { LotOption, useLotsSelectAutoComplate } from "./select-useGetLotsQuery";

interface LotProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  expiration: string;
  value: string;
}

export interface LotAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (lotId: string | null) => void;
  maxDropdownHeight?: number;
  itemCodeValue: string;
  newIdValue?: number;
  width?: string;
}

export const LotSelectComplete = (params: LotAutoCompleteProps) => {
  const {
    value: lotId,
    onChange,
    maxDropdownHeight,
    itemCodeValue,
    newIdValue,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);

  const { data: options } = useLotsSelectAutoComplate(focused || !!itemCodeValue, itemCodeValue);

  let selectedLot = options?.find((item) => {
    return newIdValue ? newIdValue == Number(item.value) : item.value === lotId;
  });

  const onChangeHandler = (e: string | null) => {
    selectedLot = options?.find((lot) => lot.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, LotProps>(
    ({ group, label: name, value: id, expiration, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>
              Item({name === "null" ? "-" : name},{" "}
              {timeUtil(expiration) === "NaN-NaN-NaN" ? "-" : timeUtil(expiration)})
            </Text>
          </Flex>
        </Flex>
      </div>
    ),
  );

  const selectOptions = [...(options ?? [])].reduce((unique: LotOption[], option: LotOption) => {
    return unique.some((u) => u.value === option.value) ? unique : [...unique, option];
  }, []);

  return (
    <LotSelect
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedLot?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"code: " + selectedLot?.value}
            </div>
          )}
        </div>
      )}
      withinPortal
      value={selectedLot?.value ?? ""}
      itemComponent={SelectItem}
      data={selectOptions}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      rightSection={LotInfo({
        lot: selectedLot as LotOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) => {
        return item?.value?.toLowerCase().includes(value.toLowerCase().trim());
      }}
      {...etcParams}
    />
  );
};

const LotInfo = (params: { lot?: LotOption; onChange: (lotId: string | null) => void }) => {
  const { lot, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return lot?.label ? (
    <LotInfoLabel>
      {/* ({lot.label}) */}
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </LotInfoLabel>
  ) : null;
};

const LotInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const LotSelect = styled(Select)<{ value: string | null | undefined }>`
  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? "0.8rem !important" : "0.4rem !important")};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
