import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
// import { AlignTextRight } from "@/utils/textRight";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPIRateOfOperation = Object.assign(
  {},
  {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
  },
);

export const KPIRateOfOperationPage = () => {
  const today = dayjs().subtract(1, "day").startOf("day").toDate();
  const oneWeekAgo = dayjs().subtract(7, "day").startOf("day").toDate();

  const [content, setContent] = useState<any>([]);
  const [selectList, setSelectList] = useState<Set<number>>(new Set());
  const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
  const [equipment, setEquipment] = useState<string | null>("");
  const [averageRateOfOperation, setAverageRateOfOperation] = useState<string>("");

  const { equipmentData, rateOfOperationData } = useKPIData({
    equipment,
    stateDate,
    options: { fetchRateOfOperation: true },
  });

  const TableHeader = {
    machine: ["설비명", "평균 설비가동률"],
  };

  const BodyData = {
    machine: [
      {
        설비명: equipmentData?.name ?? "-",
        "평균 설비가동률": averageRateOfOperation === "NaN" ? "-" : averageRateOfOperation,
      },
    ],
  };

  useEffect(() => {
    if (rateOfOperationData?.data) {
      const transformedData = rateOfOperationData.data.map((rateOfOperation: any) => ({
        id: rateOfOperation.id,
        productionPlanId: rateOfOperation.productionPlanId,
        operationName: rateOfOperation.operationName,
        equipmentName: rateOfOperation.equipmentName,
        itemCode: rateOfOperation.itemCode,
        itemName: rateOfOperation.itemName,
        itemSpec: rateOfOperation.itemSpec,
        itemStandardUph: rateOfOperation.itemStandardUph,
        workerName: rateOfOperation.workerName,
        workStartTime:
          rateOfOperation.workStartTime === null
            ? "-"
            : DatetimeUtilMinute(rateOfOperation.workStartTime),
        workEndTime:
          rateOfOperation.workEndTime === null
            ? "-"
            : DatetimeUtilMinute(rateOfOperation.workEndTime),
        pauseDuration: rateOfOperation.pauseDuration,
        totalQuantity: rateOfOperation.totalQuantity,
        goodQuantity: rateOfOperation.goodQuantity,
        defectQuantity: rateOfOperation.defectQuantity,
        capacityUtilization: rateOfOperation.capacityUtilization,
      }));

      const totalCapacityUtilization =
        rateOfOperationData?.data?.reduce(
          (sum: number, rateOfOperation: any) =>
            sum + parseFloat(rateOfOperation.capacityUtilization),
          0,
        ) ?? 0;
      const averageCapacityUtilization = (
        totalCapacityUtilization / rateOfOperationData.data.length
      ).toFixed(2);
      setAverageRateOfOperation(averageCapacityUtilization);
      setContent(transformedData);
    } else {
      setContent([]);
    }
  }, [rateOfOperationData]);

  return (
    <Flex gap="md" direction={"column"}>
      <KPIRateOfOperation.Header>
        <KPIRateOfOperation.HeaderTitle>설비가동률(P)</KPIRateOfOperation.HeaderTitle>
        <KPIRateOfOperation.HeaderSubTitle>
          설비가 주어진 조건 하에서 정상적으로 가동하였을 때 생산할 수 있는 최대 생산량애 대한 실제
          생산량의 비율이다.
        </KPIRateOfOperation.HeaderSubTitle>
      </KPIRateOfOperation.Header>
      <KPIRateOfOperation.KPIActionHeader
        setItem={setEquipment}
        stateDate={stateDate}
        setStateDate={setStateDate}
        equipment={true}
      />

      <KPIRateOfOperation.CustomTable headers={TableHeader.machine} data={BodyData.machine} />
      <GridTable
        headerList={[...columnlist.rateOfoperationTable]}
        row={content}
        setRow={setContent}
        selectList={selectList}
        //@ts-ignore
        setSelectList={setSelectList}
        width={"100%"}
        height={750}
        resizable={true}
      />
    </Flex>
  );
};
