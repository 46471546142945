import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import { Button, ColorSwatch, Flex, Input, Select, Text } from "@mantine/core";
import { IconCalendar, IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { SelectColumn } from "react-data-grid";

type PageType = {
  current_page: number;
  first?: true;
  last?: true;
  limit?: number;
  total_page: number;
};

export const PressDailyStatusPage = () => {
  const [keyword, setKeyword] = useState<string>();
  const [content, setContent] = useState<any>([]);
  const [selectList, setSelectList] = useState<Set<number>>(new Set());
  const [page, setPage] = useState<PageType>({
    current_page: 1,
    total_page: 1,
  });
  const { openModal } = useModal();

  const [searchPage, setSearchPage] = useState<PageType>({
    current_page: 1,
    total_page: 1,
  });
  const [opt, setOpt] = useState<number>(1);
  const [date, setDate] = useState<any>({
    type: "month",
    date: { from: `${dayjs(new Date()).format("YYYY-MM")}` },
    unit: "month",
    tableDate: "",
  });

  // const conbineData = (data) => {

  //     let covertedData: any = [];

  //     data.forEach((v, i) => {
  //         const machineData = { name: v.machine.name, mfrCode: v.machine.mfrCode, id: v.machine.machine_id }
  //         v.status.forEach((data, index) => (
  //             machineData[`check${index + 1}`] = data
  //         ))
  //         covertedData.push(machineData)
  //     })

  //     return covertedData
  // }

  // const getPressDailyStatusApi = async (pages: number = 1) => {

  //     Notiflix.Loading.circle()

  //     await axios.get(`${PRESS_DAILY_STATUS}/${pages}/18`, {
  //         params: { date: date.date.from, types: 1 },
  //         headers: httpHeaderSetting
  //     }
  //     ).then((res) => {

  //         Notiflix.Loading.remove()
  //         const { info_list, page, totalPages } = res?.data
  //         const renewalData = conbineData(info_list)
  //         if (page > 1) {
  //             setContent([...content, ...renewalData])
  //         } else {
  //             setContent(renewalData)
  //         }
  //         setPage({ current_page: page, total_page: totalPages })
  //         setSearchPage({ ...searchPage, current_page: 1 })

  //     }).catch((error) => {
  //         console.log(error.response);
  //         const errorNum: number = error?.response?.status
  //         const message: string = error?.response?.data?.message
  //         const [errorHeader, errorMessage] = ErrorList({ errorNum, message })
  //         Notiflix.Report.failure(errorHeader, errorMessage, '확인', () => history.goBack())
  //         Notiflix.Loading.remove()
  //     })
  // }

  // const getPressDailyStatusSearchApi = async (keyword: string, pages: number = 1) => {

  //     Notiflix.Loading.circle()
  //     await axios.get(`${PRESS_DAILY_STATUS_SEARCH}/${pages}/18`, {
  //         params: { date: date.date.from, keyword, opt, types: 1 },
  //         headers: httpHeaderSetting
  //     }).then((res) => {
  //         if (res?.status === 200) {
  //             Notiflix.Loading.remove()
  //             const { info_list, page, totalPages } = res?.data
  //             const renewalData = conbineData(info_list)
  //             if (pages > 1) {
  //                 setContent([...content, ...renewalData])
  //             } else {
  //                 setContent(renewalData)
  //             }
  //             setSearchPage({ current_page: page, total_page: totalPages })
  //             setPage({ ...page, current_page: 1 })
  //         }
  //     }).catch((error) => {
  //         console.log(error.response);
  //         const errorNum: number = error?.response?.status
  //         const message: string = error?.response?.data?.message
  //         const [errorHeader, errorMessage] = ErrorList({ errorNum, message })
  //         Notiflix.Report.failure(errorHeader, errorMessage, '확인', () => history.goBack())
  //         Notiflix.Loading.remove()
  //     })
  // }

  // const onChangeDate = (selectedDate: FrequentlyDateTypeAndDate) => {

  //     setDate({
  //         type: selectedDate.type,
  //         unit: selectedDate.date === '' ? selectedDate.type : date.unit,
  //         date: selectedDate.date ? selectedDate.date.from ? { from: moment(selectedDate.date.from).format('YYYY-MM') } : moment(selectedDate.date).format('YYYY-MM') : date.date,
  //         tableDate: date.tableDate
  //     })
  // }

  // const handleOnChangeSearchKeyword = (keyword: string) => {

  //     getPressDailyStatusSearchApi(keyword)
  //     setKeyword(keyword)

  // }

  // const getSelectedId = () => {
  //     let id;
  //     let name;
  //     content.forEach((value) => {
  //         if (selectList.has(value.id)) {
  //             id = value.id
  //             name = value.name
  //         }
  //     })
  //     return [id, name]
  // }

  return (
    <>
      <Flex gap="md" direction={"column"} w={"100%"}>
        <Flex align={"center"} justify={"space-between"}>
          <Text>프레스 일상점검 일일현황</Text>
          <Flex align={"center"} gap="sm">
            <Flex w={200} justify={"space-between"}>
              <ColorSwatch color={"green"} size={20} />
              <Text>완료</Text>
              <ColorSwatch color={"orange"} size={20} />
              <Text>관리자 확인요망</Text>
            </Flex>
            <Button
              leftIcon={<IconCalendar />}
              color="indigo"
              onClick={() => {
                openModal(<Calendar dates={[null, null]} type="range" />, null, "날짜 선택");
              }}
            >
              날짜선택
            </Button>
            <Select
              w={"8rem"}
              data={[
                { label: "기계 제조사", value: "all" },
                { label: "기계이름", value: "all" },
                { label: "제조번호", value: "all" },
              ]}
            />
            <Input w={"16rem"} placeholder="검색어를 입력하세요" rightSection={<IconSearch />} />
            <Button>검색</Button>
            <Button>엑셀로 받기</Button>
          </Flex>
        </Flex>
        <GridTable
          headerList={[SelectColumn, ...columnlist.pressDaily_status]}
          row={content}
          setRow={setContent}
          selectList={selectList}
          //@ts-ignore
          setSelectList={setSelectList}
          width={"100%"}
          height={750}
          // scrollEnd={(value) => {
          //     if(value){
          //         if(keyword !== undefined){
          //             if(searchPage.total_page > searchPage.current_page){
          //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
          //             }
          //         }else{
          //             if(page.total_page > page.current_page){
          //                 getPressDailyStatusApi(page.current_page+ 1)
          //             }
          //         }
          //     }
          // }}
        />
      </Flex>
    </>
  );
};
