import { mutateDefect } from "@/api/defect/useDefectsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDefectsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const DefectCreateForm = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiDefectsPostRequest) =>
      mutateDefect.create(params).mutationFn(params as DefaultApiDefectsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defects"]);
        closeModal(form.values);
        customAlert("불량코드 생성에 성공하였습니다.", "생성 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "불량코드" }),
          "불량 생성 실패",
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      name: undefined,
      operationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        defectsGetRequest: {
          code: form.values.code!,
          name: form.values.name!,
          operationCode: form.values.operationCode!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "불량코드" }),
        "불량 생성 실패",
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput withAsterisk label="불량코드" {...form.getInputProps("code")} />
          <TextInput withAsterisk label="불량명" {...form.getInputProps("name")} />
          <OperationAutoComplete label="공정코드" {...form.getInputProps("operationCode")} />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "defects",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              불량코드 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
