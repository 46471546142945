import { Button, Flex, Text } from "@mantine/core";

interface ConfirmFormProps {
  message: string;
  yesCallback: () => void;
  noCallback: () => void;
  commonCallback?: () => void;
}

export const ConfirmForm = (params: ConfirmFormProps) => {
  const { message, yesCallback, noCallback, commonCallback = () => {} } = params;

  const callback = async (callbackFn: () => void) => {
    await commonCallback();
    await callbackFn();
  };

  return (
    <Flex w="30rem" mih="6rem" direction="column" gap="sm" justify="space-between">
      <Text
        style={{ wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "pre-wrap" }}
        size="1.6rem"
      >
        {message}
      </Text>
      <Flex gap="md" justify="flex-end">
        <Button color="red" size="md" onClick={() => callback(noCallback)}>
          {"아니오"}
        </Button>
        <Button size="md" onClick={() => callback(yesCallback)}>
          {"예"}
        </Button>
      </Flex>
    </Flex>
  );
};
