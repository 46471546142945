import { items } from "@/api/items/useItemQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { mutateStockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { LotMutationFromProps } from "@/features/stockPlan/form/stockPlanIncomingForm";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import useGetLotsIdQuery from "@/features/ui/autoComplete/lot/select-useGetLotsQuery";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { LotForm } from "@/features/ui/form/lots/form";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Space, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  DefaultApiLotsPostRequest,
  WmsApiStockPlansPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventHandler, useState } from "react";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  DateInput: DateInput,
});

interface ArrivalDeliveryStockPlanUpdateFormProps {
  formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem | undefined;
  purchaseOrder?: boolean;
}

export const ArrivalDeliveryStockPlanForm = (params: ArrivalDeliveryStockPlanUpdateFormProps) => {
  const { formatterProps, purchaseOrder } = params;
  const { openModal, closeModal } = useModal();
  const [newLot, setNewLot] = useState<LotMutationFromProps>();

  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: WmsApiStockPlansPostRequest) =>
      mutateStockPlans.create(params).mutationFn(params as WmsApiStockPlansPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stockPlans"]);
        queryClient.invalidateQueries(["purchaseOrderItems"]);
        closeModal(form.values);
        customAlert(
          `${purchaseOrder ? "출고 계획에 성공하였습니다." : "입고 계획에 성공하였습니다."}`,
          `${purchaseOrder ? "출고 계획 등록 성공" : "입고 계획 등록 성공"}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: `${purchaseOrder ? "출고 계획" : "입고 계획"}`,
          }),
          `${purchaseOrder ? "출고 계획 실패" : "입고 계획 실패"}`,
          "red",
        );
      },
    },
  );

  const { mutate: postLotsMutate } = useMutation(
    (params: DefaultApiLotsPostRequest) =>
      mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lots"]);
      },
    },
  );

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      scheduledAt: formatterProps?.deliveryDeadline
        ? new Date(formatterProps.deliveryDeadline)
        : undefined,
      itemCode: formatterProps?.itemCode,
      lotId: newLot?.id ?? undefined,
      quantity: formatterProps?.quantity,
      userCode: formatterProps?.userCode,
      sourceLocationCode: formatterProps?.sourceLocationCode,
      targetLocationCode: formatterProps?.targetLocationCode,
      transportationId: undefined,
    },
  });

  const { data: itemData } = useQuery(
    items.codeSearch({
      query: form.values.itemCode ? { $and: [{ code: { $eq: form.values.itemCode } }] } : "",
    }),
  );

  const { data: lotData } = useGetLotsIdQuery(
    Number(form.values?.lotId) || Number(newLot?.id),
    !!form.values?.lotId || !!newLot?.id,
  );

  const onSubmit = async () => {
    try {
      updateMutate({
        stockPlansGetRequest: {
          scheduledAt: form.values.scheduledAt?.toDateString()!,
          itemCode: form.values.itemCode!,
          direction: formatterProps?.direction!,
          partnerCode: form.values.partnerCode!,
          lotId: newLot?.id ?? form.values.lotId!,
          quantity: form.values.quantity!,
          userCode: form.values.userCode!,
          sourceLocationCode: form.values.sourceLocationCode!,
          targetLocationCode: form.values.targetLocationCode!,
          transportationId: form.values.transportationId!,
        },
      });
    } catch (e) {
      customAlert(
        `${purchaseOrder ? "출고 계획에 실패하였습니다." : "입고 계획에 실패하였습니다."}`,
        `${purchaseOrder ? "출고 계획 등록 실패" : "입고 계획 등록 실패"}`,
        "red",
      );
    }
  };

  const onCloseHandler: EventHandler<any> = (values) => {
    postLotsMutate(
      {
        lotsGetRequest: {
          name: values.name!,
          expiration: values.expiredAt!,
          itemCode: values.itemCode!,
        },
      },
      {
        onSuccess: (data) => {
          setNewLot(data?.data as LotMutationFromProps | undefined);
          alert("로트를 생성했습니다.");
        },
      },
    );
    closeModal(values);
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <LotForm itemCodeValue={form.values.itemCode as string} onSubmit={onCloseHandler} />,
      null,
      "신규 LOT 등록",
      true,
    ).then((result) => {});
  };

  return (
    <>
      <Update.Wrapper>
        <PartnerAutoComplete
          label="협력사"
          placeholder="협력사"
          {...form.getInputProps("partnerCode")}
          defaultValue={formatterProps?.partnerCode}
          withAsterisk
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="예정일자"
          withAsterisk
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("scheduledAt")}
        />
        <ItemAutoComplete
          label="품목"
          placeholder="품목 입력"
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps?.itemCode}
        />
        <Flex justify="space-between" align="center" w="100%">
          <LotSelectComplete
            w="100%"
            label="LOT 정보"
            withAsterisk
            description={`LOT 관리 기준 : 
                        ${
                          form.values.itemCode &&
                          itemData?.data?.rows &&
                          itemData?.data?.rows[0]?.usesLotName &&
                          itemData?.data?.rows[0]?.usesLotExpiration
                            ? "이름 + 만료일"
                            : form.values.itemCode &&
                                itemData?.data?.rows &&
                                itemData?.data?.rows[0]?.usesLotName
                              ? "이름"
                              : form.values.itemCode &&
                                  itemData?.data?.rows &&
                                  itemData?.data?.rows[0]?.usesLotExpiration
                                ? "만료일"
                                : "관리 안함"
                        }`}
            itemCodeValue={(form.values.itemCode as string) || (lotData?.itemCode as string)}
            newIdValue={lotData?.id}
            dropdownPosition="bottom"
            {...form.getInputProps("lotId")}
            defaultValue={lotData && lotData?.id!.toString()}
            mr="sm"
            styles={
              lotData
                ? {
                    description: { fontSize: 10, color: "red" },
                    input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                  }
                : {
                    description: { fontSize: 10, color: "red" },
                  }
            }
            disabled={
              (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotName) ||
              (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotExpiration)
                ? false
                : true
            }
          />
          <div>
            <Space h="xl" />
            <Space h="lg" />
            <Button
              my="xl"
              disabled={
                (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotName) ||
                (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotExpiration)
                  ? false
                  : true
              }
              onClick={addNewModalHandler}
            >
              신규로트 생성
            </Button>
          </div>
        </Flex>
        <Update.Input
          label="수량"
          withAsterisk
          placeholder="수량 입력"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />
        <UserAutoComplete
          label="담당자"
          placeholder="담당자 입력"
          {...form.getInputProps("userCode")}
          defaultValue={formatterProps?.userCode}
        />
        {purchaseOrder ? (
          <LocationAutoComplete
            label="출고 로케이션"
            placeholder="출고 로케이션"
            {...form.getInputProps("sourceLocationCode")}
            defaultValue={formatterProps?.sourceLocationCode}
          />
        ) : (
          <LocationAutoComplete
            label="입고 로케이션"
            placeholder="입고 로케이션"
            {...form.getInputProps("targetLocationCode")}
            defaultValue={formatterProps?.targetLocationCode}
          />
        )}
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "stockPlan",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {purchaseOrder ? "출고계획" : "입고계획"}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
