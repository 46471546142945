import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, NumberInput, Text } from "@mantine/core";

import { mutateMoldCavity } from "@/api/mold/useMoldCavityQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import {
  DefaultApiMoldCavitiesPostRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MoldCodeProps } from "./cavityForm";

const Create = Object.assign(FormMain, {
  Button: Button,
  NumberInput: NumberInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
});

export const MoldCavityCreateForm = ({ moldCodeProps }: MoldCodeProps) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: DefaultApiMoldCavitiesPostRequest) =>
      mutateMoldCavity.create(params).mutationFn(params as DefaultApiMoldCavitiesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["moldCavity"]);
      },
    },
  );

  const form = useForm({
    initialValues: {
      moldCode: moldCodeProps,
      num: undefined,
      itemCode: undefined,
      abstractItemCode: undefined,
      seq: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          moldCavitiesGetRequest: {
            moldCode: form.values.moldCode!,
            num: form.values.num!,
            itemCode: form.values.itemCode!,
            abstractItemCode: form.values.abstractItemCode!,
            seq: form.values.seq!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert("금형캐비티 생성에 성공하였습니다.", "생성 성공", "green");
            } else {
              customAlert("금형캐비티 생성에 실패하였습니다.", "생성 실패", "red");
            }
          },
        },
      );
    } catch (e) {
      customAlert("금형캐비티 생성에 실패하였습니다.", "생성 실패", "red");
    }
  };

  return (
    <>
      <Create.Wrapper>
        <MoldAutoComplete
          label="금형코드"
          width="100%"
          withAsterisk
          {...form.getInputProps("moldCode")}
          defaultValue={moldCodeProps}
        />
        <Create.NumberInput label="갯수" withAsterisk {...form.getInputProps("num")} />
        <ItemAutoComplete
          getItemType={[
            ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
          ]}
          label="품목코드"
          width="100%"
          {...form.getInputProps("itemCode")}
          disabled={form.values.abstractItemCode ? true : false}
        />
        <AbstractItemAutoComplete
          label="형태코드"
          width="100%"
          {...form.getInputProps("abstractItemCode")}
          disabled={form.values.itemCode ? true : false}
        />
        <Create.NumberInput label="캐비티 번호" {...form.getInputProps("seq")} />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal} className="cancel_btn">
            취소
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "moldCavity",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            금형캐비티 생성
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
