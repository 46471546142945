import { useModal } from "@/features/modal/ModalStackManager";
import { OperationDetailForm } from "@/features/operations/components/form/operationDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { DowntimeReasonsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

const DetailDownTimeReasons = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface DownTimeReasonsDetailHeaderProps {
  data: DowntimeReasonsGet200ResponseRowsInner | any;
}

export const DownTimeReasonsDetailHeader = (params: DownTimeReasonsDetailHeaderProps) => {
  const { data } = params;

  const downtimeReason = data?.data?.rows[0];

  const { openModal } = useModal();

  return (
    <>
      <DetailDownTimeReasons>
        <DetailDownTimeReasons.Title>{`[비가동 사유 상세]`}</DetailDownTimeReasons.Title>
        <DetailDownTimeReasons.Field>
          <DetailDownTimeReasons.Label>{"비가동 사유 코드"}</DetailDownTimeReasons.Label>
          <DetailDownTimeReasons.Content>{downtimeReason?.code}</DetailDownTimeReasons.Content>
        </DetailDownTimeReasons.Field>
        <DetailDownTimeReasons.Field>
          <DetailDownTimeReasons.Label>{"비가동 사유 명"}</DetailDownTimeReasons.Label>
          <DetailDownTimeReasons.Content>{downtimeReason?.name}</DetailDownTimeReasons.Content>
        </DetailDownTimeReasons.Field>
        <DetailDownTimeReasons.Field>
          <DetailDownTimeReasons.Label>{"공정코드"}</DetailDownTimeReasons.Label>
          <DetailDownTimeReasons.LinkWrapper>
            <DetailDownTimeReasons.Link
              onClick={() =>
                openModal(
                  <OperationDetailForm operationCode={downtimeReason.operationCode} />,
                  null,
                  "",
                )
              }
            >
              {downtimeReason?.operationCode}
            </DetailDownTimeReasons.Link>
          </DetailDownTimeReasons.LinkWrapper>
        </DetailDownTimeReasons.Field>
      </DetailDownTimeReasons>
    </>
  );
};
