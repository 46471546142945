import XLSX from "xlsx-js-style";

// header의 스타일을 정의하는 객체
export const headerStyle = {
  font: { bold: true, color: { rgb: "000000" }, name: "함초롱바탕", sz: 13 }, // 폰트 설정 (굵게, 검은색, '함초롱바탕' 폰트, 크기 13)
  fill: { fgColor: { rgb: "BC8F8F" } }, // 셀 배경색 (라이트 브라운 계열 색상)
  alignment: { horizontal: "center", vertical: "center" }, // 텍스트 정렬 (가로, 세로 모두 중앙 정렬)
  border: {
    // 테두리 설정 (네 방향 모두 얇은 선, 자동 색상)
    left: { style: "thin", color: { auto: 1 } },
    right: { style: "thin", color: { auto: 1 } },
    top: { style: "thin", color: { auto: 1 } },
    bottom: { style: "thin", color: { auto: 1 } },
  },
};

// 데이터 셀의 스타일을 정의하는 객체
export const dataStyle = {
  font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 }, // 폰트 설정 (검은색, '함초롱바탕' 폰트, 크기 11)
  fill: { fgColor: { rgb: "FFFAFA" } }, // 셀 배경색 (흰색)
  alignment: { horizontal: "center", vertical: "center" }, // 텍스트 정렬 (가로, 세로 모두 중앙 정렬)
  border: {
    // 테두리 설정 (네 방향 모두 얇은 선, 자동 색상)
    left: { style: "thin", color: { auto: 1 } },
    right: { style: "thin", color: { auto: 1 } },
    top: { style: "thin", color: { auto: 1 } },
    bottom: { style: "thin", color: { auto: 1 } },
  },
};

// 각 row의 데이터를 담을 때 사용하는 타입 정의
type rowData = {
  v: string; // 셀에 표시될 값 (header 또는 row의 실제 내용)
  t: string; // 셀의 데이터 타입 (s: 문자열, n: 숫자, b: 불리언, d: 날짜, e: 에러)
  s: any; // 셀의 스타일 설정 (headerStyle이나 dataStyle 같은 커스텀 스타일 적용 가능)
  /*
    스타일을 커스터마이징할 때는 아래와 같은 예시로 진행 가능
    s: {
        ...dataStyle,
        numFmt: 'yyyy-mm-dd [$-ko-KR]AM/PM hh:mm' // 셀의 날짜 형식 (한국 표준시 기준, 오전/오후 표기)
    }
    */
};

// 엑셀 데이터에 필요한 요소들을 포함한 타입 정의
type ExcelData = {
  headerRow: rowData[]; // 첫 번째 행에 들어갈 헤더 데이터
  dataRows: rowData[][]; // 그 이후에 들어갈 데이터 행들
  colWidths: number[]; // 각 열의 너비를 지정하는 배열
  fileName: string; // 저장될 파일 이름
};

/**
 * 엑셀 다운로드 매개변수 형식은 다음과 같습니다.
 * 1. headerRow: 엑셀 파일의 헤더 부분을 정의합니다.
 * ex) const headerRow = [
 *  {
 *     v: '헤더1',  // 헤더의 내용
 *     t: 's',      // 해당 셀의 데이터 타입 (s: string, n: number, b: boolean, d: date, e: error)
 *     s: headerStyle // 해당 셀의 스타일 (커스터마이징 가능)
 *  }, ...
 * ]
 *
 * 2. dataRows: 엑셀 파일의 데이터 부분을 정의합니다.
 * ex) const dataRows = [
 *  {
 *     v: '데이터1',  // 데이터의 내용
 *     t: 's',       // 해당 셀의 데이터 타입 (s: string, n: number, b: boolean, d: date, e: error)
 *     s: dataStyle // 해당 셀의 스타일 (커스터마이징 가능)
 *  }, ...
 * ]
 *
 * 3. colWidths: 엑셀 파일의 각 열의 폭을 정의합니다.
 *
 * 4. fileName: 다운로드 받을 파일의 이름을 정의합니다.
 *
 */

/**
 * 엑셀 파일을 생성하고 다운로드하는 함수
 * @param param0 ExcelData 타입의 객체 (헤더, 데이터 행, 열 너비, 파일 이름)
 */
export const excelDownLoad = async ({ headerRow, dataRows, colWidths, fileName }: ExcelData) => {
  // 헤더 행과 데이터 행을 합쳐 전체 rows 배열 생성
  const rows = [headerRow, ...dataRows];

  // 새로운 workbook (엑셀 파일) 생성
  const wb = XLSX.utils.book_new();

  // colWidths 배열을 사용해 각 열의 폭을 조절하는 설정 배열을 생성
  const cols = colWidths?.map((width: number) => ({ wpx: width })) ?? [];

  // 배열 형태의 데이터를 엑셀 시트에 맞게 변환
  const ws = XLSX.utils.aoa_to_sheet(rows);

  // 생성된 시트에 열 너비 설정 적용
  ws["!cols"] = cols;

  // workbook에 새로 생성한 시트를 'Sheet1' 이름으로 추가
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // 작성된 엑셀 파일을 지정된 파일 이름으로 다운로드 (fileName.xlsx 형태로 저장됨)
  XLSX.writeFile(wb, `${fileName}.xlsx`);

  return;
};
