import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Calendar } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import { Button, Flex, Input, Select, Text } from "@mantine/core";
import { IconCalendar, IconSearch } from "@tabler/icons-react";
import { SelectColumn } from "react-data-grid";

export const PressCompleteAdminPage = () => {
  const { openModal } = useModal();
  return (
    <>
      <Flex gap="md" direction={"column"}>
        <Flex gap="sm" justify={"space-between"}>
          <Text>설비 수리 완료 리스트(관리자용)</Text>
          <Flex gap="sm">
            <Button
              leftIcon={<IconCalendar />}
              onClick={() => {
                openModal(<Calendar dates={[null, null]} type="range" />, null, "날짜 선택");
              }}
            >
              날짜선택
            </Button>
            <Select
              w={"8rem"}
              data={[
                { label: "제조사명", value: "all" },
                { label: "기계명", value: "all" },
                { label: "제조번호", value: "all" },
                { label: "담당자", value: "all" },
                { label: "문제점", value: "all" },
                { label: "완료내용", value: "all" },
              ]}
            />
            <Input w={"16rem"} placeholder="검색어를 입력하세요" rightSection={<IconSearch />} />
            <Button>검색</Button>
          </Flex>
        </Flex>
        <GridTable
          headerList={[SelectColumn, ...columnlist.completeAdmin]}
          row={""}
          setRow={() => {}}
        />
      </Flex>
    </>
  );
};
