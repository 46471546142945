import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Main } from "@/features/standard/Main";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign({}, Main, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  DateInput: DateInput,
});

interface PurchaseOrderUpdateFormProps {
  formatterProps: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const OrderUpdateForm = (params: PurchaseOrderUpdateFormProps) => {
  const { formatterProps } = params;
  const userCode = getUserCodeByUser();
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdPutRequest) =>
      mutatePurchaseOrders
        .update(params)
        .mutationFn(params as ScmApiPurchaseOrdersPurchaseOrderIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["purchaseOrders"]);
        closeModal(formatterProps);
        customAlert(
          `${userCode === "FLOUR" ? "수주 수정에 성공하였습니다." : "발주 수정에 성공하였습니다."}`,
          `${userCode === "FLOUR" ? "수주 수정 성공" : "발주 수정 성공"}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: `${userCode === "FLOUR" ? "수주" : "발주"}` }),
          `${userCode === "FLOUR" ? "수주 등록 실패" : "발주 등록 실패"}`,
          "red",
        );
      },
    },
  );

  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      status: formatterProps?.status,
      userCode: formatterProps?.userCode,
      code: formatterProps?.code,
      targetLocationCode: formatterProps?.targetLocationCode,
      orderDate: formatterProps?.orderDate ? new Date(formatterProps.orderDate) : null,
      deliveryDeadline: formatterProps?.deliveryDeadline
        ? new Date(formatterProps.deliveryDeadline)
        : null,
      // quantityOrdered: formatterProps.quantityOrdered,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        purchaseOrderId: formatterProps?.id ?? 0,
        purchaseOrdersGetRequest: {
          direction: "OUTBOUND",
          partnerCode: form.values.partnerCode!,
          status: form.values.status!,
          code: form.values.code!,
          // quantityOrdered : form.values.quantityOrdered!,
          userCode: form.values.userCode!,
          targetLocationCode: form.values.targetLocationCode!,
          orderDate: form.values.orderDate?.toDateString()!,
          deliveryDeadline: form.values.deliveryDeadline?.toDateString()!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: `${userCode === "FLOUR" ? "수주" : "발주"}` }),
        `${userCode === "FLOUR" ? "수주 등록 실패" : "발주 등록 실패"}`,
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <PartnerAutoComplete
          label="협력사"
          placeholder="협력사 입력"
          defaultValue={formatterProps?.partnerCode}
          {...form.getInputProps("partnerCode")}
          error={form.isDirty("partnerCode") ? "저장 필요" : ""}
        />
        <Select
          label={`${userCode === "FLOUR" ? "수주상태" : "발주상태"}`}
          placeholder={`${userCode === "FLOUR" ? "수주상태" : "발주상태"}`}
          data={[
            { value: "DRAFT", label: "대기" },
            { value: "IN_PROGRESS", label: "진행중" },
            { value: "DONE", label: "완료" },
            { value: "CANCELED", label: "취소됨" },
          ]}
          defaultValue={formatterProps?.status}
          {...form.getInputProps("status")}
          error={form.isDirty("status") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("status") ? "red !important" : "",
              borderColor: form.isDirty("status") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.Input
          label={`${userCode === "FLOUR" ? "수주 코드" : "발주 코드"}`}
          placeholder={`${userCode === "FLOUR" ? "수주 코드 입력" : "발주 코드 입력"}`}
          defaultValue={formatterProps?.code}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "red !important" : "",
              borderColor: form.isDirty("code") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        {/* <Update.Input
                    label="발주항목 갯수"
                    placeholder="발주항목 갯수 입력"
                    defaultValue={formatterProps.quantityOrdered}
                    {...form.getInputProps("quantityOrdered")}
                    error={form.isDirty("quantityOrdered") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("quantityOrdered") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("quantityOrdered") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                /> */}
        <UserAutoComplete
          label="담당자"
          placeholder="담당자 입력"
          defaultValue={formatterProps?.userCode}
          {...form.getInputProps("userCode")}
          error={form.isDirty("userCode") ? "저장 필요" : ""}
        />
        <LocationAutoComplete
          label="입고 로케이션"
          placeholder="입고 로케이션"
          defaultValue={formatterProps?.targetLocationCode}
          {...form.getInputProps("targetLocationCode")}
          error={form.isDirty("targetLocationCode") ? "저장 필요" : ""}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label={`${userCode === "FLOUR" ? "수주일" : "발주일"}`}
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("orderDate")}
          error={form.isDirty("orderDate") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("orderDate") ? "red !important" : "",
              borderColor: form.isDirty("orderDate") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.DateInput
          popoverProps={{ withinPortal: true }}
          icon={<IconCalendar size="1.2rem" />}
          label="납기일"
          valueFormat="YYYY-MM-DD"
          {...form.getInputProps("deliveryDeadline")}
          error={form.isDirty("deliveryDeadline") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("deliveryDeadline") ? "red !important" : "",
              borderColor: form.isDirty("deliveryDeadline") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            취소
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "purchaseOrder",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {userCode === "FLOUR" ? "수주 수정" : "발주 수정"}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
