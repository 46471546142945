import { mutateUsers } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { DepartmentAutoComplete } from "@/features/ui/autoComplete/department/department-autoComplete";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiUsersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UsersCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

const isDepartmentInformIncluded = customFunctions.ADD_USER_DEPARTMENT_INFORM;

export function UsersCreateForm(params: UsersCreateFormProps) {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const form = useForm({
    initialValues: {
      code: code ?? undefined,
      name: undefined,
      username: undefined,
      password: undefined,
      equipmentCode: undefined,
      departmentCode: undefined,
      printerCode: undefined,
      locationCode: undefined,
    },
  });

  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiUsersPostRequest) =>
      mutateUsers.create(params).mutationFn(params as MasterApiUsersPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        customAlert("사용자 등록에 성공하였습니다.", "등록 성공", "green");
        closeModal(form.values);
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "사용자 코드나 아이디" }),
          "사용자 생성 실패",
          "red",
        );
      },
    },
  );

  const onSubmit = async () => {
    try {
      createMutate({
        authSignupPostRequest: {
          code: code ?? form.values.code!,
          name: form.values.name!,
          username: form.values.username!,
          password: form.values.password,
          equipmentCode: form.values.equipmentCode,
          ...(isDepartmentInformIncluded ? { departmentCode: form.values.departmentCode } : {}),
          printerCode: form.values.printerCode,
          locationCode: form.values.locationCode,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "사용자 코드나 아이디" }),
        "사용자 생성 실패",
        "red",
      );
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput label="사용자코드" withAsterisk {...form.getInputProps("code")} />
          <TextInput label="사용자명" withAsterisk {...form.getInputProps("name")} />
          <TextInput label="로그인아이디" withAsterisk {...form.getInputProps("username")} />
          <TextInput
            // type="number"
            label="비밀번호"
            {...form.getInputProps("password")}
          />
          <EquipmentAutoComplete label="설비코드" {...form.getInputProps("equipmentCode")} />
          <LocationAutoComplete label="로케이션코드" {...form.getInputProps("locationCode")} />
          {isDepartmentInformIncluded && (
            <DepartmentAutoComplete label="부서코드" {...form.getInputProps("departmentCode")} />
          )}
          <TextInput label="프린터 코드" {...form.getInputProps("printerCode")} />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "users",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              사용자 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
