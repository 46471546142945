import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { GradeDetailForm } from "@/features/grade/components/form/GradeDetailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerRoutingsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

const DetailRouting = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface InspectSpecificationInformProps {
  data?: ItemsGet200ResponseRowsInnerRoutingsInner;
}

export const InspectSpecificationInform = (params: InspectSpecificationInformProps) => {
  const { data } = params;
  const { openModal } = useModal();

  return (
    <DetailRouting>
      <DetailRouting.Title>{`검사표준`}</DetailRouting.Title>
      <DetailRouting.Inform>{"검사표준"}</DetailRouting.Inform>
      <Space h={8} />
      <DetailRouting.Field>
        <DetailRouting.Label>{"품목"}</DetailRouting.Label>
        <DetailRouting.LinkWrapper>
          <DetailRouting.Badge>{data?.itemWithGrade?.itemType}</DetailRouting.Badge>
          <DetailRouting.Link>{data?.itemWithGrade?.name}</DetailRouting.Link>
        </DetailRouting.LinkWrapper>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{"Grade"}</DetailRouting.Label>
        <DetailRouting.Content>
          <DetailLink
            onClick={() => {
              openModal(
                <GradeDetailForm gradeCode={data?.itemWithGrade?.gradeCode} />,
                null,
                "Grade 상세",
              );
            }}
          >
            {data?.itemWithGrade?.gradeName}
          </DetailLink>
        </DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{"Color"}</DetailRouting.Label>
        <DetailRouting.Content>
          <DetailLink
            onClick={() => {
              openModal(
                <ItemCategoryDetailForm ItemCategoryName={data?.itemWithGrade?.itemCategoryName} />,
                null,
                "Color 상세",
              );
            }}
          >
            {data?.itemWithGrade?.itemCategoryName}
          </DetailLink>
        </DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{"형태"}</DetailRouting.Label>
        <DetailRouting.Content>
          <DetailLink
            onClick={() => {
              openModal(
                <AbstractItemDetailForm AbstractItemCode={data?.itemWithGrade?.abstractItemCode} />,
                null,
                "형태 상세",
              );
            }}
          >
            {data?.itemWithGrade?.abstractItemCode}
          </DetailLink>
        </DetailRouting.Content>
      </DetailRouting.Field>
      {/* <DetailRouting.Field>
        <DetailRouting.Label>{"라우팅코드"}</DetailRouting.Label>
        <DetailRouting.Content>{data?.code}</DetailRouting.Content>
      </DetailRouting.Field> */}
      <DetailRouting.Field>
        <DetailRouting.Label>{"검사 종류"}</DetailRouting.Label>
        <DetailRouting.Content>{"초중종물 검사"}</DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{"생성정보"}</DetailRouting.Label>
        <DetailRouting.Content>
          {`${data?.creatorUserCode ?? ""}(${dayjs(data?.createdAt).format(
            "YYYY-MM-DD HH:mm:ss",
          )})`}
        </DetailRouting.Content>
      </DetailRouting.Field>
      <DetailRouting.Field>
        <DetailRouting.Label>{"수정정보"}</DetailRouting.Label>
      </DetailRouting.Field>
    </DetailRouting>
  );
};
