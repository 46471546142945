import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { getUserCodeByUser } from "@/utils/checkData";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import { Badge, Checkbox, Space } from "@mantine/core";
import { PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

const PurchaseOrderDetail = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});

export interface PurchaseOrderDetailHeaderProps {
  data: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const PurchaseOrderDetailHeader = (params: PurchaseOrderDetailHeaderProps) => {
  const { data } = params;
  const { openModal } = useModal();
  const userCode = getUserCodeByUser();
  return (
    <>
      <PurchaseOrderDetail>
        <PurchaseOrderDetail.Title>
          {`${userCode === "PB" ? "[발주]" : "[수주]"}`} {data?.code}
        </PurchaseOrderDetail.Title>
        <Space h={8} />
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>
            {`${userCode === "PB" ? "발주번호" : "수주번호"}`}
          </PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>{data?.id}</PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>
            {`${userCode === "PB" ? "발주코드" : "수주코드"}`}
          </PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>{data?.code}</PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"협력사"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link
              onClick={() =>
                openModal(
                  <PartnerDetailForm partnerCode={data?.partnerCode} />,
                  null,
                  "협력사 상세",
                )
              }
            >
              {data?.partner?.name}
            </PurchaseOrderDetail.Link>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"상태"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <Badge
              variant="filled"
              color={
                purchaseOrderStatusColor[data?.status as keyof typeof purchaseOrderStatusColor]
              }
            >
              {setPurchaseOrderStatus(data?.status ?? "")}
            </Badge>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"담당자"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link
              onClick={() =>
                openModal(<UsersDetailForm UserCode={data?.userCode} />, null, "사용자 상세")
              }
            >
              {data?.manager}
            </PurchaseOrderDetail.Link>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"출고 로케이션"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={data?.sourceLocationCode} />,
                  null,
                  "로케이션 상세",
                )
              }
            >
              {data?.sourceLocationName}
            </PurchaseOrderDetail.Link>
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"비고"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>{data?.description}</PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"발주일자"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            {dayjs(data?.orderDate).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"납기일자"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            {dayjs(data?.deliveryDeadline).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"접수일시"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            {data?.creatorUserName} {dayjs(data?.createdAt).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
        <PurchaseOrderDetail.Field>
          <PurchaseOrderDetail.Label>{"수정정보"}</PurchaseOrderDetail.Label>
          <PurchaseOrderDetail.Content>
            <PurchaseOrderDetail.Link>{data?.updaterUserName}</PurchaseOrderDetail.Link>
            {data?.updatedAt === null ? "" : dayjs(data?.updatedAt).format("YYYY-MM-DD")}
          </PurchaseOrderDetail.Content>
        </PurchaseOrderDetail.Field>
      </PurchaseOrderDetail>
    </>
  );
};
