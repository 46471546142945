import { customFunctions } from "@/config/customFunction";
import { ColumnHeaderProps } from "@/constants/columnHeader";
import { useQueryString } from "@/features/standardLayout/useQueryString";
import { useResetQueryStrings } from "@/features/standardLayout/useResetQueryStrings";
import { isEmptyObject } from "@/utils/checkData";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

export type QueryType = Record<string, any>;

// Context에서 사용할 상태 타입 정의
interface StandardLayoutContextProps<TData> {
  search: string;
  setSearch: (value: string) => void;
  searchFields: string[];
  setSearchFields: (fields: string[]) => void;
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  query: QueryType[];
  setQuery: Dispatch<SetStateAction<QueryType[]>>; // (query: QueryType[]) => void;
  sort: string[];
  setSort: Dispatch<SetStateAction<string[]>>; // (sort: string[]) => void;
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  data: TData | undefined;
  isLoading: boolean;
  error: unknown;
  refetch: () => void;
  searchFieldsHeader: ColumnHeaderProps[];
  resetQueryStrings: () => void;
  selectedRows: Set<any>;
  setSelectedRows: Dispatch<SetStateAction<Set<any>>>;
  populate: string[] | undefined;
  params: QueryType;
  setParams: Dispatch<SetStateAction<QueryType>>;
}

// Context 생성
const StandardLayoutContext = createContext<StandardLayoutContextProps<any> | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
  queryKeyFactory: (params: any, options: any) => any;
  searchFieldsHeader: ColumnHeaderProps[];
  populate: string[] | undefined;
  isUseParams?: boolean;
  isUseDate?: boolean;
}

// 기본 날짜 설정
export const startDateTime = customFunctions.ADD_STANDARD_TODAY_DATE
  ? dayjs().startOf("day").toDate()
  : dayjs().startOf("month").toDate();
export const endDateTime = customFunctions.ADD_STANDARD_TODAY_DATE
  ? dayjs().endOf("day").toDate()
  : dayjs().endOf("month").toDate();

export const StandardLayoutContextProvider = <TData,>({
  children,
  queryKeyFactory,
  searchFieldsHeader,
  populate,
  isUseParams = false,
  isUseDate = false,
}: ProviderProps) => {
  // QueryString을 통한 상태 관리
  const [search, setSearch] = useQueryString<string>("search", "");
  const [searchFields, setSearchFields] = useQueryString<string[]>("searchFields", []);
  const [page, setPage] = useQueryString<number>("page", 1);
  const [pageSize, setPageSize] = useQueryString<number>("pageSize", 10);
  const [query, setQuery] = useQueryString<QueryType[]>("query", []);
  const [sort, setSort] = useQueryString<string[]>("sort", []);
  // 날짜 관련 상태를 항상 초기화
  const [startDate, setStartDate] = useQueryString<Date | null>(
    "startDate",
    isUseDate ? startDateTime : null,
  );
  const [endDate, setEndDate] = useQueryString<Date | null>(
    "endDate",
    isUseDate ? endDateTime : null,
  );

  // 선택적으로 URL에 날짜를 포함하지 않도록 처리
  useEffect(() => {
    if (!isUseDate) {
      setStartDate(null); // 상태를 null로 설정
      setEndDate(null);
    }
  }, [isUseDate, setStartDate, setEndDate]);

  const [selectedRows, setSelectedRows] = useState((): Set<any> => new Set());
  const [params, setParams] = useState<QueryType>({});

  const resetQueryStrings = useResetQueryStrings({
    setSearch,
    setSearchFields,
    setPage,
    setPageSize,
    setQuery,
    setSort,
    setSelectedRows,
    setStartDate,
    setEndDate,
  });

  useEffect(() => {
    if (isUseDate) {
      if (!endDate) {
        setEndDate(endDateTime);
      }
      if (!startDate) {
        setStartDate(startDateTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUseDate, startDate, endDate]);

  // queryKeyFactory를 통한 queryKey 생성 및 options 생성
  const queryKey = queryKeyFactory(
    {
      query, //: JSON.stringify(query),
      ...(search ? { search } : {}),
      searchFields: searchFields.length
        ? searchFields
        : searchFieldsHeader
            .filter((item) => item.category === "text" && !item.isEnum)
            .map((item) => item.value),
      page,
      pageSize: Number(pageSize),
      ...(sort.length ? { sort: sort.join(",") } : {}),
      populate: populate,
    },
    {
      params: params,
    },
  );

  // useQuery를 통해 데이터를 가져옴
  const { data, isLoading, error, refetch } = useQuery<TData>({
    ...queryKey,
    enabled: isUseParams ? !isEmptyObject(params) : !isUseParams,
  });

  // Context에 제공할 값 정의
  const value: StandardLayoutContextProps<TData> = {
    search,
    setSearch,
    searchFields,
    setSearchFields,
    page,
    setPage,
    pageSize,
    setPageSize,
    query,
    setQuery,
    sort,
    setSort,
    startDate: isUseDate ? startDate : null,
    setStartDate: isUseDate ? setStartDate : () => {},
    endDate: isUseDate ? endDate : null,
    setEndDate: isUseDate ? setEndDate : () => {},
    data,
    isLoading,
    error,
    refetch,
    searchFieldsHeader,
    resetQueryStrings,
    selectedRows,
    setSelectedRows,
    populate,
    params,
    setParams,
  };

  return <StandardLayoutContext.Provider value={value}>{children}</StandardLayoutContext.Provider>;
};

// Context의 값을 쉽게 사용할 수 있는 커스텀 훅
export const useStandardLayout = <TData,>(): StandardLayoutContextProps<TData> => {
  const context = useContext(StandardLayoutContext);
  if (!context) {
    throw new Error("useStandardLayout must be used within a StandardLayoutProvider");
  }
  return context as StandardLayoutContextProps<TData>;
};
