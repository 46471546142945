import { bomHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { BOMCreateForm } from "@/features/bom/form/createForm";
import { BOMUpdateForm } from "@/features/bom/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useRef, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

const BOMWrap = {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  Wrapper: Wrapper,
  CreateForm: BOMCreateForm,
  UpdateForm: BOMUpdateForm,
};

export const BOMPage = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { openModal } = useModal();
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());

  const searchFieldsHeader = bomHeader.filter((item) => item.category === "text" && !item.isEnum);

  const pageSize = 10;
  const totalRows = BOMPageMockupData.length;

  // 페이지에 맞는 데이터 슬라이싱
  const paginatedRows = BOMPageMockupData.slice((activePage - 1) * pageSize, activePage * pageSize);

  const columns: readonly Column<any>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "partnerCode",
      name: "협력사",
      sortable: true,
      resizable: true,
    },
    {
      key: "modelName",
      name: "모델명",
      sortable: true,
      resizable: true,
    },
    {
      key: "bomCode",
      name: "BOM코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemName",
      name: "품목명",
      sortable: true,
      resizable: true,
    },
    {
      key: "itemType",
      name: "품목군",
      sortable: true,
      resizable: true,
    },
    {
      key: "unitText",
      name: "단위",
      sortable: true,
      resizable: true,
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <BOMWrap.UpdateForm formatterProps={formatterProps.row} />,
                  {},
                  "BOM 수정",
                  true,
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                window.confirm("정말로 삭제하시겠습니까?") &&
                  customAlert("BOM 삭제에 실패하였습니다.", "삭제 실패", "red");
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  const deleteSelectedItems = () => {
    window.confirm("정말로 삭제하시겠습니까?") &&
      customAlert("선택된 행 삭제에 실패하였습니다.", "삭제 실패", "red");
  };

  return (
    <BOMWrap.Wrapper>
      <BOMWrap.ActionHeader>
        <BOMWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() => openModal(<BOMWrap.CreateForm />, null, "BOM 추가", true)}
        >
          추가
        </BOMWrap.ActionButton>
        <BOMWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => deleteSelectedItems()}
        >
          선택된 행 삭제
        </BOMWrap.ActionButton>
      </BOMWrap.ActionHeader>
      <BOMWrap.Header>
        <BOMWrap.HeaderTitle>BOM 관리</BOMWrap.HeaderTitle>
        <BOMWrap.HeaderSubTitle>
          {"우리 회사에서 다루는 BOM에 대해 조회할 수 있는 페이지 입니다."}
        </BOMWrap.HeaderSubTitle>
      </BOMWrap.Header>

      <Flex w="100%" justify="space-between">
        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
          <CustomFilter filterType={bomHeader} setQuery={setQuery} query={query} />
          <CustomSorter sorterType={bomHeader} setSort={setSort} sort={sort} />
        </Flex>
        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
      </Flex>
      <Flex w="100%" h="100%">
        <GridWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={paginatedRows}
            rowHeight={40}
            rowKeyGetter={(row) => row.logDate}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
          <Pagination
            onChange={setPage}
            value={activePage}
            total={Math.ceil(totalRows / pageSize)} // 전체 페이지 계산
            size="lg"
            radius="sm"
            position="center"
          />
        </GridWrapper>
      </Flex>
    </BOMWrap.Wrapper>
  );
};

const BOMPageMockupData = [
  {
    partnerCode: "P001",
    modelName: "ModelA",
    bomCode: "BOM001",
    itemName: "ItemA",
    spec: "SpecA-001",
    itemType: "TypeA",
    unitText: "EA",
    operationCode: "OP001",
  },
  {
    partnerCode: "P002",
    modelName: "ModelB",
    bomCode: "BOM002",
    itemName: "ItemB",
    spec: "SpecB-002",
    itemType: "TypeB",
    unitText: "SET",
    operationCode: "OP002",
  },
  {
    partnerCode: "P003",
    modelName: "ModelC",
    bomCode: "BOM003",
    itemName: "ItemC",
    spec: "SpecC-003",
    itemType: "TypeC",
    unitText: "PCS",
    operationCode: "OP003",
  },
  {
    partnerCode: "P004",
    modelName: "ModelD",
    bomCode: "BOM004",
    itemName: "ItemD",
    spec: "SpecD-004",
    itemType: "TypeD",
    unitText: "KG",
    operationCode: "OP004",
  },
  {
    partnerCode: "P005",
    modelName: "ModelE",
    bomCode: "BOM005",
    itemName: "ItemE",
    spec: "SpecE-005",
    itemType: "TypeE",
    unitText: "EA",
    operationCode: "OP005",
  },
  {
    partnerCode: "P006",
    modelName: "ModelF",
    bomCode: "BOM006",
    itemName: "ItemF",
    spec: "SpecF-006",
    itemType: "TypeF",
    unitText: "BOX",
    operationCode: "OP006",
  },
  {
    partnerCode: "P007",
    modelName: "ModelG",
    bomCode: "BOM007",
    itemName: "ItemG",
    spec: "SpecG-007",
    itemType: "TypeG",
    unitText: "SET",
    operationCode: "OP007",
  },
  {
    partnerCode: "P008",
    modelName: "ModelH",
    bomCode: "BOM008",
    itemName: "ItemH",
    spec: "SpecH-008",
    itemType: "TypeH",
    unitText: "PCS",
    operationCode: "OP008",
  },
  {
    partnerCode: "P009",
    modelName: "ModelI",
    bomCode: "BOM009",
    itemName: "ItemI",
    spec: "SpecI-009",
    itemType: "TypeI",
    unitText: "KG",
    operationCode: "OP009",
  },
  {
    partnerCode: "P010",
    modelName: "ModelJ",
    bomCode: "BOM010",
    itemName: "ItemJ",
    spec: "SpecJ-010",
    itemType: "TypeJ",
    unitText: "BOX",
    operationCode: "OP010",
  },
  {
    partnerCode: "P011",
    modelName: "ModelK",
    bomCode: "BOM011",
    itemName: "ItemK",
    spec: "SpecK-011",
    itemType: "TypeK",
    unitText: "EA",
    operationCode: "OP011",
  },
  {
    partnerCode: "P012",
    modelName: "ModelL",
    bomCode: "BOM012",
    itemName: "ItemL",
    spec: "SpecL-012",
    itemType: "TypeL",
    unitText: "PCS",
    operationCode: "OP012",
  },
  {
    partnerCode: "P013",
    modelName: "ModelM",
    bomCode: "BOM013",
    itemName: "ItemM",
    spec: "SpecM-013",
    itemType: "TypeM",
    unitText: "SET",
    operationCode: "OP013",
  },
  {
    partnerCode: "P014",
    modelName: "ModelN",
    bomCode: "BOM014",
    itemName: "ItemN",
    spec: "SpecN-014",
    itemType: "TypeN",
    unitText: "BOX",
    operationCode: "OP014",
  },
  {
    partnerCode: "P015",
    modelName: "ModelO",
    bomCode: "BOM015",
    itemName: "ItemO",
    spec: "SpecO-015",
    itemType: "TypeO",
    unitText: "EA",
    operationCode: "OP015",
  },
  {
    partnerCode: "P016",
    modelName: "ModelP",
    bomCode: "BOM016",
    itemName: "ItemP",
    spec: "SpecP-016",
    itemType: "TypeP",
    unitText: "SET",
    operationCode: "OP016",
  },
  {
    partnerCode: "P017",
    modelName: "ModelQ",
    bomCode: "BOM017",
    itemName: "ItemQ",
    spec: "SpecQ-017",
    itemType: "TypeQ",
    unitText: "KG",
    operationCode: "OP017",
  },
  {
    partnerCode: "P018",
    modelName: "ModelR",
    bomCode: "BOM018",
    itemName: "ItemR",
    spec: "SpecR-018",
    itemType: "TypeR",
    unitText: "PCS",
    operationCode: "OP018",
  },
  {
    partnerCode: "P019",
    modelName: "ModelS",
    bomCode: "BOM019",
    itemName: "ItemS",
    spec: "SpecS-019",
    itemType: "TypeS",
    unitText: "BOX",
    operationCode: "OP019",
  },
  {
    partnerCode: "P020",
    modelName: "ModelT",
    bomCode: "BOM020",
    itemName: "ItemT",
    spec: "SpecT-020",
    itemType: "TypeT",
    unitText: "EA",
    operationCode: "OP020",
  },
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: 440px;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
